import axios from 'axios';
import {API_BASE_URI} from 'constants/app';

axios.defaults.withCredentials = true;

const getToken = (token) => {
    if (token == null) {
        // throw new Error('Cannot get token!');
        console.log("Unable to find authentic credentials, redirecting to login..");
        window.location.href = "/login";
        return '';
    }
    let a = token.split("==#==")[0];
    let b = token.split("==#==")[1].split("==!==")[0];
    let c = token.split("==#==")[1].split("==!==")[1];
    a = a.replace(c, "");
    b = b.substr(parseInt(a));
    b = b.substr(0, b.length-parseInt(c));
    return atob(b);
};

const apiClient = axios.create({
    //timeout: 50000,
    baseURL: API_BASE_URI,
    headers: {
        'Authorization': 'Bearer ' + getToken(window.localStorage.getItem('wsatinfo')),
        'Accept': 'application/json',
        // 'Content-Type': 'application/json;charset=utf-8'
    }
  });

apiClient.defaults.timeout = 50000;

  apiClient.interceptors.response.use(function (response) {
    return response;
  }, (error) => {
    if (error === "Error: Network Error") {
        return Promise.reject("Unable to connect to backend - " + error);
    } else if (error.response === undefined) {
        return Promise.reject("Unknown issue - " + error);
    } else if (error.response.status === undefined){
        return Promise.reject("Unknown Status issue - " + error);
    } else if ( error.response.status === 401 ) {
        // Unauthorized
        window.location.href = "/login";
        return Promise.reject("You have been logged out " + error);
    } else if (error.response.status === 404 ) {
        // Not Found
        return Promise.reject("You are trying to reach unknown resource - " + error);
    } else if (error.response.status === 500 ) {
        // Backend Error
        return Promise.reject("Backend issue - " + error);
    } else {
        return Promise.reject("Unknown issue" + error);
    }

  });
// const cAxios = axios;
export const cAxios = axios;
export default apiClient;
