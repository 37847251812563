import React from 'react';
import ReactDOM from 'react-dom';
//import pkg from  '../package.json';
import {IS_SENTRY_REQUIRED, API_BASE_URI, ENVIRONMENT} from 'constants/app';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const rootEl = document.getElementById('app-site');
// Create a reusable render method that we can call more than once
let render = () => {
  // Dynamically import our main App component, and render it
  if (ENVIRONMENT !== 'production') {
    console.log(`Sentry Loaded: ${IS_SENTRY_REQUIRED}`);
  }
  if (IS_SENTRY_REQUIRED) {
    if (ENVIRONMENT !== 'production') {
      //console.log(`Sentry initializing.. ${ENVIRONMENT}, v${pkg.version}`);
    }
    Sentry.init({
      dsn: "https://5aa3008976f042edbdeb4257bbd3a1c4@o446617.ingest.sentry.io/5425379",
      environment: ENVIRONMENT,
      //release: `v${pkg.version}`,
      integrations: [new Integrations.BrowserTracing()]
      // beforeSend(event, hint) {
      //   // Check if it is an exception, and if so, show the report dialog
      //   if (event.exception.values[0]['type'] === 'Error' && event.exception.values[0]['value'] === 'Cannot get token!') {
      //     window.location.href = API_BASE_URI+'/login';
      //   }
      //   else {
      //     Sentry.showReportDialog({ eventId: event.event_id });
      //   }
      //   return event;
      // }
    });
  }

  /*window.location.href.includes('influencer-research') ?
    <React.StrictMode><MainApp/></React.StrictMode>: <MainApp/>,
    rootEl*/
  const MainApp = require('./MainApp').default;
  ReactDOM.render(
    <MainApp/>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const MainApp = require('./MainApp').default;
    render(
      <MainApp/>,
      rootEl
    );
  });
}

render();
