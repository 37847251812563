import {
  FETCH_ALL_MAIL,
  GET_ALL_MAIL,
  GET_IMPORTANT_MAIL,
  GET_NAV_FILTERS,
  GET_NAV_FOLDER,
  GET_NAV_LABELS,
  GET_READ_MAIL,
  GET_STARRED_MAIL,
  GET_UNIMPORTANT_MAIL,
  GET_UNREAD_MAIL,
  GET_UNSELECTED_ALL_MAIL,
  GET_UNSTARRED_MAIL,
  HANDLE_REQUEST_CLOSE,
  ON_ALL_MAIL_SELECT,
  ON_COMPOSE_MAIL,
  ON_DELETE_MAIL,
  ON_BLOCK_MAIL,
  ON_FOLDER_MENU_ITEM_SELECT,
  ON_FOLDER_SELECT,
  ON_HIDE_LOADER,
  ON_IMPORTANT_SELECT,
  ON_LABEL_MENU_ITEM_SELECT,
  ON_LABEL_SELECT,
  ON_MAIL_CHECKED,
  ON_MAIL_SELECT,
  ON_MAIL_SEND,
  ON_OPTION_MENU_ITEM_SELECT,
  ON_OPTION_MENU_SELECT,
  ON_START_SELECT,
  ON_TOGGLE_DRAWER,
  SEARCH_MAIL,
  SET_CURRENT_MAIL_NULL,
  UPDATE_SEARCH,
  ON_TRASH_CLICK,
  ON_REPLY_CLICK,
  ON_SPAM_CLICK,
  ON_NOTES_CLICK,
  FETCH_NOTES_SUCCESS,
  ON_NOTES_SUBMIT,
  ACTIVE_SRC,
  ON_DATE_MENU_SELECT,
  ON_DATE_RANGE_CHANGE,
  FETCH_PROJECT_TAGS_REQUEST,
  FETCH_PROJECT_TAGS_SUCCESS,
  ON_APPLY_LABEL,
  UPDATE_LABEL_INFO,
  ADD_LABEL_INFO,
  DELETE_LABEL,
  ADD_LABEL_INFO_SUCCESS,
  DELETE_LABEL_SUCCESS,
  ON_APPLY_REPLY,
  ON_APPLY_DELETE,
  ON_SPAM_CLICK_AUTHOR,
  FOLDER_STAT_REQUEST,
  FOLDER_STAT_SUCCESS,
  ON_READ_CLICK,
  ON_NEW_KEYWORD_LOAD,
  FETCH_CONTINUOUSLY,
  ON_FILTER_TOGGLE_DRAWER,
  ON_USER_FETCHED_SUCCESS,
  SHOW_NEW_POSTS,
  ON_APPLY_STARRED,
  ON_TAG_DELETE,
  ADD_LABEL_ALREADY,
  ADD_LABEL_ALREADY_SUCCESS,
  ON_CHANGE_ADD_LABEL_TITLE,
  TAG_FILTER_SELECTED,
  ON_DOWNLOAD_CLICK,
  ON_DOWNLOAD_SUCCESS,
  DETAIL_LOADER_HANDLER,
  ON_MOUSE_OUT,
  ON_MOUSE_OVER,
  CALL_LAZY_LOAD,
  SENTI_ON_CHANGE_IMPACT,
  REPORT_SENTI_ISSUE,
  ON_SENTI_CHANGE,
  ON_TITLE_SUBMIT,
  ON_WEB_SUBMIT,
  ON_WORD_SUBMIT,
  ON_TITLE_DELETE,
  ON_WEB_DELETE,
  ON_WORD_DELETE,
  RESET_FILTER,
  ON_FILTER_SUBMIT,
  ON_FILTER_SUBMIT_SUCCESS,
  ON_FILTER_SELECT,
  GET_FILTER_LIST,
  GET_FILTER_SUCCESS,
  ON_DELETE_FILTER,
  APPLY_SAVED_FILTER,
  ON_LANG_SUBMIT,
  ON_LANG_DELETE,
  ON_LANG_CHANGE,
  ON_LOC_SUBMIT,
  ON_LOC_DELETE,
  ON_LOC_CHANGE,
  ON_SORT_SUBMIT,
  ON_SORT_DELETE,
  ON_SORT_CHANGE,
  CHANGE_DATE_RANGE_CALENDAR,
  SAVE_LAST_SELECTED_DATE,
  RESELECT_LAST_SELECTED_DATE,
  GET_LANG
} from '../constants/ActionTypes';
import {FETCH_ALL_MAIL_SUCCESS, SHOW_MESSAGE} from 'constants/ActionTypes';


export const fetchMails = (params) => {
  return {
    type: FETCH_ALL_MAIL,
    params
  };
};

export const fetchMailsSuccess = (mails) => {
  return {
    type: FETCH_ALL_MAIL_SUCCESS,
    payload: mails
  }
};
export const showMailMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const onFolderMenuItemSelect = (folderId) => {
  return {
    type: ON_FOLDER_MENU_ITEM_SELECT,
    payload: folderId
  };
};

export const onMailLabelMenuItemSelect = (label) => {
  return {
    type: ON_LABEL_MENU_ITEM_SELECT,
    payload: label
  };
};
export const handleMailRequestClose = () => {
  return {
    type: HANDLE_REQUEST_CLOSE,
  };
};

export const getMailNavFilters = (filter) => {
  return {
    type: GET_NAV_FILTERS,
    payload: filter,
  };
};
export const onFolderSelect = () => {
  return {
    type: ON_FOLDER_SELECT
  };
};
export const onMailLabelSelect = () => {
  return {
    type: ON_LABEL_SELECT,
  };
};
export const onMailOptionMenuSelect = () => {
  return {
    type: ON_OPTION_MENU_SELECT,
  };
};
export const onOptionMenuItemSelect = (option) => {
  return {
    type: ON_OPTION_MENU_ITEM_SELECT,
    payload: option
  };
};
export const getAllMail = () => {
  return {
    type: GET_ALL_MAIL,
  };
};
export const getUnselectedAllMail = () => {
  return {
    type: GET_UNSELECTED_ALL_MAIL,
  };
};
export const getReadMail = () => {
  return {
    type: GET_READ_MAIL
  };
};
export const getUnreadMail = () => {
  return {
    type: GET_UNREAD_MAIL
  };
};
export const getStarredMail = () => {
  return {
    type: GET_STARRED_MAIL
  };
};

export const getUnStarredMail = () => {
  return {
    type: GET_UNSTARRED_MAIL
  };
};
export const getImportantMail = () => {
  return {
    type: GET_IMPORTANT_MAIL
  };
};
export const getUnimportantMail = () => {
  return {
    type: GET_UNIMPORTANT_MAIL
  };
};
export const getMailNavLabels = (label) => {
  return {
    type: GET_NAV_LABELS,
    payload: label
  };
};
export const onSearchMail = (searchText) => {
  return {
    type: SEARCH_MAIL,
    payload: searchText
  };
};
export const onMailChecked = (data) => {
  return {
    type: ON_MAIL_CHECKED,
    payload: data
  };
};

export const onAllMailSelect = () => {

  return {
    type: ON_ALL_MAIL_SELECT
  };
};
export const onStartSelect = (data, params) => {
  return {
    type: ON_START_SELECT,
    payload: {
      data,
      params
    }
  };
};
export const onImportantSelect = (data) => {
  return {
    type: ON_IMPORTANT_SELECT,
    payload: data
  };
};
export const onMailSend = (data) => {
  return {
    type: ON_MAIL_SEND,
    payload: data
  };
};
export const onMailSelect = (mail) => {
  return {
    type: ON_MAIL_SELECT,
    payload: mail
  };
};
export const getNavFolders = (folder) => {
  return {
    type: GET_NAV_FOLDER,
    payload: folder
  };
};

export const updateMailSearch = (searchText) => {
  return {
    type: UPDATE_SEARCH,
    payload: searchText
  };
};
export const onMailToggleDrawer = () => {
  return {
    type: ON_TOGGLE_DRAWER
  };
};
export const onDeleteMail = () => {
  return {
    type: ON_DELETE_MAIL
  };
};

export const onBlockMail = () => {
  return {
    type: ON_BLOCK_MAIL
  };
};

export const hideMailLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};
export const setCurrentMailNull = () => {
  return {
    type: SET_CURRENT_MAIL_NULL
  };
};
export const onComposeMail = () => {
  return {
    type: ON_COMPOSE_MAIL
  };
};

export const onTrashClick = (data) => {
  return {
    type: ON_TRASH_CLICK,
    payload: data
  };
};

export const onReplyClick = (data) => {
  return {
    type: ON_REPLY_CLICK,
    payload: data
  };
};

export const onSpamClick = (data) => {
  return {
    type: ON_SPAM_CLICK,
    payload: data
  };
};

export const onNotesClick = (mail, params) => {
  return {
    type: ON_NOTES_CLICK,
    payload: { mail, params}
  };
};

export const fetchNotesSuccess = (notes) => {
  return {
    type: FETCH_NOTES_SUCCESS,
    payload: notes
  }
}

export const onNotesSubmit = (data) => {
  return {
    type: ON_NOTES_SUBMIT,
    payload: data
  };
};

export const onAciveSrcChange = (src) => {
  return {
    type: ACTIVE_SRC,
    payload: src
  };
};

export const onDateMenuSelect = () => {
  return {
    type: ON_DATE_MENU_SELECT
  };
};

export const onDateRangeChange = (dateOption) => {
  return {
    type: ON_DATE_RANGE_CHANGE,
    payload: dateOption
  };
}

export const requestProjectTags = (params) => {
  return {
    type: FETCH_PROJECT_TAGS_REQUEST,
    payload: params
  }
}

export const fetchProjectsSuccess = (projectTags, keywordName, boolList) => {
  return {
    type: FETCH_PROJECT_TAGS_SUCCESS,
    payload: { projectTags, keywordName, boolList }
  }
}

export const showMessage = (error) => {
  return {
    type: 'error',
    payload: error
  }
}

export const onApplyLabel = (postIds, labelId, project_id, params) => {
  return {
    type: ON_APPLY_LABEL,
    payload: {
      postIds,
      labelId,
      project_id,
      params
    }
  };
};

export const updateLabelInfo = (id, title, color, projectId) => {
  return {
    type: UPDATE_LABEL_INFO,
    payload: {
      id: id,
      title: title,
      color: color,
      projectId: projectId
    }
  };
};

export const addLabelInfo = (title, color, projectId) => {
  return {
    type: ADD_LABEL_INFO,
    payload: {
      title,
      color,
      projectId
    }
  };
};

export const deleteLabel = (labelId, projectId) => {
  return {
    type: DELETE_LABEL,
    payload: {
      labelId,
      projectId
    }
  }
};


export const addLabelInfoSuccess = (label) => {
  return {
    type: ADD_LABEL_INFO_SUCCESS,
    payload: label
  }
}

export const deleteLabelInfoSuccess = (label) => {
  return {
    type: DELETE_LABEL_SUCCESS,
    payload: label
  }
};

export const onApplyReply = (postIds, project_id, params, selected_date) => {
  return {
    type: ON_APPLY_REPLY,
    payload: {
      postIds,
      project_id,
      params,
      selected_date
    }
  };
};

export const onApplyDelete = (postIds, project_id, params, selected_date) => {
  return {
    type: ON_APPLY_DELETE,
    payload: {
      postIds,
      project_id,
      params,
      selected_date
    }
  };
};

export const onSpamClickAuthor = (data) => {
  return {
    type: ON_SPAM_CLICK_AUTHOR,
    payload: data
  };
};

export const folderStatRequestAction = (projectId, keywordId, dateRange, src) => {
  return {
    type: FOLDER_STAT_REQUEST,
    payload: {
      projectId,
      keywordId,
      dateRange,
      src
    }
  }
}

export const folderStatSuccessAction = (stats) => {
  return {
    type: FOLDER_STAT_SUCCESS,
    payload: stats
  }
}

export const onReadClick = (data) => {
  return {
    type: ON_READ_CLICK,
    payload: data
  };
};

export const onNewKeywordLoad = () => {
  return {
    type: ON_NEW_KEYWORD_LOAD
  }
};

export const fetchMailContinously = (params) => {
  return {
    type: FETCH_CONTINUOUSLY,
    payload: params
  }
};

export const onFilterToggleDrawer = () => {
  return {
    type: ON_FILTER_TOGGLE_DRAWER
  };
};

export const getLoggedInUser = (user) => {
  return {
    type : ON_USER_FETCHED_SUCCESS,
    payload : user
  }
};

export const showNewPosts = (new_posts) => {
  return {
    type: SHOW_NEW_POSTS,
    payload: new_posts
  }
};

export const onApplyStarred = (postIds, project_id, params, selected_date) => {
  return {
    type: ON_APPLY_STARRED,
    payload: {
      postIds,
      project_id,
      params,
      selected_date
    }
  };
};

export const onTagDelete = (postId, labelId, project_id, params) => {
  return {
    type: ON_TAG_DELETE,
    payload: {
      postId,
      labelId,
      project_id,
      params
    }
  };
};

export const addLabelAlready = (title) => {
  return {
    type: ADD_LABEL_ALREADY,
    payload: {
      title
    }
  };
};

export const addLabelAlreadySuccess = (label) => {
  return {
    type: ADD_LABEL_ALREADY_SUCCESS,
    payload: label
  }
}

export const onChangeAddLabelTitle = () => {
  return {
    type: ON_CHANGE_ADD_LABEL_TITLE
  };
};

export const tagFilterSelected = () => {
  return {
    type: TAG_FILTER_SELECTED
  };
};

export const onDownloadClick = (params, selected_date) => {
  return {
    type: ON_DOWNLOAD_CLICK,
    payload: {
      params,
      selected_date
    }
  };
};

export const onDownloadSuccess = () => {
  return {
    type: ON_DOWNLOAD_SUCCESS
  };
};


export const detailLoaderHandler = () => {
  return {
    type: DETAIL_LOADER_HANDLER
  };
};

export const onMouseOver = (id) => {
  return {
    type: ON_MOUSE_OVER,
    payload: {
      postid: id
    }
  };
};

export const onMouseOut = () => {
  return {
    type: ON_MOUSE_OUT
  };
};

export const callLazyLoad = (lazyLoad) => {
  return {
    type: CALL_LAZY_LOAD,
    payload: {
      lazy_load: lazyLoad
    }
  };
};

export const setSentiState = (impact, checked) => {
  return {
    type: SENTI_ON_CHANGE_IMPACT,
    payload: {impact, checked}
  }
}

export const reportSentimentIssue = (id) => {
  return {
    type: REPORT_SENTI_ISSUE,
    payload: {id}
  }
}

export const onSentiChange = (val, old_val, id, project_id, kw_id) => {
  return {
    type: ON_SENTI_CHANGE,
    payload: {val:val, old_val:old_val, id:id, project_id: project_id, kw_id: kw_id}
  };
};

export const titleSubmit = (title) => {
  return {
    type: ON_TITLE_SUBMIT,
    payload: {title}
  }
}

export const wordSubmit = (word) => {
  return {
    type: ON_WORD_SUBMIT,
    payload: {word}
  }
}

export const webSubmit = (web) => {
  return {
    type: ON_WEB_SUBMIT,
    payload: {web}
  }
}


export const titleDelete = (title) => {
  return {
    type: ON_TITLE_DELETE,
    payload: {title}
  }
}

export const wordDelete = (word) => {
  return {
    type: ON_WORD_DELETE,
    payload: {word}
  }
}

export const webDelete = (web) => {
  return {
    type: ON_WEB_DELETE,
    payload: {web}
  }
}

export const resetFilter = () => {
  return {
    type: RESET_FILTER
  }
}

export const filterSubmit = (titleList, webList, wordList, sentiPos, sentiNeg, sentiNet, sentiUnk, filterName, project_id, kw_id, langList, sortList, locList) => {
  return {
    type: ON_FILTER_SUBMIT,
    payload: {titleList, webList, wordList, sentiPos, sentiNeg, sentiNet, sentiUnk, filterName, project_id, kw_id, langList, sortList, locList }
  }
}

export const onFilterSelect = () => {
  return {
    type: ON_FILTER_SELECT
  };
};

export const filterSubmitSuccess = () => {
  return {
    type: ON_FILTER_SUBMIT_SUCCESS
  };
};

export const getfilterList = (project_id, kw_id) => {
  return {
    type: GET_FILTER_LIST,
    payload: {project_id, kw_id}
  }
}

export const getFilterSuccess = (list) => {
  return {
    type: GET_FILTER_SUCCESS,
    payload: list
  }
}

export const onDeleteFilter = (filter_id) => {
  return {
    type: ON_DELETE_FILTER,
    payload: {
      filter_id
    }
  }
};

export const applySavedFilter = (info) => {
  return {
    type: APPLY_SAVED_FILTER,
    payload: {
      info
    }
  }
};

export const langSubmit = (data) => {
  return {
    type: ON_LANG_SUBMIT,
    payload: {data}
  }
}

export const langDelete = (data) => {
  return {
    type: ON_LANG_DELETE,
    payload: {data}
  }
}

export const onLangChange = (val, old_val, id, project_id, kw_id) => {
  return {
    type: ON_LANG_CHANGE,
    payload: {val:val, old_val:old_val, id:id, project_id: project_id, kw_id: kw_id}
  };
};

export const locSubmit = (data) => {
  return {
    type: ON_LOC_SUBMIT,
    payload: {data}
  }
}

export const locDelete = (data) => {
  return {
    type: ON_LOC_DELETE,
    payload: {data}
  }
}

export const onLocChange = (val, old_val, id, project_id, kw_id) => {
  return {
    type: ON_LOC_CHANGE,
    payload: {val:val, old_val:old_val, id:id, project_id: project_id, kw_id: kw_id}
  };
};

export const changeDateRangeCalendar = (newRange) => {
  return {
    type: CHANGE_DATE_RANGE_CALENDAR,
    payload: {newRange}
  }
}

export const sortSubmit = (data) => {
  return {
    type: ON_SORT_SUBMIT,
    payload: {data}
  }
}

export const sortDelete = (data) => {
  return {
    type: ON_SORT_DELETE,
    payload: {data}
  }
}

export const onSortChange = (val, old_val, id, project_id, kw_id) => {
  return {
    type: ON_SORT_CHANGE,
    payload: {val:val, old_val:old_val, id:id, project_id: project_id, kw_id: kw_id}
  };
};

export const saveLastSelectedDate = (id) => {
  return {
    type: SAVE_LAST_SELECTED_DATE,
    payload: id
  };
}

export const relsectLastSelectedDate = (id) => {
  return {
    type: RESELECT_LAST_SELECTED_DATE,
    payload: id
  };
}

export const getLang = (lang) => {
  return {
    type: GET_LANG,
    payload: lang
  };
}
