import {
  FETCH_PLANS,
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_FAILED,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_FAILED,
  UPDATE_SUBSCRIPTION_SUCCESS,
  WHITE_LABEL_INFO_SUCCESS
} from 'constants/ActionTypes';

export const fetchPlans = () => {
  return {
    type: FETCH_PLANS
  }
}

export const fetchPlanSuccess = (response) => {
  return {
    type: FETCH_PLANS_SUCCESS,
    payload: response
  }
}

export const fetchPlanFailure = (message) => {
  return {
    type: FETCH_PLANS_FAILED,
    payload: message
  }
}

export const updateSubscription = (productId, price) => {
  return {
    type: UPDATE_SUBSCRIPTION,
    payload: {productId, price}
  }
}

export const updateSubscriptionFailure = (error) => {
  return {
    type: UPDATE_SUBSCRIPTION_FAILED,
    payload: error
  }
}

export const updateSubscriptionSuccess = (response) => {
  return {
    type: UPDATE_SUBSCRIPTION_SUCCESS,
    payload: response
  }
}

export const whiteLabelInfoSuccess = (response) => {
  return {
    type: WHITE_LABEL_INFO_SUCCESS,
    payload: response
  }
}
