import {all} from 'redux-saga/effects';
import mailSagas from './Mail';
import projectSagas from './Project';
import accountSagas from './Account';
import analyticsSagas from './Analytics';
import InfluencerResearchSagas from './InfluencerResearch';

export default function* rootSaga(getState) {
  yield all([
    mailSagas(),
    projectSagas(),
    accountSagas(),
    analyticsSagas(),
    InfluencerResearchSagas()
  ]);
}
