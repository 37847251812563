export default {  'en' :
  {
    'searchLang': 'Search Language',
    'select': 'Select',
    'issueDesc': 'Issue Description',
    'date' : 'Date',
    'langChangeSuccess' : 'Language changed Successfully',
    'locChangeSuccess' : 'Location changed Successfully',
    'impactChangeSuccess': 'Impact changed Successfully',
    'postTagRemSuccess': 'Post Tag removed successfully.',
    'movespamSuccess': 'Moved this author posts to Spam Successfully',
    'srcUpdated': 'Source Updated',
    'noteaddedSuccess': 'Note Added Successfully',
    'addnote': 'Please add note',
    'moveSpamSuccess': 'Moved to Spam Successfully',
    'postReplySuccess': 'Post Reply added Successfully',
    'postDelSuccess': 'Post Deleted Successfully',
    'postBlockSuccess': 'Post Blocked Successfully',
    'postDelSuccess': 'Post Deleted Successfully',
    'postMarkStar': 'Post Mark as Star',
    'postRemoveStar': 'Post Remove as Star',
    'noPostSelFolder': 'No post found in selected folder',
    'mailSentSuccess': 'Mail Sent Successfully',
    'noPostFoundSelLabel': 'No post found in selected label',
    'labelUpdateSuccess': 'Label Updated Successfully',
    'postMarkImp': 'Post Mark as Important',
    'postRemoveImp': 'Post Remove as Important',
    'postMoveSuccess': 'Post has been moved successfully',
    'noPostFoundSelFilter': 'No post found in selected filter'
  },
  'fr' :
  {
    'searchLang': 'Search Language-1',
    'select': 'Select-1',
    'issueDesc': 'Issue Description-1',
    'date' : 'Date-1',
    'langChangeSuccess' : 'Language changed Successfully-1',
    'locChangeSuccess' : 'Location changed Successfully-1',
    'impactChangeSuccess': 'Impact changed Successfully-1',
    'postTagRemSuccess': 'Post Tag removed successfully.-1',
    'movespamSuccess': 'Moved this author posts to Spam Successfully-1',
    'srcUpdated': 'Source Updated-1',
    'noteaddedSuccess': 'Note Added Successfully-1',
    'addnote': 'Please add note-1',
    'moveSpamSuccess': 'Moved to Spam Successfully-1',
    'postReplySuccess': 'Post Reply added Successfully-1',
    'postDelSuccess': 'Post Deleted Successfully-1',
    'postBlockSuccess': 'Post Blocked Successfully-1',
    'postDelSuccess': 'Post Deleted Successfully-1',
    'postMarkStar': 'Post Mark as Star-1',
    'postRemoveStar': 'Post Remove as Star-1',
    'noPostSelFolder': 'No post found in selected folder-1',
    'mailSentSuccess': 'Mail Sent Successfully-1',
    'noPostFoundSelLabel': 'No post found in selected label-1',
    'labelUpdateSuccess': 'Label Updated Successfully-1',
    'postMarkImp': 'Post Mark as Important-1',
    'postRemoveImp': 'Post Remove as Important-1',
    'postMoveSuccess': 'Post has been moved successfully-1',
    'noPostFoundSelFilter': 'No post found in selected filter-1'
  },
  'es' :
  {
    'searchLang': 'Search Language-1',
    'select': 'Select-1',
    'issueDesc': 'Issue Description-1',
    'date' : 'Date-1',
    'langChangeSuccess' : 'Language changed Successfully-1',
    'locChangeSuccess' : 'Location changed Successfully-1',
    'impactChangeSuccess': 'Impact changed Successfully-1',
    'postTagRemSuccess': 'Post Tag removed successfully.-1',
    'movespamSuccess': 'Moved this author posts to Spam Successfully-1',
    'srcUpdated': 'Source Updated-1',
    'noteaddedSuccess': 'Note Added Successfully-1',
    'addnote': 'Please add note-1',
    'moveSpamSuccess': 'Moved to Spam Successfully-1',
    'postReplySuccess': 'Post Reply added Successfully-1',
    'postDelSuccess': 'Post Deleted Successfully-1',
    'postBlockSuccess': 'Post Blocked Successfully-1',
    'postDelSuccess': 'Post Deleted Successfully-1',
    'postMarkStar': 'Post Mark as Star-1',
    'postRemoveStar': 'Post Remove as Star-1',
    'noPostSelFolder': 'No post found in selected folder-1',
    'mailSentSuccess': 'Mail Sent Successfully-1',
    'noPostFoundSelLabel': 'No post found in selected label-1',
    'labelUpdateSuccess': 'Label Updated Successfully-1',
    'postMarkImp': 'Post Mark as Important-1',
    'postRemoveImp': 'Post Remove as Important-1',
    'postMoveSuccess': 'Post has been moved successfully-1',
    'noPostFoundSelFilter': 'No post found in selected filter-1'
  },
  'zh' :
  {
    'searchLang': 'Search Language-1',
    'select': 'Select-1',
    'issueDesc': 'Issue Description-1',
    'date' : 'Date-1',
    'langChangeSuccess' : 'Language changed Successfully-1',
    'locChangeSuccess' : 'Location changed Successfully-1',
    'impactChangeSuccess': 'Impact changed Successfully-1',
    'postTagRemSuccess': 'Post Tag removed successfully.-1',
    'movespamSuccess': 'Moved this author posts to Spam Successfully-1',
    'srcUpdated': 'Source Updated-1',
    'noteaddedSuccess': 'Note Added Successfully-1',
    'addnote': 'Please add note-1',
    'moveSpamSuccess': 'Moved to Spam Successfully-1',
    'postReplySuccess': 'Post Reply added Successfully-1',
    'postDelSuccess': 'Post Deleted Successfully-1',
    'postBlockSuccess': 'Post Blocked Successfully-1',
    'postDelSuccess': 'Post Deleted Successfully-1',
    'postMarkStar': 'Post Mark as Star-1',
    'postRemoveStar': 'Post Remove as Star-1',
    'noPostSelFolder': 'No post found in selected folder-1',
    'mailSentSuccess': 'Mail Sent Successfully-1',
    'noPostFoundSelLabel': 'No post found in selected label-1',
    'labelUpdateSuccess': 'Label Updated Successfully-1',
    'postMarkImp': 'Post Mark as Important-1',
    'postRemoveImp': 'Post Remove as Important-1',
    'postMoveSuccess': 'Post has been moved successfully-1',
    'noPostFoundSelFilter': 'No post found in selected filter-1'
  },
  'it' :
  {
    'searchLang': 'Search Language-1',
    'select': 'Select-1',
    'issueDesc': 'Issue Description-1',
    'date' : 'Date-1',
    'langChangeSuccess' : 'Language changed Successfully-1',
    'locChangeSuccess' : 'Location changed Successfully-1',
    'impactChangeSuccess': 'Impact changed Successfully-1',
    'postTagRemSuccess': 'Post Tag removed successfully.-1',
    'movespamSuccess': 'Moved this author posts to Spam Successfully-1',
    'srcUpdated': 'Source Updated-1',
    'noteaddedSuccess': 'Note Added Successfully-1',
    'addnote': 'Please add note-1',
    'moveSpamSuccess': 'Moved to Spam Successfully-1',
    'postReplySuccess': 'Post Reply added Successfully-1',
    'postDelSuccess': 'Post Deleted Successfully-1',
    'postBlockSuccess': 'Post Blocked Successfully-1',
    'postDelSuccess': 'Post Deleted Successfully-1',
    'postMarkStar': 'Post Mark as Star-1',
    'postRemoveStar': 'Post Remove as Star-1',
    'noPostSelFolder': 'No post found in selected folder-1',
    'mailSentSuccess': 'Mail Sent Successfully-1',
    'noPostFoundSelLabel': 'No post found in selected label-1',
    'labelUpdateSuccess': 'Label Updated Successfully-1',
    'postMarkImp': 'Post Mark as Important-1',
    'postRemoveImp': 'Post Remove as Important-1',
    'postMoveSuccess': 'Post has been moved successfully-1',
    'noPostFoundSelFilter': 'No post found in selected filter-1'
  },
  'ar' :
  {
    'searchLang': 'Search Language-1',
    'select': 'Select-1',
    'issueDesc': 'Issue Description-1',
    'date' : 'Date-1',
    'langChangeSuccess' : 'Language changed Successfully-1',
    'locChangeSuccess' : 'Location changed Successfully-1',
    'impactChangeSuccess': 'Impact changed Successfully-1',
    'postTagRemSuccess': 'Post Tag removed successfully.-1',
    'movespamSuccess': 'Moved this author posts to Spam Successfully-1',
    'srcUpdated': 'Source Updated-1',
    'noteaddedSuccess': 'Note Added Successfully-1',
    'addnote': 'Please add note-1',
    'moveSpamSuccess': 'Moved to Spam Successfully-1',
    'postReplySuccess': 'Post Reply added Successfully-1',
    'postDelSuccess': 'Post Deleted Successfully-1',
    'postBlockSuccess': 'Post Blocked Successfully-1',
    'postDelSuccess': 'Post Deleted Successfully-1',
    'postMarkStar': 'Post Mark as Star-1',
    'postRemoveStar': 'Post Remove as Star-1',
    'noPostSelFolder': 'No post found in selected folder-1',
    'mailSentSuccess': 'Mail Sent Successfully-1',
    'noPostFoundSelLabel': 'No post found in selected label-1',
    'labelUpdateSuccess': 'Label Updated Successfully-1',
    'postMarkImp': 'Post Mark as Important-1',
    'postRemoveImp': 'Post Remove as Important-1',
    'postMoveSuccess': 'Post has been moved successfully-1',
    'noPostFoundSelFilter': 'No post found in selected filter-1'
  }
};
