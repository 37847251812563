import {all, call, put, takeEvery, takeLatest, delay, select} from 'redux-saga/effects';
import {
  fetchMailsSuccess,
  showMailMessage,
  fetchProjectsSuccess,
  showMessage,
  deleteLabelInfoSuccess,
  fetchNotesSuccess,
  addLabelInfoSuccess,
  folderStatSuccessAction,
  showNewPosts,
  addLabelAlreadySuccess,
  onDownloadSuccess,
  getFilterSuccess,
  filterSubmitSuccess,
  deleteFilterSuccess
} from 'actions/Mail';

import {
  FETCH_ALL_MAIL,
  ON_START_SELECT,
  ON_TRASH_CLICK,
  ON_REPLY_CLICK,
  ON_SPAM_CLICK,
  ON_NOTES_CLICK,
  ON_APPLY_LABEL,
  UPDATE_LABEL_INFO,
  ADD_LABEL_INFO,
  ADD_LABEL_ALREADY,
  DELETE_LABEL,
  ON_NOTES_SUBMIT,
  ON_APPLY_REPLY,
  ON_APPLY_DELETE,
  ON_SPAM_CLICK_AUTHOR,
  FOLDER_STAT_REQUEST,
  ON_READ_CLICK,
  FETCH_PROJECT_TAGS_REQUEST,
  FETCH_CONTINUOUSLY,
  ON_APPLY_STARRED,
  ON_TAG_DELETE,
  ON_CHANGE_ADD_LABEL_TITLE,
  ON_DOWNLOAD_CLICK,
  REPORT_SENTI_ISSUE,
  ON_SENTI_CHANGE,
  ON_FILTER_SUBMIT,
  GET_FILTER_LIST,
  ON_DELETE_FILTER,
  ON_LANG_CHANGE,
  ON_LOC_CHANGE,
} from 'constants/ActionTypes';
import apiClient from 'apiclient';
import queryString from 'query-string';
import moment from 'moment';
const getPostCount = (state) => state.mail.mail_page.total;
const currentActiveFolder = (state) => state.mail.selectedFolder;
const checkactiveSrc = (state) => state.mail.activeSrc;
const selectedDate = (state) => state.mail.selectedDate;
const sentiPos = (state) => state.mail.sentiPos;
const sentiNeg = (state) => state.mail.sentiNeg;
const sentiNet = (state) => state.mail.sentiNet;
const sentiUnk = (state) => state.mail.sentiUnk;
const sentiLead = (state) => state.mail.sentiLead;

const titleList = (state) => state.mail.titleList;
const wordList = (state) => state.mail.wordList;
const webList = (state) => state.mail.webList;
const langList = (state) => state.mail.langList;
const locList = (state) => state.mail.locList;
const sortList = (state) => state.mail.sortList;

const check_mail = (state) => state.mail.folderMails;

const getMails = async (params) => {
  const page = (params.page === undefined) ? 1 : params.page;
  const disable_page = (params.disable_page === undefined) ? 0 : params.disable_page;
  const old_disable = (params.old_disable === undefined) ? 0 : params.old_disable;
  const last_post_id = (params.last_post_id === undefined) ? 0 : params.last_post_id;
  const show = (params.show === undefined) ? 0 : params.show;
  const tag_id = (params.tag_id === undefined) ? 0 : params.tag_id;
  const per_page = (params.per_page === undefined) ? 10 : params.per_page;
  const dateRange = (params.dateRange === undefined) ? {
    to: moment().format('YYYY-MM-DD'),
    from: moment().subtract(1825,'d').format('YYYY-MM-DD'),
    label: "All Time",
    id: 6
  } : params.dateRange;

  const post_det = {
      kw_id: parseInt(params.kw_id),
      project_id: parseInt(params.project_id),
      page: page,
      show: show,
      from: dateRange.from,
      to: dateRange.to,
      src: params.src,
      tag_id: tag_id,
      runnow_init: 1,
      per_page: parseInt(per_page),
      sentiPos: params.sentiPos,
      sentiNeg: params.sentiNeg,
      sentiNet: params.sentiNet,
      sentiUnk: params.sentiUnk,
      sentiLead: params.sentiLead,
      titleList: params.titleList,
      webList: params.webList,
      wordList: params.wordList,
      langList: params.langList,
      locList: params.locList,
      sortList: params.sortList,
      lastest_added: params.lastest_added,
      disable_page: disable_page,
      old_disable: old_disable,
      last_post_id: last_post_id,


    };
  return await apiClient.get('/api/post/list?' + queryString.stringify(post_det))
      .then(response => response.data);
      // .catch(error => error);
};

const getMailsExport = async (params) => {
  const show = (params.show === undefined) ? 0 : params.show;
  const tag_id = (params.tag_id === undefined) ? 0 : params.tag_id;
  // const per_page = (params.per_page === undefined) ? 10 : params.per_page;
  const dateRange = (params.dateRange === undefined) ? {
    to: moment().format('YYYY-MM-DD'),
    from: moment().subtract(1825,'d').format('YYYY-MM-DD'),
    label: "Up to Yesterday",
    id: 7
  } : params.dateRange;
  const post_det = {
      kw_id: parseInt(params.kw_id),
      project_id: parseInt(params.project_id),
      show: show,
      from: dateRange.from,
      to: dateRange.to,
      src: params.src,
      tag_id: tag_id,
      runnow_init: 1,
      sentiPos: params.sentiPos,
      sentiNeg: params.sentiNeg,
      sentiNet: params.sentiNet,
      sentiUnk: params.sentiUnk,
      sentiLead: params.sentiLead,
      titleList: params.titleList,
      webList: params.webList,
      wordList: params.wordList,
      langList: params.langList,
      locList: params.locList,
      sortList: params.sortList,

    };
  // return await apiClient.post('/api/post/list/export', post_det, {timeout: 0})
  return await apiClient({url:'/api/post/list/export', method: 'post', data: post_det, timeout: 0, responseType: 'json'})
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data.link !== undefined) {
            let link = response.data.link;
            // const url = window.URL.createObjectURL(new Blob([response.data]));
            const ele = document.createElement('a');
            ele.href = link;
            ele.target = '_blank';
            const date = moment().format('YYYYMMDD');
            ele.setAttribute('download', params.kw_name.replace(/\s+/g, '_')+'_'+date+'.csv');
            document.body.appendChild(ele);
            ele.click();
            return true;
          } else {
            return false;
          }
        }
        else if (response.status === 400) {
          return false;
        }

  });
      // .catch(error => error);
};

const updateFavMails = async (params) => {
  const post_det = {
      fav: params.starred,
      post_id: parseInt(params.id)
    };
  return await apiClient.post('/api/update-fav-mails', post_det)
      .then(response => response.data);
      // .catch(error => error);
};

const updateTrashStatus = async (params) => {
  const post_det = {
      status: 0,
      post_id: parseInt(params.id),
      kw_id: parseInt(params.post.kw_id),
      p_id: parseInt(params.project_id)
  };
  return await apiClient.post('/api/update-trash-status', post_det)
      .then(response => response.data);
      // .catch(error => error);
};

const updateReplyStatus = async (params) => {
  const post_det = {
      read: 2,
      post_id: parseInt(params.id),
      kw_id: parseInt(params.post.kw_id),
      p_id: parseInt(params.project_id)
  };
  return await apiClient.post('/api/update-reply-status', post_det)
      .then(response => response.data);
      // .catch(error => error);
};

const updateSpamStatus = async (params) => {
  const post_det = {
      status: 2,
      post_id: parseInt(params.id),
      kw_id: parseInt(params.post.kw_id),
      p_id: parseInt(params.project_id)
  };
  return await apiClient.post('/api/update-spam-status', post_det)
      .then(response => response.data);
      // .catch(error => error);
};

const updateSpamStatusAuthor = async (params) => {
  const post_det = {
      author: params.post.author,
      kw_id: parseInt(params.post.kw_id),
      p_id: parseInt(params.post.project_id),
      src: parseInt(params.post.src)
  };
  return await apiClient.get('/api/update-spam-status-author?' + queryString.stringify(post_det))
      .then(response => response.data);
      // .catch(error => error);
};

const showPostNotes = async (params) => {
  const page = (params.params.page === undefined) ? 0 : params.params.page;
  const post_det = {
      post_id: parseInt(params.mail.id),
      status: 1,
      page: page
  };
  return await apiClient.post('/api/show-post-notes',post_det)
      .then(response => response.data);
      // .catch(error => error);
};

const updateLabelInfo = async (params) => {
  const projectId = (params.projectId === undefined) ? 0 : params.projectId;
  const tagId = (params.id === undefined) ? 0 : params.id;
  const title = (params.title === undefined) ? 0 : params.title;
  const color = (params.color === undefined) ? 0 : params.color;
  const post_det = {
    title,
    tagId,
    color
  }
  return await apiClient.get('/api/project/'+projectId+'/tag/edit/update?' + queryString.stringify(post_det))
    .then(response => response.data);
    // .catch(error => error);
};
const addNotesData = async (params) => {

  const post_det = {
      post_id: parseInt(params.id),
      notes : params.post.notes,
      status: 1
  };
  return await apiClient.post('/api/add-notes-data' ,post_det)
      .then(response => response.data);
      // .catch(error => error);
};

function* onStartSelect(res) {
  try {
    yield call(updateFavMails, res.payload.data);
    if (res.payload.params.page !== undefined) {

      const fetchedMail = yield call(getMails, res.payload.params);
      yield put(fetchMailsSuccess(fetchedMail));
    }


  } catch (error) {
    yield put(showMailMessage("Oops! Unable to mark that post as favorite!"));
  }
}

function* onTrashClick(res) {
  try {
    yield call(updateTrashStatus, res.payload);
  } catch (error) {
    yield put(showMailMessage("Oops! Unable to delete that post!"));
  }
}

function* onReplyClick(res) {
  try {
    yield call(updateReplyStatus, res.payload);
  } catch (error) {
    yield put(showMailMessage("Oops! Unable to mark that post as replied!"));
  }
}

function* onSpamClick(res) {
  try {
    yield call(updateSpamStatus, res.payload);
  } catch (error) {
    yield put(showMailMessage("Oops! Unable to mark that post as spam!"));
  }
}

function* onSpamClickAuthor(res) {
  try {
    yield call(updateSpamStatusAuthor, res.payload);
    // let folder_req = {
    //   "projectId" : res.payload.params.project_id,
    //   "keywordId": res.payload.params.kw_id,
    //   "dateRange": res.payload.selected_date
    // };
    // const folderStats = yield call(fetchFolderStats, folder_req);
    // yield put(folderStatSuccessAction(folderStats));
  } catch (error) {
    yield put(showMailMessage("Oops! Unable to mark that author as spam!"));
  }
}

function* onNotesClick(res) {
  try {
    const notes = yield call(showPostNotes, res.payload);
    yield put(fetchNotesSuccess(notes));
  } catch (error) {
    yield put(showMailMessage("Oops! Unable to add notes to post!"));
  }
}
function* onLabelInfoUpdate(request) {
  try {
    yield call(updateLabelInfo, request.payload);
  } catch (error) {
    yield put(showMailMessage("Oops! Unable to update tag!"));
  }
}

function* onNotesSubmit(res) {
  try {
    yield call(addNotesData, res.payload);
  } catch (error) {
    yield put(showMailMessage(error));
  }
}

function* fetchMailRequest(res) {
  try {
    let spos = yield select(sentiPos);
    let sneg = yield select(sentiNeg);
    let snet = yield select(sentiNet);
    let sunk = yield select(sentiUnk);
    let lead = yield select(sentiLead);

    let title = yield select(titleList);
    let word = yield select(wordList);
    let web = yield select(webList);
    let langdata = yield select(langList);
    let locdata = yield select(locList);
    let sortdata = yield select(sortList);
    let langFilterList = [];
    langdata.map(lang => {
      langFilterList.push(lang.code);
    });

    let locFilterList = [];
    locdata.map(loc => {
      locFilterList.push(loc.code);
    });

    let sortFilterList = [];
    sortdata.map(sort => {
      sortFilterList.push(sort.code);
    });

    res.params['sentiPos'] = spos;
    res.params['sentiNeg'] = sneg;
    res.params['sentiNet'] = snet;
    res.params['sentiUnk'] = sunk;
    res.params['sentiLead'] = lead;
    res.params['titleList'] = title.join(',');
    res.params['wordList'] = word.join(',');
    res.params['webList'] = web.join(',');
    res.params['langList'] = langFilterList.join(',');
    res.params['locList'] = locFilterList.join(',');
    res.params['sortList'] = sortFilterList.join(',');

    let folderMails = yield select(check_mail);
    let lastest_added = 0;
    if (folderMails !== undefined && folderMails.length > 0) {
      lastest_added = folderMails[0]["added"];
    }
    res.params['lastest_added'] = lastest_added;
    const fetchedMail = yield call(getMails, res.params);
    //const fetchedMailExport = yield call(getMailsExport, res.params);
    yield put(fetchMailsSuccess(fetchedMail));
  } catch (error) {
    console.log(error);
    yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

function* fetchMailRequestExport(res) {
  try {
    const kw_params = {kw_id : parseInt(res.payload.params.kw_id)}
    const keywordName = yield call(getKeywordName, kw_params);
    res.payload.params.kw_name = decodeURIComponent(keywordName);
    let spos = yield select(sentiPos);
    let sneg = yield select(sentiNeg);
    let snet = yield select(sentiNet);
    let sunk = yield select(sentiUnk);
    let lead = yield select(sentiLead);
    let title = yield select(titleList);
    let word = yield select(wordList);
    let web = yield select(webList);
    let langdata = yield select(langList);
    let locdata = yield select(locList);
    let sortdata = yield select(sortList);

    let langFilterList = [];
    langdata.map(lang => {
      langFilterList.push(lang.code);
    });

    let locFilterList = [];
    locdata.map(loc => {
      locFilterList.push(loc.code);
    });

    let sortFilterList = [];
    sortdata.map(sort => {
      sortFilterList.push(sort.code);
    });

    res.payload.params['sentiPos'] = spos;
    res.payload.params['sentiNeg'] = sneg;
    res.payload.params['sentiNet'] = snet;
    res.payload.params['sentiUnk'] = sunk;
    res.payload.params['sentiLead'] = lead;
    res.payload.params['titleList'] = title.join(',');
    res.payload.params['wordList'] = word.join(',');
    res.payload.params['webList'] = web.join(',');
    res.payload.params['langList'] = langFilterList.join(',');
    res.payload.params['locList'] = locFilterList.join(',');
    res.payload.params['sortList'] = sortFilterList.join(',');

    const fetchedMailExport = yield call(getMailsExport, res.payload.params);
    if(fetchedMailExport === false) {
      yield put(showMailMessage("Oops! Something went wrong with export!"));
    }
    yield put(onDownloadSuccess());
  } catch (error) {
    console.log(error);
    yield put(onDownloadSuccess());
    yield put(showMailMessage("Oops! Unable to export mail Content right now!"));
  }
}

const getProjectTags = async (params) => {
  let project_id = (params.project_id === undefined) ? 0 : params.project_id;
  return await apiClient.get('/api/project/'+project_id+'/tag')
      .then(response => response.data);
      // .catch(error => error);
};

const getKeywordName = async (params) => {
  return await apiClient.get('/api/get-keyword-name/'+params.kw_id)
      .then(response => response.data);
      // .catch(error => error);
};

const getUserKeywordDetail = async (params) => {
  let requestData = {
    kw_id: (params.kw_id === undefined) ? 0 : params.kw_id,
    project_id: (params.project_id === undefined) ? 0 : params.project_id
  };
  return await apiClient.post('/api/get-user-keyword-detail', requestData)
      .then(response => response.data);
      // .catch(error => error);
};

function* fetchProjectTagsRequest(res) {
  try {
    const projectTags = yield call(getProjectTags, res.payload);
    const keywordName = yield call(getKeywordName, res.payload);
    const userkeywordDetail = yield call(getUserKeywordDetail, res.payload);
    yield put(fetchProjectsSuccess(projectTags, decodeURIComponent(keywordName), userkeywordDetail));
  } catch (error) {
    yield put(showMessage("Oops! Unable to fetch your labels!"));
  }
}

const labelUpdateRequest = async (request) => {
  let requestData = {
    'postIds': request.postIds.join("|,|"),
    'labelId': request.labelId
  };
  return await apiClient.get('/api/project/'+ request['project_id'] +'/tag/update?' + queryString.stringify(requestData))
  .then(response => response.data);
  // .catch(error => error);
};

function* onApplyLabel(request) {
  try {

    let label_req = {
      "postIds" : request.payload.postIds,
      "labelId": request.payload.labelId,
      "project_id" : request.payload.project_id
    }
    yield call(labelUpdateRequest, label_req);

    // const fetchedMail = yield call(getMails, request.payload.params);
    // yield put(fetchMailsSuccess(fetchedMail));


  } catch (error) {
    yield put(showMailMessage("Oops! Unable to mark label that post!"));
  }
}

const replyUpdateRequest = async (request) => {
  let requestData = {
    'postIds': request.postIds.join("|,|"),
    'read': 2
  };
  return await apiClient.get('/api/update-selected-reply?' + queryString.stringify(requestData))
  .then(response => response.data);
  // .catch(error => error);
};

function* onApplyReply(request) {
  try {

    let reply_req = {
      "postIds" : request.payload.postIds,
      "project_id" : request.payload.project_id
    }
    yield call(replyUpdateRequest, reply_req);

    let spos = yield select(sentiPos);
    let sneg = yield select(sentiNeg);
    let snet = yield select(sentiNet);
    let sunk = yield select(sentiUnk);
    let lead = yield select(sentiLead);

    let title = yield select(titleList);
    let word = yield select(wordList);
    let web = yield select(webList);
    let langdata = yield select(langList);
    let locdata = yield select(locList);
    let sortdata = yield select(sortList);

    let langFilterList = [];
    langdata.map(lang => {
      langFilterList.push(lang.code);
    });

    let locFilterList = [];
    locdata.map(loc => {
      locFilterList.push(loc.code);
    });

    let sortFilterList = [];
    sortdata.map(sort => {
      sortFilterList.push(sort.code);
    });

    request.payload.params['sentiPos'] = spos;
    request.payload.params['sentiNeg'] = sneg;
    request.payload.params['sentiNet'] = snet;
    request.payload.params['sentiUnk'] = sunk;
    request.payload.params['sentiLead'] = lead;

    request.payload.params['titleList'] = title.join(',');
    request.payload.params['wordList'] = word.join(',');
    request.payload.params['webList'] = web.join(',');
    request.payload.params['langList'] = langFilterList.join(',');
    request.payload.params['locList'] = locFilterList.join(',');
    request.payload.params['sortList'] = sortFilterList.join(',');

    const fetchedMail = yield call(getMails, request.payload.params);
    yield put(fetchMailsSuccess(fetchedMail));

    let folder_req = {
      "projectId" : request.payload.params.project_id,
      "keywordId": request.payload.params.kw_id,
      "dateRange": request.payload.selected_date,
      "sentiPos" : spos,
      "sentiNeg" : sneg,
      "sentiNet" : snet,
      "sentiUnk" : sunk,
      "sentiLead" : lead,
      "titleList" : title.join(','),
      "wordList" : word.join(','),
      "webList" : web.join(','),
      "langList" : langFilterList.join(','),
      "locList" : locFilterList.join(','),
      "sortList" : sortFilterList.join(',')
    };

    const folderStats = yield call(fetchFolderStats, folder_req);
    yield put(folderStatSuccessAction(folderStats));

  } catch (error) {
    yield put(showMailMessage("Oops! Unable to mark those posts as replied!"));
  }
}

const deleteUpdateRequest = async (request) => {
  let requestData = {
    'postIds': request.postIds.join("|,|"),
    'status': 0
  };
  return await apiClient.get('/api/update-selected-delete?' + queryString.stringify(requestData))
  .then(response => response.data);
  // .catch(error => error);
};

function* onApplyDelete(request) {
  try {

    let delete_req = {
      "postIds" : request.payload.postIds,
      "project_id" : request.payload.project_id
    }
    yield call(deleteUpdateRequest, delete_req);

    let spos = yield select(sentiPos);
    let sneg = yield select(sentiNeg);
    let snet = yield select(sentiNet);
    let sunk = yield select(sentiUnk);
    let lead = yield select(sentiLead);

    let title = yield select(titleList);
    let word = yield select(wordList);
    let web = yield select(webList);
    let langdata = yield select(langList);
    let locdata = yield select(locList);

    let sortdata = yield select(sortList);

    let langFilterList = [];
    langdata.map(lang => {
      langFilterList.push(lang.code);
    });

    let locFilterList = [];
    locdata.map(loc => {
      locFilterList.push(loc.code);
    });

    let sortFilterList = [];
    sortdata.map(sort => {
      sortFilterList.push(sort.code);
    });

    request.payload.params['sentiPos'] = spos;
    request.payload.params['sentiNeg'] = sneg;
    request.payload.params['sentiNet'] = snet;
    request.payload.params['sentiUnk'] = sunk;
    request.payload.params['sentiLead'] = lead;

    request.payload.params['titleList'] = title.join(',');
    request.payload.params['wordList'] = word.join(',');
    request.payload.params['webList'] = web.join(',');
    request.payload.params['langList'] = langFilterList.join(',');
    request.payload.params['locList'] = locFilterList.join(',');
    request.payload.params['sortList'] = sortFilterList.join(',');


    const fetchedMail = yield call(getMails, request.payload.params);
    yield put(fetchMailsSuccess(fetchedMail));

    let folder_req = {
      "projectId" : request.payload.params.project_id,
      "keywordId": request.payload.params.kw_id,
      "dateRange": request.payload.selected_date,
      "sentiPos" : spos,
      "sentiNeg" : sneg,
      "sentiNet" : snet,
      "sentiUnk" : sunk,
      "sentiLead" : lead,
      "titleList" : title.join(','),
      "wordList" : word.join(','),
      "webList" : web.join(','),
      "langList" : langFilterList.join(','),
      "locList" : locFilterList.join(','),
      "sortList" : sortFilterList.join(',')
    };
    const folderStats = yield call(fetchFolderStats, folder_req);
    yield put(folderStatSuccessAction(folderStats));


  } catch (error) {
    yield put(showMailMessage("Oops! Unable to delete those posts!"));
  }
}

const addLabelRequest = async (request) => {
  let requestData = {
    title: request.title,
    color: request.color,
    projectId: request.projectId
  };
  return await apiClient.get('/api/project/'+ request['projectId'] +'/tag/add?' + queryString.stringify(requestData))
  .then(response => response.data);
  // .catch(error => error);
}

const deleteLabelRequest = async (request) => {
  let requestData = {
    labelId: request.labelId,
    projectId: request.projectId
  };
  return await apiClient.get('/api/project/'+ request['projectId'] +'/tag/remove?' + queryString.stringify(requestData))
  .then(response => response.data);
  // .catch(error => error);
}



function* addLabelAlready(request) {
  try {
    yield put(addLabelAlreadySuccess(request.payload.title));
  } catch (error) {
    yield put(showMailMessage("Oops! Unable to add label!"));
  }
}

function* addLabel(request) {
  try {
    const newLabel = yield call(addLabelRequest, request.payload);
    yield put(addLabelInfoSuccess(newLabel));
  } catch (error) {
    yield put(showMailMessage("Oops! Unable to add label!"));
  }
}

function* deleteLabel(request) {
  try {
    const deletedLabel = yield call(deleteLabelRequest, request.payload);
    yield put(deleteLabelInfoSuccess(deletedLabel));
  } catch (error) {
    yield put(showMailMessage("Oops! Unable to delete that label!"));
  }
}

const fetchFolderStats = async (request) => {
  const dateRange = (request.dateRange === undefined) ? {
    to: moment().format('YYYY-MM-DD'),
    from: moment().subtract(1825,'d').format('YYYY-MM-DD'),
    label: "Up to Yesterday",
    id: 7
  } : request.dateRange;

  let requestData = {
    kw_id: request.keywordId === undefined ? request.kw_id : request.keywordId,
    project_id: request.projectId === undefined ? request.project_id : request.projectId,
    from: dateRange.from,
    to: dateRange.to,
    src: request.src,
    sentiPos: request.sentiPos,
    sentiNeg: request.sentiNeg,
    sentiNet: request.sentiNet,
    sentiUnk: request.sentiUnk,
    sentiLead: request.sentiLead,
    titleList: request.titleList,
    wordList: request.wordList,
    webList: request.webList,
    langList: request.langList,
    locList: request.locList
  };
  return await apiClient.post('/api/post/folder-stats', requestData)
  .then(response => response.data);
  // .catch(error => error);
};

function* getFolderStats(request) {
  try {
    let spos = yield select(sentiPos);
    let sneg = yield select(sentiNeg);
    let snet = yield select(sentiNet);
    let sunk = yield select(sentiUnk);
    let lead = yield select(sentiLead);

    let title = yield select(titleList);
    let word = yield select(wordList);
    let web = yield select(webList);
    let langdata = yield select(langList);
    let locdata = yield select(locList);

    let langFilterList = [];
    langdata.map(lang => {
      langFilterList.push(lang.code);
    });

    let locFilterList = [];
    locdata.map(loc => {
      locFilterList.push(loc.code);
    });

    request.payload['sentiPos'] = spos;
    request.payload['sentiNeg'] = sneg;
    request.payload['sentiNet'] = snet;
    request.payload['sentiUnk'] = sunk;
    request.payload['sentiLead'] = lead;

    request.payload['titleList'] = title.join(',');
    request.payload['wordList'] = word.join(',');
    request.payload['webList'] = web.join(',');
    request.payload['langList'] = langFilterList.join(',');
    request.payload['locList'] = locFilterList.join(',');

    const folderStats = yield call(fetchFolderStats, request.payload);
    yield put(folderStatSuccessAction(folderStats));
  } catch (error) {
    yield put(showMailMessage("Unable to load Inbox Summary"));
  }
}

function* onReadClick(res) {
  try {
    yield call(updateReadStatus, res.payload);
  } catch (error) {
    yield put(showMailMessage(error));
  }
}

const updateReadStatus = async (params) => {
  const post_det = {
      read: 1,
      post_id: parseInt(params.id)
  };
  return await apiClient.post('/api/update-read-status', post_det)
      .then(response => response.data);
      // .catch(error => error);
};

const checkPostCount = async (params, selDate) => {
  const post_det = {
      project_id: parseInt(params.project_id),
      kw_id: parseInt(params.kw_id),
      from: selDate.from,
      to: selDate.to
  };
  return await apiClient.post('/api/check-post-count', post_det)
      .then(response => response.data);
      // .catch(error => error);
};

function* fetchContinuously(res) {
  // let all_check = yield select(checkactiveSrc);
  console.log('1');
  yield select(checkactiveSrc);
  let currentFolder = yield select(currentActiveFolder);
  console.log(currentFolder);
  let selDate = yield select(selectedDate);
  console.log(selDate);
  if (currentFolder === 0) {
    console.log('if');
    const response = yield call(checkPostCount, res.payload, selDate);
    console.log(response);
    //let postCount = yield select(getPostCount);
    let folderMails = yield select(check_mail);
    let postCount = 0;
    if (folderMails !== undefined) {
      postCount = folderMails.length;
    }
    console.log(postCount);
    console.log('postCount');
    if (response['response'] > 0 && postCount === 0 && response['added'] === 1) {
      yield put({type : FETCH_ALL_MAIL, params : res.payload});
      yield put({type : FOLDER_STAT_REQUEST, payload : res.payload});
      // Found first few new post for keyword added today
      console.log('15');
      console.log('a');
      yield delay(15000);
    } else if(response['response'] === 0 && postCount === 0 && response['added'] === 1) {
      // Keyword added today, no new post yet
      yield delay(5000);
      console.log('5');
      console.log('b');
    } else if (response['response'] === 0 && postCount > 0 && response['added'] === 1) {
      // Keyword added today, no new post but shown some
      yield delay(15000);
      console.log('15');
      console.log('c');
    } else if (response['response'] > 0 && postCount > 0 && response['added'] === 1) {
      // removed a condition for checking: all_check.all !== 0
      let new_posts = response['response'];
      yield put(showNewPosts(new_posts));
      // Keyword added today, new post found and already shown some posts
      yield delay(15000);
      console.log('15');
      console.log('d');
    } else if (response['response'] > 0 && postCount === 0 && response['added'] === 0) {
      // removed a condition for checking: all_check.all !== 0
      yield put({type : FETCH_ALL_MAIL, params : res.payload});
      yield put({type : FOLDER_STAT_REQUEST, payload : res.payload});
      //let new_posts = response['response'];
      //yield put(showNewPosts(new_posts));
      // Keyword not added today, new post found later.
      yield delay(15000);
      console.log('15');
      console.log('e');
    } else if(response['response'] === 0 && postCount === 0 && response['added'] === 0) {
      // Keyword not added today, and no post found yet.
      yield delay(20000);
      console.log('20');
      console.log('f');
    } else if (response['response'] === 0 && postCount > 0 && response['added'] === 0) {
      // Keyword not added today, and no new post found showing old posts.
      yield delay(15000);
      console.log('15');
      console.log('g');
    } else if (response['response'] > 0 && postCount > 0 && response['added'] === 0) {
      // Keyword not added today, and new post found and showing old posts.
      let new_posts = response['response'];
      yield put(showNewPosts(new_posts));
      yield delay(15000);
      console.log('15');
      console.log('h');
    } else {
      yield delay(10000);
      console.log('10');
      console.log('i');
    }
    yield put({ type: FETCH_CONTINUOUSLY, payload: res.payload });
  } else {
    console.log('else');
    yield delay(20000);
    console.log('20');
    console.log('j');
    yield put({ type: FETCH_CONTINUOUSLY, payload: res.payload });
  }

}

function getMax(arr, prop) {
    var max;
    for (var i=0 ; i<arr.length ; i++) {
        if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop])) {
          max = arr[i];
        }

    }
    return max['post_id'];
}

function getMin(arr, prop) {
    var min;
    for (var i=0 ; i<arr.length ; i++) {
        if (min == null || parseInt(arr[i][prop]) < parseInt(min[prop])) {
          min = arr[i];
        }

    }
    return min['post_id'];
}

const starredUpdateRequest = async (request) => {
  let requestData = {
    'postIds': request.postIds
  };
  return await apiClient.post('/api/update-selected-starred' , requestData)
  .then(response => response.data);
  //.catch(error => error);
};

function* onApplyStarred(request) {
  try {
    let starred_req = {
      "postIds" : request.payload.postIds,
      "project_id" : request.payload.project_id
    }
    yield call(starredUpdateRequest, starred_req);

    // const fetchedMail = yield call(getMails, request.payload.params);
    // yield put(fetchMailsSuccess(fetchedMail));

    // let folder_req = {
    //   "projectId" : request.payload.params.project_id,
    //   "keywordId": request.payload.params.kw_id,
    //   "dateRange": request.payload.selected_date
    // };
    // const folderStats = yield call(fetchFolderStats, folder_req);
    // yield put(folderStatSuccessAction(folderStats));

  } catch (error) {
    yield put(showMailMessage("Oops! Unable to star these posts right now, please try later."));
  }
}

const tagDeleteRequest = async (request) => {
  let requestData = {
    'postId': request.postId,
    'labelId': request.labelId
  };
  return await apiClient.get('/api/project/'+ request['project_id'] +'/tag/post/delete?' + queryString.stringify(requestData))
  .then(response => response.data);
  // .catch(error => error);
};

function* onTagDelete(request) {
  try {
    let tag_request = {
      'postId': request.payload.postId,
      'labelId': request.payload.labelId,
      'project_id': request.payload.project_id
    };

    yield call(tagDeleteRequest, tag_request);

    // const fetchedMail = yield call(getMails, request.payload.params);
    // yield put(fetchMailsSuccess(fetchedMail));

    yield call(fetchProjectTagsRequest, request.payload.params);

  } catch (error) {
    yield put(showMailMessage("Oops! Unable to delete tag for post!"));
  }
}
function* onChangeAddLabelTitle(request) {
}

const reportSentiIssueRequest = async (payload) => {
  let upId = payload.payload.id;
  const postData = {id: upId};
return await apiClient.post('/api/report-issue/senti', postData)
    .then(response => response.data);
};

function* reportSentiIssue(payload) {
  try {
    let response = yield call(reportSentiIssueRequest, payload);
    yield put(showMailMessage("Reported this post for a review! Thank you for the feedback."));
  } catch (error) {

  }
}

const onSentiChangeRequest = async (res) => {
  let sentiVal = res.payload.val;
  let oldsentiVal = res.payload.old_val;

  let project_id = res.payload.project_id;
  let kw_id = res.payload.kw_id;
  const postData = {senti_val: sentiVal, old_senti_val: oldsentiVal,  post_id: res.payload.id, pid: project_id, kid: kw_id};
return await apiClient.post('/api/update-senti-status', postData)
    .then(response => response.data);
};

function* onSentiChange(payload) {
  try {
    let response = yield call(onSentiChangeRequest, payload);
    yield put(showMailMessage("Filter Applied Successfully."));
  } catch (error) {

  }
}

const onLangChangeRequest = async (res) => {
  let langVal = res.payload.val;
  let oldlangVal = res.payload.old_val;

  let project_id = res.payload.project_id;
  let kw_id = res.payload.kw_id;
  const postData = {lang_val: langVal, old_lang_val: oldlangVal,  post_id: res.payload.id, pid: project_id, kid: kw_id};
return await apiClient.post('/api/update-lang-val', postData)
    .then(response => response.data);
};

function* onLangChange(payload) {
  try {
    let response = yield call(onLangChangeRequest, payload);
    yield put(showMailMessage("Language Updated Successfully."));
  } catch (error) {

  }
}

const onLocChangeRequest = async (res) => {
  let locVal = res.payload.val;
  let oldlocVal = res.payload.old_val;

  let project_id = res.payload.project_id;
  let kw_id = res.payload.kw_id;
  const postData = {loc_val: locVal, old_loc_val: oldlocVal,  post_id: res.payload.id, pid: project_id, kid: kw_id};
return await apiClient.post('/api/update-loc-val', postData)
    .then(response => response.data);
};

function* onLocChange(payload) {
  try {
    let response = yield call(onLocChangeRequest, payload);
    yield put(showMailMessage("Location Updated Successfully."));
  } catch (error) {

  }
}

const onFilterSubmitRequest = async (res) => {
  const postData = {sentiPos: res.payload.sentiPos, sentiNeg: res.payload.sentiNeg, sentiNet: res.payload.sentiNet, sentiUnk: res.payload.sentiUnk, titleList: res.payload.titleList, webList: res.payload.webList, wordList: res.payload.wordList, projectId: res.payload.project_id, kwId: res.payload.kw_id, filterName: res.payload.filterName, langList: res.payload.langList, locList: res.payload.locList, sortList: res.payload.sortList};
  return await apiClient.post('/api/add-filter-save', postData)
    .then(response => response.data);
};

function* onFilterSubmit(payload) {
  try {
    let response = yield call(onFilterSubmitRequest, payload);
    yield put(showMailMessage("Filter Saved Successfully."));
    yield put(filterSubmitSuccess());
  } catch (error) {
    yield put(showMailMessage("Oops! Something Went Wrong"));
    yield put(filterSubmitSuccess());
  }
}

const filterRequest = async (res) => {
  const postData = { projectId: res.payload.project_id, kwId: res.payload.kw_id};
  return await apiClient.post('/api/get-filter-list', postData)
    .then(response => response.data);
};

function* getFilterList(payload) {
  try {
    let response = yield call(filterRequest, payload);
    yield put(getFilterSuccess(response));
  } catch (error) {
    yield put(showMailMessage("Oops! Unable to get Filter List!"));
  }
}

const deleteFilterRequest = async (request) => {
  const postData = { filter_id: request.filter_id };
  return await apiClient.post('/api/delete-filter', postData)
    .then(response => response.data);
}

function* onDeleteFilter(request) {
  try {
    const del_res = yield call(deleteFilterRequest, request.payload);
    if (del_res.error === 1) {
       yield put(showMailMessage(del_res.errMsg));
    }
    else {
      yield put(showMailMessage(del_res.errMsg));
    }
    console.log(del_res);
  } catch (error) {
    yield put(showMailMessage("Oops! Unable to delete that filter!"));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(FETCH_ALL_MAIL, fetchMailRequest),
    takeEvery(ON_START_SELECT, onStartSelect),
    takeEvery(ON_TRASH_CLICK, onTrashClick),
    takeEvery(ON_REPLY_CLICK, onReplyClick),
    takeEvery(ON_SPAM_CLICK, onSpamClick),
    takeEvery(ON_SPAM_CLICK_AUTHOR, onSpamClickAuthor),
    takeEvery(ON_NOTES_CLICK, onNotesClick),
    takeEvery(ON_APPLY_LABEL, onApplyLabel),
    takeEvery(UPDATE_LABEL_INFO, onLabelInfoUpdate),
    takeEvery(ADD_LABEL_INFO, addLabel),
    takeEvery(ADD_LABEL_ALREADY, addLabelAlready),
    takeEvery(DELETE_LABEL, deleteLabel),
    takeEvery(ON_TAG_DELETE, onTagDelete),
    takeEvery(ON_NOTES_SUBMIT, onNotesSubmit),
    takeEvery(ON_APPLY_REPLY, onApplyReply),
    takeEvery(ON_APPLY_DELETE, onApplyDelete),
    takeEvery(ON_APPLY_STARRED, onApplyStarred),
    takeLatest(FOLDER_STAT_REQUEST, getFolderStats),
    takeEvery(ON_READ_CLICK, onReadClick),
    takeEvery(FETCH_PROJECT_TAGS_REQUEST, fetchProjectTagsRequest),
    takeEvery(ON_CHANGE_ADD_LABEL_TITLE, onChangeAddLabelTitle),
    takeEvery(ON_DOWNLOAD_CLICK, fetchMailRequestExport),
    takeLatest(FETCH_CONTINUOUSLY, fetchContinuously),
    takeEvery(REPORT_SENTI_ISSUE, reportSentiIssue),
    takeEvery(ON_SENTI_CHANGE, onSentiChange),
    takeEvery(ON_LANG_CHANGE, onLangChange),
    takeEvery(ON_LOC_CHANGE, onLocChange),
    takeEvery(ON_FILTER_SUBMIT, onFilterSubmit),
    takeEvery(GET_FILTER_LIST, getFilterList),
    takeEvery(ON_DELETE_FILTER, onDeleteFilter)
  ]);
}
