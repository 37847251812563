import {
  FETCH_ALL_PROJECT,
  FETCH_ALL_PROJECT_SUCCESS,
  SHOW_MESSAGE
} from '../constants/ActionTypes';


export const fetchProjects = () => {
  return {
    type: FETCH_ALL_PROJECT
  };
};

export const fetchProjectsSuccess = (projects, users, progress, free_user) => {
  return {
    type: FETCH_ALL_PROJECT_SUCCESS,
    payload: { projects, users, progress, free_user }
  }
};
export const showProjectMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

