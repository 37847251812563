import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import {connect} from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {userSignOut} from 'actions/Auth';

import {WHITE_LABEL_ENABLED} from 'constants/app';
import IntlMessages from 'util/IntlMessages';

class UserInfo extends React.Component {

  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  handleRequestClose = () => {
    this.setState({open: false});
  };

  render() {
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar
          alt='...'
          src={this.props.user.avatar}
          className="user-avatar "
        />
        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>{this.props.user.name} <i
            className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
          </h4>
        </div>
        <Menu className="user-info"
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.handleRequestClose}
              PaperProps={{
                style: {
                  minWidth: 232,
                  paddingTop: 0,
                  paddingBottom: 0
                }
              }}
        >
          {/* <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
            <IntlMessages id="popup.profile"/>
          </MenuItem> */}
          {/* <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
            <IntlMessages id="popup.setting"/>
          </MenuItem> */}
          {/* <MenuItem onClick={() => {
            this.handleRequestClose();
            this.props.userSignOut()
          }}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

            <IntlMessages id="popup.logout"/>
          </MenuItem> */}
          {/*
          <MenuItem>
            <a className="prepend-icon"
              href="/account">
                <i className="zmdi zmdi-account zmdi-hc-fw  mr-2" />
                <span className="nav-text"><IntlMessages id="sidebar.profile"/></span>
            </a>
          </MenuItem>
          {(WHITE_LABEL_ENABLED == undefined || WHITE_LABEL_ENABLED==0) ?
          <MenuItem>
            <a className="prepend-icon"
              href="/team">
                <i className="zmdi zmdi-accounts zmdi-hc-fw  mr-2" />
                <span className="nav-text"><IntlMessages id="sidebar.teams"/></span>
            </a>
          </MenuItem>
          : ''}
          {(WHITE_LABEL_ENABLED == undefined || WHITE_LABEL_ENABLED==0) ?
          <MenuItem>
            <a className="prepend-icon"
              href="/aff">
                <i className="zmdi zmdi-money-box zmdi-hc-fw  mr-2" />
                <span className="nav-text"><IntlMessages id="sidebar.affiliate"/></span>
            </a>
          </MenuItem>
          : ''}  */}
          <a href={"/account"} className="prepend-icon dis-dec-hover" target="_blank" rel="noopener noreferrer">
          <MenuItem>
            <i className="fa fa-cog zmdi-hc-fw mr-2"/>
            <IntlMessages id="popup.accountsettings"/>
          </MenuItem>
          </a>
          {(WHITE_LABEL_ENABLED == undefined || WHITE_LABEL_ENABLED==0) ?
          <a href={"/billing-settings"} className="prepend-icon dis-dec-hover" target="_blank" rel="noopener noreferrer">
          <MenuItem>
            <i className="fa fa-credit-card zmdi-hc-fw mr-2"/>
            <IntlMessages id="popup.billing"/>
          </MenuItem>
          </a>
          : ''}
          {(WHITE_LABEL_ENABLED == undefined || WHITE_LABEL_ENABLED==0) ?
          <a href={"/team"} className="prepend-icon dis-dec-hover" target="_blank" rel="noopener noreferrer">
          <MenuItem>
            <i className="fa fa-users zmdi-hc-fw mr-2"/>
            <IntlMessages id="popup.team"/>
          </MenuItem>
          </a>
          : ''}
          {(WHITE_LABEL_ENABLED == undefined || WHITE_LABEL_ENABLED==0) ?
          <a href={"/faq"} className="prepend-icon dis-dec-hover" target="_blank" rel="noopener noreferrer">
          <MenuItem>
            <i className="fa fa-question-circle zmdi-hc-fw mr-2"/>
            <IntlMessages id="popup.faqs"/>
          </MenuItem>
          </a>
          : ''}
          {(WHITE_LABEL_ENABLED == undefined || WHITE_LABEL_ENABLED==0) ?
          <a className="prepend-icon dis-dec-hover"
              href="https://feedback.websignals.com/" target="_blank" rel="noopener noreferrer">
          <MenuItem>
            <i className="fa fa-bullhorn zmdi-hc-fw mr-2"/>
            <IntlMessages id="popup.roadmap"/>
          </MenuItem>
          </a>
          : ''}
          <a className="prepend-icon dis-dec-hover"
              href="/logout">
          <MenuItem>
            {/* <NavLink className="prepend-icon"
              to="/logout">
                <i className="zmdi zmdi-sign-in zmdi-hc-fw  mr-2"/>
                <span className="nav-text"><IntlMessages id="sidebar.logout"/></span>
            </NavLink> */}
                <i className="fa fa-sign-out-alt zmdi-hc-fw  mr-2"/>
                <span className="nav-text"><IntlMessages id="sidebar.logout"/></span>
          </MenuItem>
          </a>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {locale, user} = settings;
  return {locale, user}
};
export default connect(mapStateToProps, {userSignOut})(UserInfo);


