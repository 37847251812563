import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER
} from 'constants/ActionTypes';
import {switchLanguage, toggleCollapsedNav, sendInvite, showInvite, quotaStatRequestAction, showInviteError, showSupportError, sendSupport, resetSuccess, whiteLabelInfo} from 'actions/Setting';
import IntlMessages from 'util/IntlMessages';
import Menu from 'components/TopNav/Menu';
import Menusupport from '@material-ui/core/Menu';
import UserInfoPopup from 'components/UserInfo/UserInfoPopup';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';


import DialogContentText from '@material-ui/core/DialogContentText';
import classes from 'clsx';
import Paper from '@material-ui/core/Paper';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {WHITE_LABEL_ENABLED} from 'constants/app';
import langText from 'app/routes/mail/data/langText';


function TabContainer(props) {
  return (
    <div style={{padding: '0px 10px'}}>
      {props.children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class Header extends React.Component {

  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification
    })
  };
  onMailNotificationSelect = () => {
    this.setState({
      mailNotification: !this.state.mailNotification
    })
  };
  onLangSwitcherSelect = (event) => {
    this.setState({
      langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
    })
  };
  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox
    })
  };
  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps
    })
  };
  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo
    })
  };
  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false
    });
  };
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };
  Apps = () => {
    return (
      <ul className="jr-list jr-list-half">
        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/calendar/basic">
            <i className="zmdi zmdi-calendar zmdi-hc-fw"/>
            <span className="jr-list-text"><IntlMessages id="sidebar.calendar.basic"/></span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/to-do">
            <i className="zmdi zmdi-check-square zmdi-hc-fw"/>
            <span className="jr-list-text"><IntlMessages id="sidebar.appModule.toDo"/></span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/mail">
            <i className="zmdi zmdi-email zmdi-hc-fw"/>
            <span className="jr-list-text"><IntlMessages id="sidebar.appModule.mail"/></span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/chat">
            <i className="zmdi zmdi-comment zmdi-hc-fw"/>
            <span className="jr-list-text"><IntlMessages id="sidebar.appModule.chat"/></span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/contact">
            <i className="zmdi zmdi-account-box zmdi-hc-fw"/>
            <span className="jr-list-text"><IntlMessages id="sidebar.appModule.contact"/></span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/">
            <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw"/>
            <span className="jr-list-text"><IntlMessages id="mail.header.addnew"/></span>
          </Link>
        </li>
      </ul>)
  };

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      inviteDialogOpen: false,
      inviteEmail: null,
      inviteName: null,
      invitesSent: 0,
      value: 0,
      issueDisc: '',
      open: false,
      images: [],
      imagenames: []
    }
  }

  componentDidMount() {
    if (WHITE_LABEL_ENABLED == '1') {
      this.props.whiteLabelInfo();
    }

  }

  updateSearchText(evt) {
    this.setState({
      searchText: evt.target.value,
    });
  }

  onInviteDialogClose = () => {
    this.setState({inviteDialogOpen: false, inviteName:null, inviteEmail: null, invitesSent: 0});
  }
  handleInviteDialogClose = () => {
    this.setState({inviteDialogOpen: false});
  }

  onChangeInviteEmail = (evt) => {
    this.setState({inviteEmail: evt.target.value});
  }

  onChangeInviteName = (evt) => {
    this.setState({inviteName: evt.target.value});
  }

  listInvitation = () => {
    this.props.showInvite();
  };

  submitInvitation = () => {
    if (this.state.inviteName === null || this.state.inviteEmail === null) {
      this.props.showInviteError();
      return;
    }
    else if (!this.state.inviteEmail.match(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i)) {
      this.props.showInviteError();
      return;
    }
    let email_already = false;
    this.props.invites.codes.map((invite, index) => {
      if (invite !== undefined && invite.invited_email === this.state.inviteEmail) {
        email_already = true;
      }
      return invite;
    });
    if (email_already) {
      this.props.showInviteError();
      return;
    }
    this.props.sendInvite(this.state.inviteName,this.state.inviteEmail);
  };
  onOptionMenuSelect = event => {
    this.props.resetSuccess();
    this.setState({
      open: true,
      anchorEl: event.currentTarget
    })
  };

  handleChange = (event, value) => {
    const temp_val = value;
    value = 0;
    this.setState({value});
    if (temp_val === 1) {
      window.open('https://feedback.websignals.com', '_blank');
    }
    return;
  };

  onChangeIssueDisc = (evt) => {
    this.setState({issueDisc: evt.target.value});
  }

  handleRequestCloseSupport = () => {

    this.setState({open: false, images: [], imagenames: [], issueDisc: ''});
  };

  submitSupportRequest = () => {
    if (this.state.issueDisc === '') {
      this.props.showSupportError();
      return;
    }
    /*
    if (this.state.images.length === 0) {
      this.props.showSupportError();
      return;
    }

    if (this.state.imagenames.length === 0) {
      this.props.showSupportError();
      return;
    }
    */
    this.props.sendSupport(this.state.issueDisc, this.state.images, this.state.imagenames);
    //this.handleRequestCloseSupport();
  };

  handleCapture = ({ target }) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            this.setState((prevState) => ({
              /*eslint no-useless-computed-key: "off"*/
               ['images']: [...prevState['images'], e.target.result], ['imagenames']: [...prevState['imagenames'], target.files[0].name]
            }));
        };
        return;
    };

  removeCapture = (name, image) => {
        let images_state = [...this.state.images];
        let imagesname_state = [...this.state.imagenames];

        var images_index = images_state.indexOf(image);
        var imagesname_index = imagesname_state.indexOf(name);

        if (images_index !== -1) {
          images_state.splice(images_index, 1);
          imagesname_state.splice(imagesname_index, 1);
          this.setState((prevState) => ({
              ['images']: images_state, ['imagenames']: imagesname_state
          }));
          return;
        }
        return;
    };

  render() {
    const {drawerType, navigationStyle, horizontalNavPosition, project} = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';
    const {value} = this.state;

    const getLangChangeText = (option) => {
      switch (option) {
        case 'issueDesc':
          return langText[this.props.locale.locale]['issueDesc'];
        default:
          return -1;
      }
    };


    return (
      <AppBar
        className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
        <Toolbar className="app-toolbar" disableGutters={false}>
          {navigationStyle === HORIZONTAL_NAVIGATION ?
            <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
                            <span className="jr-menu-icon">
                              <span className="menu-icon"/>
                            </span>
            </div>
            :
            <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
                        onClick={this.onToggleCollapsedNav}>
              <span className="menu-icon"/>
            </IconButton>
          }
          {(WHITE_LABEL_ENABLED === '0' || WHITE_LABEL_ENABLED == undefined) ? <a className="app-logo mr-2" href="http://www.websignals.com">
          <img src={require("assets/images/logos/logos-combo/websignal-dark-orange-medium.png")} alt={<IntlMessages id="mail.header.websignal"/>} title={<IntlMessages id="mail.header.websignals"/>}/>
          </a>: (this.props.wl_info !== undefined && this.props.wl_info.title !== undefined) ? <a className="app-logo mr-2" href={this.props.wl_info.fqdn}>
          <img src={this.props.wl_info.logo_large} alt={this.props.wl_info.title} title={this.props.wl_info.title}/></a>: ''}

          {/* <SearchBox styleName="d-none d-lg-block" placeholder=""
                     onChange={this.updateSearchText.bind(this)}
                     value={this.state.searchText}/> */}
          {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) &&
          <Menu/>}
          <div className="ml-auto">

          <ul className="header-notifications list-inline float-right mt-2-xl">
            <li className="list-inline-item">
              <Button className="jr-btn bg-warning text-white" style={{display: 'none'}}
              onClick={() => { this.setState({inviteDialogOpen: true}); this.listInvitation(); }}><IntlMessages id="mail.header.sendinvite"/></Button>

              <Dialog open={this.state.inviteDialogOpen}
                      className={'sendInviteCode invite-popup'} onClose={this.onInviteDialogClose} fullWidth>
                  <DialogTitle>
                    {"Send Invitations"}
                  </DialogTitle>

                  <DialogContent className="inviteContent">

                    {this.props.inviteError ? <div className="alert alert-danger text-center">Please enter valid name/email</div> : ''}
                    {(this.props.inviteResponse.error !== undefined && this.props.inviteResponse.error > 0) ?
                      <div className="alert alert-danger text-center">{this.props.inviteResponse.errMsg}</div>
                    : (this.props.inviteResponse.error === 0) ? <div className="alert alert-success text-center">Invite Sent Successfully!</div>:''
                    }
                    {this.props.inviteDialogLoading ? <div style={{minHeight: '188px'}} className="col-md-12 text-center"><CircularProgress /> </div>
                    :  <div> {(this.props.invites.codes !== undefined  && this.props.invites.codes.length < this.props.invites.limit) ?
                      <div>
                      {!this.props.inviteLoading ?
                          <div className="inviteForm">
                            <form className={classes.container} noValidate autoComplete="off">
                              <TextField
                                label="Name"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                onChange={this.onChangeInviteName}
                              />
                              <TextField
                                label="Email"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                onChange={this.onChangeInviteEmail}
                              />
                              <Button onClick={this.submitInvitation} className="bg-warning text-white">Send Invite</Button>
                            </form>
                          </div>
                         : <div></div> }
                     </div>: <div> {!this.props.inviteLoading ? <div className="alert alert-danger text-center">Invites Limit Reached. you can not send more invites.</div>: <div></div>}</div>}</div>}

                    {!this.props.inviteLoading ?
                              <Paper className={classes.root}>
                                <div className={classes.tableWrapper}>
                                  <h4 className="table-heading">Invited People</h4>



                                    { this.props.invites.codes !== undefined && this.props.invites.codes.length > 0 ?
                                    <Table className="stickyHeader">
                                      <TableHead>
                                        <TableRow>
                                            <TableCell className="d-none d-sm-table-cell">
                                              {"Name"}
                                            </TableCell>
                                            <TableCell>
                                              {"Email"}
                                            </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                      {this.props.invites.codes.map((invite, index) => {
                                        if (invite !== undefined) {
                                          return <TableRow>
                                          <TableCell className="d-none d-sm-table-cell text-capitalize">
                                              {invite.invited_name}
                                          </TableCell>
                                          <TableCell>
                                              {invite.invited_email}
                                          </TableCell>
                                          </TableRow>
                                        } else {
                                          return ''
                                        }
                                        })
                                      }
                                    </TableBody> </Table> : '' }

                                  <p className="page">
                                    { (this.props.invites.codes !== undefined) ?
                                    <span>{this.props.invites.codes.length}</span>
                                    : <span>0</span> }
                                    <span>{(this.props.invites.codes !== undefined && this.props.invites.codes.length === 1) ? " Invite " : " Invites " } {"sent out of "}</span>
                                    { (this.props.invites.limit !== undefined) ?
                                    <span>{this.props.invites.limit}</span>
                                    : <span></span> }
                                  </p>
                                </div>
                              </Paper>
                            : <div className="col-md-12 text-center" style={{minHeight: '188px'}}><CircularProgress /> </div>}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleInviteDialogClose} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
            </li>

            {navigationStyle === HORIZONTAL_NAVIGATION &&
            <li className="list-inline-item user-nav">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.userInfo}
                toggle={this.onUserInfoSelect.bind(this)}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn size-30">
                    <Avatar
                      alt='...'
                      src={'https://via.placeholder.com/150x150'}
                      className="size-30"
                    />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <UserInfoPopup/>
                </DropdownMenu>
              </Dropdown>
            </li>}
          </ul>

          {(this.props.project.free_user !== undefined && this.props.project.free_user === 1 && (WHITE_LABEL_ENABLED == undefined || WHITE_LABEL_ENABLED === '0')) ?
            <div
            className="float-left mr-4">
          <a href={"/pricing"} className="anchor-no-css" rel="noopener noreferrer">
          <Button className="bg-primary bg-head-upgrade btn-block text-center mt-1" style={{}} variant="contained" size="small"><IntlMessages id="sidebar.upgrade"/></Button>
          </a>
          </div>
          : '' }

          {(WHITE_LABEL_ENABLED == undefined || WHITE_LABEL_ENABLED === '0') ?

          <div
            className="float-left list-inline mt-support-2 mr-2"
            onClick={this.onOptionMenuSelect.bind(this)}
            aria-controls="option-menu"
            aria-haspopup="true"
            variant="contained"
            style={{cursor:'pointer'}}>
            <img src={require("assets/images/dashboard/support-icon.png")} alt="Support" />
            <span style={{color: '#949CA3'}} className="d-none d-sm-none d-xs-none d-md-inline-block d-lg-inline-block d-xl-inline-block support-theming">&nbsp;<IntlMessages id="mail.header.support"/></span>
            <IconButton className="icon-btn-sm d-none d-sm-none d-xs-none d-md-inline-block d-lg-inline-block d-xl-inline-block" style={{padding: '0px'}}>
              <i className="zmdi zmdi-caret-down"/>
            </IconButton>
          </div> : ''}
          <Menusupport id="option-menu"
              anchorEl={this.state.anchorEl}
              getContentAnchorEl={null}
              open={this.state.open}
              keepMounted
              onClose={this.handleRequestCloseSupport}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              MenuListProps={{
                style: {
                  width: 380,
                }
              }}
              >
              <div position="static" color="default" >
              <Tabs value={value} onChange={this.handleChange} className="pl-2 pr-2">

                <Tab className={`tab ${(value === 0) ? 'tab-highlight' : 'tab-disable'}`}  label={<div><img alt={<IntlMessages id="mail.header.reportproblem"/>} src={(value === 0) ? require("assets/images/dashboard/support-warn-color.png") : require("assets/images/dashboard/support-warn-gray.png")} /> <IntlMessages id="mail.header.reportissue"/></div>} />
                <Tab className={`tab ${(value === 1) ? 'tab-highlight' : 'tab-disable'}`} label={<div><img alt={<IntlMessages id="mail.header.raisefeature"/>} src={(value === 1) ? require("assets/images/dashboard/support-request-color.png") : require("assets/images/dashboard/support-request-gray.png")} /> <IntlMessages id="mail.header.featurereq"/></div>} />
              </Tabs>
              </div>
              {value === 0 &&
              <TabContainer>
                {this.props.supportSuccessMessage === '' ?
                <div>

                  <div className="inviteForm">
                    <form className={classes.container} noValidate autoComplete="off">
                      <div className="mb-2" style={{minHeight: '250px', backgroundColor: '#f8f8f8', padding: '20px 10px'}}>
                      <p className="m-0" style={{fontSize:'13px', fontWeight:'bold'}}><IntlMessages id="mail.header.reportcontact"/></p>
                       {this.props.supportError ? <div className="text-left text-danger" style={{fontSize:'13px'}}><IntlMessages id="mail.header.reportenterdesc"/></div> : ''}
                      <TextField className="mt-2 mb-2" style={{ backgroundColor: '#fff' }}
                        id="multiline-static"
                        multiline
                        rows="4"
                        fullWidth
                        border="0"
                        placeholder={getLangChangeText('issueDesc')}
                        variant="outlined"
                        onChange={this.onChangeIssueDisc}
                        value={this.state.issueDisc}
                        ref={node => {this.TextField = node;}}
                      />
                      <input
                          accept="image/*"
                          className={classes.input}
                          id="icon-button-photo"
                          onChange={this.handleCapture}
                          type="file"
                          style={{ display: "none" }}
                      />
                      <div className="p-2" style={{backgroundColor: '#fff', display:'inline-table'}}>
                      { this.state.imagenames !== undefined && this.state.imagenames.length > 0 ?
                      <div style={{width: '200px', float: 'left', backgroundColor: '#fff'}}>
                      {this.state.imagenames.map((name, index) => {
                        console.log(index);
                        return <div>{name}&nbsp;<i className="zmdi zmdi-close-circle" data-name={name} data-val={this.state.images[index]} onClick={() => this.removeCapture(name, this.state.images[index])}></i></div>
                        })
                      }
                      </div>
                      : <div className="pl-2 pt-2" style={{width: '200px', float: 'left', backgroundColor: '#fff'}}><IntlMessages id="mail.header.uploadattach"/></div>}
                      <label htmlFor="icon-button-photo" >
                          <Button color="default" style={{ backgroundColor: '#f8f8f8', width: '120px'}} component="span">
                            { this.state.imagenames !== undefined && this.state.imagenames.length > 0 ? <IntlMessages id="mail.header.uploadattach"/> : <IntlMessages id="mail.header.upload"/> }
                        </Button>
                      </label>
                      </div>
                      </div>
                      {this.props.supportDialogLoading === false ? <Button onClick={this.submitSupportRequest} className="bg-warning text-white"><IntlMessages id="mail.header.submit"/></Button> : <Button className="bg-warning disabled text-white" aria-disabled="true"><IntlMessages id="mail.header.pleasewait"/>...</Button>}
                      <Button onClick={this.handleRequestCloseSupport} style={{ backgroundColor: '#f8f8f8'}} className="bg-default ml-2"><IntlMessages id="mail.header.close"/></Button>

                    </form>
                  </div>

                </div>
                : <div className="text-primary p-4 text-center" style={{backgroundColor: '#f8f8f8', fontSize: '1.2em'}}>{this.props.supportSuccessMessage}</div> }
              </TabContainer>}
              {value === 1 &&
              <TabContainer>
              <div style={{minHeight: '400px'}}></div>
              </TabContainer>}
          </Menusupport>
          </div>
          <div className="ellipse-shape"></div>


        </Toolbar>
      </AppBar>
    );
  }

}


const mapStateToProps = ({project, settings}) => {
  const {drawerType, locale, navigationStyle, horizontalNavPosition, inviteDialogLoading, inviteResponse, inviteLoading, invites, inviteError, optionMenuState, supportError, supportDialogLoading, supportSuccessMessage, wl_info} = settings;
  return {drawerType, locale, navigationStyle, horizontalNavPosition, inviteDialogLoading, inviteResponse, inviteLoading, invites, inviteError, optionMenuState, supportError, supportDialogLoading, supportSuccessMessage, wl_info, project}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, switchLanguage, sendInvite, showInvite, quotaStatRequestAction, showInviteError, showSupportError, sendSupport, resetSuccess, whiteLabelInfo})(Header));
