// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';

// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const


export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const ON_BLOCK_MAIL = 'on_block_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';
export const ON_REPLY_MENU_ITEM_SELECT = 'on_reply_menu_item_select';
export const ON_DELETE_MENU_ITEM_SELECT = 'on_delete_menu_item_select';
export const ON_APPLY_REPLY = 'on_apply_reply';
export const ON_APPLY_DELETE = 'on_apply_delete';
export const ON_APPLY_STARRED = 'on_apply_starred';


//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

export const REQUEST_ALL_PROJECT = "request_all_project";
export const FETCH_ALL_PROJECT = "fetch_all_project";
export const FETCH_ALL_PROJECT_SUCCESS = "fetch_all_project_success";
export const ON_TRASH_CLICK = 'on_trash_click';
export const ON_REPLY_CLICK = 'on_reply_click';
export const ON_SPAM_CLICK = 'on_spam_click';
export const ON_NOTES_CLICK = 'on_notes_click';
export const FETCH_NOTES_SUCCESS = 'fetch_notes_success';
export const ON_NOTES_SUBMIT = 'on_notes_submit';
export const ACTIVE_SRC = 'active_src';
export const ON_DATE_MENU_SELECT = 'on_date_menu_select';
export const ON_DATE_RANGE_CHANGE = 'on_date_range_change';

export const FETCH_USER_REQUEST = 'fetch_user_request';
export const FETCH_USER_SUCCESS = 'fetch_user_success';

export const FETCH_PROJECT_TAGS_REQUEST = 'fetch_project_tags_request';
export const FETCH_PROJECT_TAGS_SUCCESS = 'fetch_project_tags_success';

export const ON_APPLY_LABEL = 'on_apply_label';
export const UPDATE_LABEL_INFO = 'update_label_info';

export const ADD_LABEL_INFO = 'add_label_info';
export const DELETE_LABEL = 'delete_label';
export const ADD_LABEL_INFO_SUCCESS = 'add_label_info_success';
export const DELETE_LABEL_SUCCESS = 'delete_label_success';
export const LABEL_ACTION_PRELOADER_SHOW = 'label_action_preloader_show';

export const SEND_INVITE_REQUEST = 'send_invite_request';
export const SEND_INVITE_REQUEST_SUCCESS = 'send_invite_request_success';
export const ON_SPAM_CLICK_AUTHOR = 'on_spam_click_author';

export const FOLDER_STAT_REQUEST = 'folder_stat_request';
export const FOLDER_STAT_SUCCESS = 'folder_stat_success';
export const ON_READ_CLICK = 'on_read_click';

export const SHOW_INVITE_SUCCESS = 'show_invite_success';
export const SHOW_INVITE_REQUEST = 'show_invite_request';

export const ON_NEW_KEYWORD_LOAD = 'on_new_keyword_load';

export const FETCH_CONTINUOUSLY = 'fetch_continously';
export const ON_FILTER_TOGGLE_DRAWER = 'on_filter_toggle_drawer';
export const ON_USER_FETCHED_SUCCESS = "on_user_fetched_success";

export const USER_QUOTA_REQUEST = 'user_quota_request';
export const QUOTA_STAT_SUCCESS = 'quota_stat_success';
export const SHOW_NEW_POSTS = 'show_new_posts';

export const SHOW_INVITE_ERROR = 'show_invite_error';


export const ON_TAG_DELETE = 'on_tag_delete';

export const ADD_LABEL_ALREADY = 'add_label_already';
export const ADD_LABEL_ALREADY_SUCCESS = 'add_label_already_success';

export const ON_CHANGE_ADD_LABEL_TITLE = 'on_change_add_label_title';

export const TAG_FILTER_SELECTED = 'tag_filter_selected';

export const ON_DOWNLOAD_CLICK = 'on_download_click';

export const ON_DOWNLOAD_SUCCESS = 'on_download_success';


// Actions for Subscription plans loading
export const FETCH_PLANS = 'fetch_plans_request';
export const FETCH_PLANS_SUCCESS = 'fetch_plans_success';
export const FETCH_PLANS_FAILED = 'fetch_plans_failed';
export const UPDATE_SUBSCRIPTION = 'update_subscription';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'update_subscription_success';
export const UPDATE_SUBSCRIPTION_FAILED = 'update_subscription_failed';
export const DETAIL_LOADER_HANDLER = 'detail_loader_handler';

export const SHOW_SUPPORT_ERROR = 'show_support_error';
export const SEND_SUPPORT_REQUEST = 'send_support_request';
export const SEND_SUPPORT_REQUEST_SUCCESS = 'send_support_request_success';
export const RESET_SUCCESS = 'reset_success';

export const ON_MOUSE_OUT = 'ON_MOUSE_OUT';
export const ON_MOUSE_OVER = 'on_mouse_over';
export const CALL_LAZY_LOAD = 'call_lazy_load';


// Analytics Constants
export const ANALYTICS_LOADING = 'analytics_module_loading';
export const ANALYTICS_REQUEST_PROJECT_GRAPH_DIST = 'ANALYTICS_REQUEST_PROJECT_GRAPH_DIST';
export const ANALYTICS_PROJECT_REQUEST_GRAPH_DIST_SUCCESS = 'analytics_request_project_graph_dist_success';
export const ANALYTICS_PROJECT_REQUEST_GRAPH_DIST_FAILED = 'analytics_request_project_graph_dist_failed';

export const ANALYTICS_REQUEST_PROJECT_LANG_DIST = 'analytics_request_project_lang_dist';
export const ANALYTICS_PROJECT_REQUEST_LANG_DIST_SUCCESS = 'analytics_request_project_lang_dist_success';
export const ANALYTICS_PROJECT_REQUEST_LANG_DIST_FAILED = 'analytics_request_project_lang_dist_failed';

export const ANALYTICS_REQUEST_PROJECT_WORD_COUNT = 'analytics_request_project_word_count';
export const ANALYTICS_PROJECT_REQUEST_WORD_COUNT_SUCCESS = 'analytics_request_project_word_count_success';
export const ANALYTICS_PROJECT_REQUEST_WORD_COUNT_FAILED = 'analytics_request_project_word_count_failed';

export const ANALYTICS_REQUEST_PROJECT_SUMMARY = 'analytics_request_project_summary';
export const ANALYTICS_PROJECT_REQUEST_SUMMARY_SUCCESS = 'analytics_request_project_summary_success';
export const ANALYTICS_PROJECT_REQUEST_SUMMARY_FAILED = 'analytics_request_project_summary_failed';

export const ANALYTICS_REQUEST_PROJECT_AUTHOR = 'analytics_request_project_author';
export const ANALYTICS_PROJECT_REQUEST_AUTHOR_SUCCESS = 'analytics_request_project_author_success';
export const ANALYTICS_PROJECT_REQUEST_AUTHOR_FAILED = 'analytics_request_project_author_failed';

export const CHANGE_ACTIVE_SOURCE = 'change_active_source';
export const ANALYTICS_CHANGE_DATE_RANGE = 'analytics_date_range_change';
export const ANALYTICS_ON_CHANGE_AUTHOR_SORT = 'analytics_on_change_author_sort';

export const ANALYTICS_REQUEST_TWITTER_AUTHOR = 'analytics_request_twitter_authors';
export const ON_SUCCESS_ANALYTICS_REQUEST_TWITTER_AUTHOR = 'on_success_analytics_request_twitter_authors';

export const ANALYTICS_REQUEST_REDDIT_AUTHOR = 'analytics_request_reddit_author';
export const ON_SUCCESS_ANALYTICS_REQUEST_REDDIT_AUTHOR = 'on_success_analytics_request_reddit_author';

export const ANALYTICS_REQUEST_INSTA_AUTHOR = 'analytics_request_insta_author';
export const ON_SUCCESS_ANALYTICS_REQUEST_INSTA_AUTHOR = 'on_success_analytics_request_insta_author';

export const ANALYTICS_REQUEST_WEB_AUTHOR = 'analytics_request_web_author';
export const ON_SUCCESS_ANALYTICS_REQUEST_WEB_AUTHOR = 'on_success_analytics_request_web_author';

export const ANALYTICS_REQUEST_HUMAN_AUTHOR = 'analytics_request_human_author';
export const ON_SUCCESS_ANALYTICS_REQUEST_HUMAN_AUTHOR = 'on_success_analytics_request_human_author';

export const ANALYTICS_REQUEST_YOUTUBE_AUTHOR = 'analytics_request_youtube_author';
export const ON_SUCCESS_ANALYTICS_REQUEST_YOUTUBE_AUTHOR = 'on_success_analytics_request_youtube_author';

export const ANALYTICS_ON_CHANGE_KEYWORD_SELECT = 'analytics_on_change_keyword_select';

export const GET_KEYWORD_NAMES = 'get_keyword_names';
export const GET_KEYWORD_NAMES_ON_SUCCESS = 'get_keyword_names_on_success';
export const GET_KEYWORD_NAMES_ON_FAILURE = 'get_keyword_names_on_failure';

export const SENTI_ON_CHANGE_IMPACT = 'senti_on_change_impact';
export const REPORT_SENTI_ISSUE = 'report_senti_issue';

export const VIEW_POST_BY_AUTHOR = 'view_post_author';
export const ON_SUCCESS_POST_BY_AUTHOR = 'on_success_post_by_author';
export const CLOSE_VIEW_POST_BY_AUTHOR = 'on_close_post_by_author';

export const WHITE_LABEL_INFO = 'white_label_info';
export const WHITE_LABEL_INFO_SUCCESS = 'white_label_info_success';

export const ON_SENTI_CHANGE = 'on_senti_change';

export const ON_TITLE_SUBMIT = 'on_title_submit';
export const ON_WORD_SUBMIT = 'on_word_submit';
export const ON_WEB_SUBMIT = 'on_web_submit';

export const ON_TITLE_DELETE = 'on_title_delete';
export const ON_WORD_DELETE = 'on_word_delete';
export const ON_WEB_DELETE = 'on_web_delete';
export const RESET_FILTER = 'reset_filter';
export const ON_FILTER_SUBMIT = 'on_filter_submit';
export const ON_FILTER_SUBMIT_SUCCESS = 'on_filter_submit_success';
export const ON_FILTER_SELECT = 'on_filter_select';
export const GET_FILTER_LIST = 'get_filter_list';
export const GET_FILTER_SUCCESS = 'get_filter_success';
export const ON_DELETE_FILTER = 'on_delete_filter';
export const APPLY_SAVED_FILTER = 'apply_saved_filter';
export const ON_LANG_SUBMIT = 'on_lang_submit';
export const ON_LANG_SUBMIT_ANALYTICS = 'on_lang_submit_analytics';
export const ON_LANG_DELETE = 'ON_LANG_DELETE';
export const ON_LANG_CHANGE = 'on_lang_change';

export const ON_LOC_SUBMIT = 'on_loc_submit';
export const ON_LOC_SUBMIT_ANALYTICS = 'on_loc_submit_analytics';
export const ON_LOC_DELETE = 'on_loc_delete';
export const ON_LOC_CHANGE = 'on_loc_change';

export const CHANGE_DATE_RANGE_CALENDAR = 'change_date_range_calendar';
//export const SIDE_BAR_STATE = 'side_bar_state';

export const ON_SORT_SUBMIT = 'on_sort_submit';
export const ON_SORT_DELETE = 'on_sort_delete';
export const ON_SORT_CHANGE = 'on_sort_change';
export const SAVE_LAST_SELECTED_DATE = 'save_last_selected_date';
export const RESELECT_LAST_SELECTED_DATE = 'reselect_last_selected_date';
export const GET_LANG = 'get_lang';

export const ANALYTICS_REQUEST_SUMMARY_GRAPH = 'analytics_request_summary_graph';
export const ANALYTICS_REQUEST_SUMMARY_GRAPH_SUCCESS = 'analytics_request_summary_graph_success';
export const ANALYTICS_REQUEST_SUMMARY_GRAPH_FAILED = 'analytics_request_summary_graph_failed';


export const ANALYTICS_REQUEST_LANGUAGE_GRAPH = 'analytics_request_language_graph';
export const ANALYTICS_REQUEST_LANGUAGE_GRAPH_SUCCESS = 'analytics_request_language_graph_success';
export const ANALYTICS_REQUEST_LANGUAGE_GRAPH_FAILED = 'analytics_request_language_graph_failed';

export const ANALYTICS_REQUEST_LOCATION_GRAPH = 'analytics_request_location_graph';
export const ANALYTICS_REQUEST_LOCATION_GRAPH_SUCCESS = 'analytics_request_location_graph_success';
export const ANALYTICS_REQUEST_LOCATION_GRAPH_FAILED = 'analytics_request_location_graph_failed';

export const ANALYTICS_REQUEST_WORD_COUNT = 'analytics_request_word_count';
export const ANALYTICS_REQUEST_WORD_COUNT_SUCCESS = 'analytics_request_word_count_success';
export const ANALYTICS_REQUEST_WORD_COUNT_FAILED = 'analytics_request_word_count_failed';

export const ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA = 'analytics_request_twitter_author_data_graph';
export const ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA_SUCCESS = 'analytics_request_twitter_author_data_success';
export const ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA_FAILED = 'analytics_request_twitter_author_data_failed';

export const ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA = 'analytics_request_youtube_author_data_graph';
export const ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA_SUCCESS = 'analytics_request_youtube_author_data_success';
export const ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA_FAILED = 'analytics_request_youtube_author_data_failed';

export const ANALYTICS_REQUEST_INSTA_AUTHOR_DATA = 'analytics_request_insta_author_data_graph';
export const ANALYTICS_REQUEST_INSTA_AUTHOR_DATA_SUCCESS = 'analytics_request_insta_author_data_success';
export const ANALYTICS_REQUEST_INSTA_AUTHOR_DATA_FAILED = 'analytics_request_insta_author_data_failed';



export const ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA = 'analytics_request_reddit_author_data_graph';
export const ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA_SUCCESS = 'analytics_request_reddit_author_data_success';
export const ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA_FAILED = 'analytics_request_reddit_author_data_failed';


export const ANALYTICS_REQUEST_WEB_AUTHOR_DATA = 'analytics_request_web_author_data_graph';
export const ANALYTICS_REQUEST_WEB_AUTHOR_DATA_SUCCESS = 'analytics_request_web_author_data_success';
export const ANALYTICS_REQUEST_WEB_AUTHOR_DATA_FAILED = 'analytics_request_web_author_data_failed';

export const ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA = 'analytics_request_human_author_data_graph';
export const ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA_SUCCESS = 'analytics_request_human_author_data_success';
export const ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA_FAILED = 'analytics_request_human_author_data_failed';

export const ANALYTICS_USER_PROJECT_KW_LIST = 'analytics_user_project_kw_list';
export const ANALYTICS_USER_PROJECT_KW_LIST_SUCCESS = 'analytics_user_project_kw_list_success';
export const ANALYTICS_USER_PROJECT_KW_LIST_FAILED = 'analytics_user_project_kw_list_failed';

export const ANALYTICS_ON_CHANGE_KEYWORD_SELECT_NEW = 'analytics_on_change_keyword_select_new';
export const ANALYTICS_CHANGE_DATE_RANGE_FILTER = 'analytics_date_range_change_filter';

export const MULTI_CHANGE_IMPACT = 'multi_change_impact';

export const ACTIVE_SRC_NEW = 'active_src_new';
export const VIEW_POST_BY_AUTHOR_DATA = 'view_post_author_data';
export const ON_SUCCESS_POST_BY_AUTHOR_DATA = 'on_success_post_by_author_data';
export const CLOSE_VIEW_POST_BY_AUTHOR_DATA = 'on_close_post_by_author_data';
export const ON_PRE_LANG_SUBMIT = 'on_pre_lang_submit';
export const ADD_LANG_FILTER = 'add_lang_filter';
export const ON_PRE_LOC_SUBMIT = 'on_pre_loc_submit';
export const ADD_LOC_FILTER = 'add_loc_filter';
export const ON_EDIT_FILTER_SUBMIT = 'on_edit_filter_submit';
export const ON_EDIT_FILTER_SUBMIT_SUCCESS = 'on_edit_filter_submit_success';
export const RESET_SELECT_FILTER= 'reset_select_filter';

export const DEL_LOC_FILTER = 'del_loc_filter';
export const DEL_LANG_FILTER = 'del_lang_filter';
export const GET_FILTER_LIST_ANALYTICS = 'get_filter_list_analytics';
export const GET_FILTER_ANALYTICS_SUCCESS = 'get_filter_analytics_success';
export const ON_DELETE_FILTER_ANALYTICS = 'on_delete_filter_analytics';
export const ON_FILTER_SUBMIT_ANALYTICS = 'on_filter_submit_analytics';
export const ON_FILTER_SUBMIT_ANALYTICS_SUCCESS = 'on_filter_submit_analytics_success';
export const LOADING_ENABLE = 'LOADING_ENABLE';

export const ANALYTICS_REQUEST_SUMMARY_TOP = 'analytics_request_summary_top';
export const ANALYTICS_REQUEST_SUMMARY_TOP_SUCCESS = 'analytics_request_summary_top_success';
export const ANALYTICS_REQUEST_SUMMARY_TOP_FAILED = 'analytics_request_summary_top_failed';
export const CHANGE_ANALYSIS_GRAPH = 'change_analysis_graph';
export const CHANGE_COMPETITIVE_GRAPH = 'change_competitive_graph';
export const SUMMARY_LOAD_ENABLE = 'summary_load_enable';
export const FOCUS_INPUT = 'focus_input';
export const ADD_KW_LIST = 'add_kw_list';
export const ON_SRC_CHANGE = 'on_src_change';
export const ON_KW_SRC_CHANGE = 'on_kw_src_change';
export const ON_KW_CHANGE = 'on_kw_change';
export const ON_FILL_CHANGE = 'on_fill_change';
export const ON_DELETE_KW = 'on_delete_kw';

export const INFLUENCER_REQUEST_DATA = 'influencer_request_data';
export const INFLUENCER_REQUEST_DATA_SUCCESS = 'influencer_request_data_success';
export const INFLUENCER_REQUEST_DATA_FAILED = 'influencer_request_data_failed';
export const ON_RESET_LIST = 'on_reset_list';
export const ON_FILTER_ADD = 'on_filter_add';
export const ON_FILTER_DEL = 'on_filter_del';
export const ON_FILTER_DEL_GRP = 'on_filter_del_grp';
export const ON_FILTER_DEL_COMP = 'on_filter_del_comp';

export const ON_FILTER_FILL_CHANGE = 'on_filter_fill_change';
export const ON_FILTER_RESET = 'on_filter_reset';
export const ON_ADD_KW_FILL_LIST = 'on_add_kw_fill_list';
export const ON_HANDLE_ADD_KW_CHANGE = 'on_handle_add_kw_change';
export const ON_HANDLE_ADD_KW_CHANGE_FILL = 'on_handle_add_kw_change_fill';
export const COLOR_CODES = 'color_codes';
export const MORE_COND_CLOSE = 'more_cond_close';
export const SEL_ALERT = 'sel_alert';
export const FILTER_EDIT_DATA = 'filter_edit_data';
export const FILTER_UPDATE = 'filter_update';
export const SET_KW_LIST = 'set_kw_list';
export const EDIT_SRC_FILTER = 'edit_src_filter';
export const ON_QUOTA_REQUST = 'on_quota_requst';
export const ON_QUOTA_REQUST_SUCCESS = 'on_quota_requst_success';
export const CLOSE_QUOTA = 'close_quota';
export const SHOW_QUOTA = 'show_quota';
export const SAVED_URL_REQUEST = 'saved_url_request';
export const SAVED_URL_REQUEST_SUCCESS = 'saved_url_request_success';
export const ON_SORT = 'on_sort';
export const RESET_KW_STATE = 'reset_kw_state';
export const SET_SEARCH_BOOL = 'set_search_bool';


