import {
  FETCH_ALL_PROJECT,
  FETCH_ALL_PROJECT_SUCCESS,
  SHOW_MESSAGE
} from 'constants/ActionTypes';

const INIT_STATE = {
  projects : [],
  users: [],
  progress: [],
  free_user: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_PROJECT: {
      return {
        ...state,
        projects: [],
        users: [],
        progress: [],
        free_user: 0,
        projectLoading:true
      }
    }
    case FETCH_ALL_PROJECT_SUCCESS: {
      return {
        ...state,
        projects: action.payload.projects,
        users: action.payload.users,
        progress: action.payload.progress,
        free_user: action.payload.free_user,
        projectLoading:false
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        projectLoading:false,
        loader: false
      }
    }
    default:
      return state;
  }
}
