import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from 'components/Header/index';
import WSSlideAlert from 'components/WSSlideAlert/index';
import Sidebar from 'containers/SideNav/index';
// import Footer from 'components/Footer';
// import Dashboard from './routes/dashboard';
// import Widgets from './routes/widgets'
// import Metrics from './routes/metrics'
// import Components from './routes/components';
// import Icons from './routes/icons/index';
// import Form from './routes/form';
// import Editors from './routes/editors';
// import Pickers from './routes/pickers';
// import Extensions from './routes/extensions';
// import Table from './routes/table';
// import Chart from './routes/charts';
// import Map from './routes/map';
// import Calendar from './routes/calendar';
// import TimeLine from './routes/timeLine';
// import CustomViews from './routes/customViews';
// import ExtraElements from './routes/extraElements'
// import eCommerce from './routes/eCommerce'
// import AppModule from './routes/appModule'
// import ExtraPages from './routes/extraPages';
// import Tour from '../components/Tour/index';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import ColorOption from 'containers/Customizer/ColorOption';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
//import PromotionalBanners from '../app/routes/analytics/PromotionalBanners';
import InfluencerBanner from 'components/InfluencerBanner';

class App extends React.Component {

  render() {
    const {match, drawerType, navigationStyle, horizontalNavPosition} = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';
    const pathName = this.props.location.pathname;

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar/>
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
            <TopNav styleName="app-top-header"/>}
            <Header/>

            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
            <TopNav/>}

          </div>
          { !pathName.includes('influencer-research') &&
          <WSSlideAlert />
          }
          { /*!pathName.includes('analytics') &&
            <PromotionalBanners></PromotionalBanners>*/
          }
          {/* { !pathName.includes('influencer-research') &&
            <InfluencerBanner></InfluencerBanner>
          } */}
          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/project/:project_id/keyword/:kw_id`}
                       component={asyncComponent(() => import('./routes/mail/redux/index'))}/>


                <Route path={`${match.url}/analytics/:kw_id?/:kw_id1?/:kw_id2?/:kw_id3?/`}
                        component={asyncComponent(() => import('./routes/analytics/index-new'))} />
                <Route path={`${match.url}/analytics/project/:project_id/(keyword)?/:kw_id?`}
                        component={asyncComponent(() => import('./routes/analytics/index'))} />

                <Route path={`${match.url}/influencer-research(/result)?/`}
                        component={asyncComponent(() => import('./routes/InfluencerResearch/index'))} />

                <Route path={`${match.url}/user`}
                       component={asyncComponent(() => import('./routes/user/redux/index'))}/>
                <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
              </Switch>
            </div>
          </main>
        </div>
        <ColorOption/>
      </div>
    );
  }
}


const mapStateToProps = ({settings}) => {
  const {drawerType, navigationStyle, horizontalNavPosition, sidebar} = settings;
  return {drawerType, navigationStyle, horizontalNavPosition, sidebar}
};
export default withRouter(connect(mapStateToProps)(App));
