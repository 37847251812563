
import {all, call, put, takeEvery, takeLatest, delay, select} from 'redux-saga/effects';

import {
  onSuccessProjectAnalyticsGraphDistRequest,
  onSuccessProjectAnalyticsLangDistRequest,
  onSuccessProjectAnalyticsWordCountRequest,
  onSuccessProjectAnalyticsSummaryRequest,
  onSuccessProjectAnalyticsAuthorRequest,
  onSuccessTwitterAuthorRequest,
  onSuccessRedditAuthorRequest,
  onSuccessInstaAuthorRequest,
  onSuccessWebAuthorRequest,
  onSuccessHumanAuthorRequest,
  onSuccessYoutubeAuthorRequest,
  onSuccessPostByAuthor,
  onSuccessPostByAuthorData,
  getKeywordNamesOnSuccess,


  onSuccessAnalyticsSummaryGraphsRequest,
  onSuccessAnalyticsSummaryTopDataRequest,
  onSuccessAnalyticsLanguageGraphsRequest,
  onSuccessAnalyticsLocationGraphsRequest,
  onSuccessAnalyticsWordCountRequest,
  onSuccessAnalyticsTwitterAuthorDataRequest,
  onSuccessAnalyticsYoutubeAuthorDataRequest,
  onSuccessAnalyticsInstaAuthorDataRequest,
  onSuccessAnalyticsRedditAuthorDataRequest,
  onSuccessAnalyticsWebAuthorDataRequest,
  onSuccessAnalyticsHumanAuthorDataRequest,
  onSuccessUserProjectKwListRequest,
  getFilterSuccess,
  filterSubmitSuccess,
  deleteFilterSuccess,
  editfilterSubmitSuccess,
  showMailMessage,
  hideMailMessage


} from 'actions/Analytics';

import {
  ANALYTICS_REQUEST_PROJECT_GRAPH_DIST,
  ANALYTICS_REQUEST_PROJECT_SUMMARY,
  ANALYTICS_REQUEST_PROJECT_AUTHOR,
  ANALYTICS_REQUEST_TWITTER_AUTHOR,
  ANALYTICS_REQUEST_REDDIT_AUTHOR,
  ANALYTICS_REQUEST_INSTA_AUTHOR,
  ANALYTICS_REQUEST_WEB_AUTHOR,
  ANALYTICS_REQUEST_HUMAN_AUTHOR,
  ANALYTICS_REQUEST_YOUTUBE_AUTHOR,
  VIEW_POST_BY_AUTHOR,
  GET_KEYWORD_NAMES,
  ANALYTICS_REQUEST_PROJECT_LANG_DIST,
  ANALYTICS_REQUEST_PROJECT_WORD_COUNT,

  ANALYTICS_REQUEST_SUMMARY_GRAPH,
  ANALYTICS_REQUEST_SUMMARY_TOP,
  ANALYTICS_REQUEST_LANGUAGE_GRAPH,
  ANALYTICS_REQUEST_LOCATION_GRAPH,
  ANALYTICS_REQUEST_WORD_COUNT,
  ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA,
  ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA,
  ANALYTICS_REQUEST_INSTA_AUTHOR_DATA,
  ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA,
  ANALYTICS_REQUEST_WEB_AUTHOR_DATA,
  ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA,
  ANALYTICS_USER_PROJECT_KW_LIST,
  VIEW_POST_BY_AUTHOR_DATA,
  ON_FILTER_SUBMIT_ANALYTICS,
  GET_FILTER_LIST_ANALYTICS,
  ON_DELETE_FILTER_ANALYTICS,
  ON_EDIT_FILTER_SUBMIT,
} from 'constants/ActionTypes';

import apiClient from 'apiclient';
import {cAxios} from 'apiclient';
import queryString from 'query-string';
import {IS_LOCAL} from 'constants/app';


const getDateRange = (state) => state.analytics.dateRange;

const getPro = async (params) => {
  const post_det = {
      project_id: parseInt(params.project_id),
      keyword_id: params.keyword_id,
      activeSrcs: params.activeSrcs,
      from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
      to_date: params.dateRange.selected.to.format('YYYY-MM-DD')
  };
  return await apiClient({url:'/api/analytics/sources/distribution', method: 'post', data: post_det, timeout: 0, responseType: 'json'})
      .then(response => response.data);
      // .catch(error => error);
};

function* requestProjectAnalytics(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedPro = yield call(getPro, res.payload);
    yield put(onSuccessProjectAnalyticsGraphDistRequest(fetchedPro));
  } catch (error) {
    console.log(error);
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getSummary = async (params) => {
  const post_det = {
      project_id: parseInt(params.project_id),
      keyword_id: params.keyword_id,
      activeSrcs: params.activeSrcs,
      from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
      to_date: params.dateRange.selected.to.format('YYYY-MM-DD')
  };
  return await apiClient({url:'/api/analytics/sources/summary', method: 'post', data: post_det, timeout: 0, responseType: 'json'})
      .then(response => response.data);
      // .catch(error => error);
};

function* requestProjectAnalyticsSummary(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedSummary = yield call(getSummary, res.payload);
    yield put(onSuccessProjectAnalyticsSummaryRequest(fetchedSummary));
  } catch (error) {
    console.log(error);
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getAuthor = async (params) => {
  const post_det = {
      project_id: parseInt(params.project_id),
      keyword_id: params.keyword_id
  };
  let postDataTwitter = {
    project_id: parseInt(params.project_id),
    keyword_id: params.keyword_id,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'fcnt'
  }
  let twitterRequest = apiClient({
    url:'/api/analytics/sources/twitter/author',
    method: 'post',
    data: postDataTwitter,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);

  let postDataReddit = {
    project_id: parseInt(params.project_id),
    keyword_id: params.keyword_id,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'karma'
  }
  let redditRequest = apiClient({
    url:'/api/analytics/sources/reddit/author',
    method: 'post',
    data: postDataReddit,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);

  let postDataInsta = {
    project_id: parseInt(params.project_id),
    keyword_id: params.keyword_id,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'followers'
  }
  let instaRequest = apiClient({
    url:'/api/analytics/sources/insta/author',
    method: 'post',
    data: postDataInsta,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);

  let postDataWeb = {
    project_id: parseInt(params.project_id),
    keyword_id: params.keyword_id,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'kwrank'
  }

  let postDataHuman = {
    project_id: parseInt(params.project_id),
    keyword_id: params.keyword_id,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'posts'
  }

  let webRequest = apiClient({
    url:'/api/analytics/sources/web/author',
    method: 'post',
    data: postDataWeb,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);

  let humanRequest = apiClient({
    url:'/api/analytics/sources/human/author',
    method: 'post',
    data: postDataHuman,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);

  let postDataYoutube = {
    project_id: parseInt(params.project_id),
    keyword_id: params.keyword_id,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'subscriber_count'
  }
  let youtubeRequest = apiClient({
    url:'/api/analytics/sources/youtube/author',
    method: 'post',
    data: postDataYoutube,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);

  // let genericRequest = apiClient({
  //   url:'/api/analytics/sources/author',
  //   method: 'post',
  //   data: post_det,
  //   timeout: 0,
  //   responseType: 'json'
  // }).then(response => response.data);

  return await cAxios.all([
    //genericRequest,
    twitterRequest,
    redditRequest,
    instaRequest,
    webRequest,
    youtubeRequest,
    humanRequest
  ]).then(cAxios.spread((...responses) => {
    return responses;
  }));
};

function* requestProjectAnalyticsAuthor(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedAuthor = yield call(getAuthor, res.payload);
    yield put(onSuccessProjectAnalyticsAuthorRequest(fetchedAuthor));
  } catch (error) {
    console.log(error);
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getTwitterAuthor = async (params) => {
  let postDataTwitter = {
    project_id: parseInt(params.projectId),
    keyword_id: params.keywordIds,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'fcnt',
    page: params.page+1
  }
  return await apiClient({
    url:'/api/analytics/sources/twitter/author',
    method: 'post',
    data: postDataTwitter,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* getTwitterAuthorRequest(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedAuthors = yield call(getTwitterAuthor, res.payload);
    yield put(onSuccessTwitterAuthorRequest(fetchedAuthors));
  } catch (error) {
    console.log(error);
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}


const getRedditAuthor = async (params) => {
  let postDataReddit = {
    project_id: parseInt(params.projectId),
    keyword_id: params.keywordIds,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'karma',
    page: params.page+1
  }
  return await apiClient({
    url:'/api/analytics/sources/reddit/author',
    method: 'post',
    data: postDataReddit,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* getRedditAuthorRequest(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedAuthors = yield call(getRedditAuthor, res.payload);
    yield put(onSuccessRedditAuthorRequest(fetchedAuthors));
  } catch (error) {
    console.log(error);
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getInstaAuthor = async (params) => {
  let postDataInsta = {
    project_id: parseInt(params.projectId),
    keyword_id: params.keywordIds,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'followers',
    page: params.page+1
  }
  return await apiClient({
    url:'/api/analytics/sources/insta/author',
    method: 'post',
    data: postDataInsta,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* getInstaAuthorRequest(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedAuthors = yield call(getInstaAuthor, res.payload);
    yield put(onSuccessInstaAuthorRequest(fetchedAuthors));
  } catch (error) {
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getWebAuthor = async (params) => {
  let postDataWeb = {
    project_id: parseInt(params.projectId),
    keyword_id: params.keywordIds,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'followers',
    page: params.page+1
  }
  return await apiClient({
    url:'/api/analytics/sources/web/author',
    method: 'post',
    data: postDataWeb,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* getWebAuthorRequest(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedAuthors = yield call(getWebAuthor, res.payload);
    yield put(onSuccessWebAuthorRequest(fetchedAuthors));
  } catch (error) {
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getHumanAuthor = async (params) => {
  let postDataHuman = {
    project_id: parseInt(params.projectId),
    keyword_id: params.keywordIds,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'followers',
    page: params.page+1
  }
  return await apiClient({
    url:'/api/analytics/sources/human/author',
    method: 'post',
    data: postDataHuman,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* getHumanAuthorRequest(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedAuthors = yield call(getHumanAuthor, res.payload);
    yield put(onSuccessHumanAuthorRequest(fetchedAuthors));
  } catch (error) {
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getYoutubeAuthor = async (params) => {
  let postDataYoutube = {
    project_id: parseInt(params.projectId),
    keyword_id: params.keywordIds,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'followers',
    page: params.page+1
  }
  return await apiClient({
    url:'/api/analytics/sources/youtube/author',
    method: 'post',
    data: postDataYoutube,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* getYoutubeAuthorRequest(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedAuthors = yield call(getYoutubeAuthor, res.payload);
    yield put(onSuccessYoutubeAuthorRequest(fetchedAuthors));
  } catch (error) {
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}


const getKeywordNamesApi = async (params) => {
  const post_det = {
      project_id: parseInt(params.project_id)
  };
  return await apiClient({url:'/api/analytics/project/kw', method: 'post', data: post_det, timeout: 0, responseType: 'json'})
      .then(response => response.data);
      // .catch(error => error);
};


function* getKeywordNames(res) {
  try {
    const fetchedNames = yield call(getKeywordNamesApi, res.payload);
    yield put(getKeywordNamesOnSuccess(fetchedNames, res.payload.kw_id));
  } catch (error) {
    console.log(error);
    yield put(showMailMessage("Oops! Unable to fetch Keyword List right now!"));
  }
}

const getPostByAuthorRequest = async (params) => {

  let postData = {
    project_id: parseInt(params.projectId),
    keyword_id: params.keywordIds,
    from_date: params.dateRange.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.to.format('YYYY-MM-DD'),
    author:  params.author,
    page: params.currentPage+1,
    src:  params.ikey
  }
  return await apiClient({
    url:'/api/author/posts',
    method: 'post',
    data: postData,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* getPostByAuthor(request) {
  try {
    const postList = yield call(getPostByAuthorRequest, request.payload);
    yield put(onSuccessPostByAuthor(postList));
  } catch (error) {
    console.log(error);
    yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getLangPro = async (params) => {
  const post_det = {
      project_id: parseInt(params.project_id),
      keyword_id: params.keyword_id,
      activeSrcs: params.activeSrcs,
      from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
      to_date: params.dateRange.selected.to.format('YYYY-MM-DD')
  };
  return await apiClient({url:'/api/analytics/sources/lang/distribution', method: 'post', data: post_det, timeout: 0, responseType: 'json'})
      .then(response => response.data);
      // .catch(error => error);
};

function* requestProjectLangAnalytics(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedLangPro = yield call(getLangPro, res.payload);
    yield put(onSuccessProjectAnalyticsLangDistRequest(fetchedLangPro));
  } catch (error) {
    console.log(error);
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getWordCountPro = async (params) => {
  const post_det = {
      project_id: parseInt(params.project_id),
      keyword_id: params.keyword_id,
      from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
      to_date: params.dateRange.selected.to.format('YYYY-MM-DD')
  };
  return await apiClient({url:'/api/analytics/sources/wordcount', method: 'post', data: post_det, timeout: 0, responseType: 'json'})
      .then(response => response.data);
      // .catch(error => error);
};

function* requestProjectWordCountAnalytics(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedWordCountPro = yield call(getWordCountPro, res.payload);
    console.log(fetchedWordCountPro);
    yield put(onSuccessProjectAnalyticsWordCountRequest(fetchedWordCountPro));
  } catch (error) {
    console.log(error);
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}


const getSummaryGraphs = async (params) => {
  const post_det = {
      keyword_ids: params.keyword_ids,
      activeSrcs: params.activeSrcs,
      from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
      to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
      senti: params.senti,
      langList: params.langList,
      locList: params.locList
  };
  return await apiClient({url:'/api/analytics/summary/graphs', method: 'post', data: post_det, timeout: 0, responseType: 'json'})
      .then(response => response.data);
      // .catch(error => error);
};

const getLanguageGraphs = async (params) => {
  const post_det = {
      keyword_ids: params.keyword_ids,
      activeSrcs: params.activeSrcs,
      from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
      to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
      senti: params.senti,
      langList: params.langList,
      locList: params.locList
  };
  return await apiClient({url:'/api/analytics/language/graphs', method: 'post', data: post_det, timeout: 0, responseType: 'json'})
      .then(response => response.data);
      // .catch(error => error);
};

const getLocationGraphs = async (params) => {
  console.log(params);
  const post_det = {
      keyword_ids: params.keyword_ids,
      activeSrcs: params.activeSrcs,
      from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
      to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
      senti: params.senti,
      langList: params.langList,
      locList: params.locList
  };
  return await apiClient({url:'/api/analytics/location/graphs', method: 'post', data: post_det, timeout: 0, responseType: 'json'})
      .then(response => response.data);
      // .catch(error => error);
};

const getWordCount = async (params) => {
  const post_det = {
      keyword_ids: params.keyword_ids,
      activeSrcs: params.activeSrcs,
      from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
      to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
      senti: params.senti,
      langList: params.langList,
      locList: params.locList
  };
  return await apiClient({url:'/api/analytics/word/count', method: 'post', data: post_det, timeout: 0, responseType: 'json'})
      .then(response => response.data);
      // .catch(error => error);
};


function* requestAnalyticsSummaryGraph(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedRes = yield call(getSummaryGraphs, res.payload);
    yield put(onSuccessAnalyticsSummaryGraphsRequest(fetchedRes));
  } catch (error) {
    //yield put(showMailMessage("Oops! Unable to fetch Competitive Analysis right now!"));
  }
}

const getSummaryTop = async (params) => {
  const post_det = {
      keyword_ids: params.keyword_ids,
      activeSrcs: params.activeSrcs,
      from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
      to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
      senti: params.senti,
      langList: params.langList,
      locList: params.locList
  };
  return await apiClient({url:'/api/analytics/summary/top', method: 'post', data: post_det, timeout: 0, responseType: 'json'})
      .then(response => response.data);
      // .catch(error => error);
};

function* requestAnalyticsSummaryTop(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedRes = yield call(getSummaryTop, res.payload);
    yield put(onSuccessAnalyticsSummaryTopDataRequest(fetchedRes));
  } catch (error) {
    //yield put(showMailMessage("Oops! Unable to fetch Summary Data right now!"));
  }
}



function* requestAnalyticsLanguageGraph(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedRes = yield call(getLanguageGraphs, res.payload);
    yield put(onSuccessAnalyticsLanguageGraphsRequest(fetchedRes));
  } catch (error) {
    console.log(error);
    //yield put(showMailMessage("Oops! Unable to fetch Language Graph Data right now!"));
  }
}

function* requestAnalyticsLocationGraph(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedRes = yield call(getLocationGraphs, res.payload);
    yield put(onSuccessAnalyticsLocationGraphsRequest(fetchedRes));
  } catch (error) {
    //yield put(showMailMessage("Oops! Unable to fetch Location Graph Data right now!"));
  }
}

function* requestAnalyticsWordCount(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedRes = yield call(getWordCount, res.payload);
    yield put(onSuccessAnalyticsWordCountRequest(fetchedRes));
  } catch (error) {
    //yield put(showMailMessage("Oops! Unable to fetch Trending topics right now!"));
  }
}

const getTwitterAuthorData = async (params) => {
  let postDataTwitter = {
    keyword_ids: params.keyword_ids,
    activeSrcs: params.activeSrcs,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'fcnt',
    order: params.order !== undefined ? params.order : 'desc',
    page: params.page+1,
    senti: params.senti,
    langList: params.langList,
    locList: params.locList
  }
  return await apiClient({
    url:'/api/analytics/sources/twitter/author/data',
    method: 'post',
    data: postDataTwitter,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* getTwitterAuthorDataRequest(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedAuthors = yield call(getTwitterAuthorData, res.payload);
    yield put(onSuccessAnalyticsTwitterAuthorDataRequest(fetchedAuthors));
  } catch (error) {
    console.log(error);
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}


const getYoutubeAuthorData = async (params) => {
  let postDataYoutube = {
    keyword_ids: params.keyword_ids,
    activeSrcs: params.activeSrcs,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'followers',
    order: params.order !== undefined ? params.order : 'desc',
    page: params.page+1,
    senti: params.senti,
    langList: params.langList,
    locList: params.locList
  }
  return await apiClient({
    url:'/api/analytics/sources/youtube/author/data',
    method: 'post',
    data: postDataYoutube,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* getYoutubeAuthorDataRequest(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedAuthors = yield call(getYoutubeAuthorData, res.payload);
    yield put(onSuccessAnalyticsYoutubeAuthorDataRequest(fetchedAuthors));
  } catch (error) {
    console.log(error);
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getInstaAuthorData = async (params) => {
  let postDataInsta = {
    keyword_ids: params.keyword_ids,
    activeSrcs: params.activeSrcs,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'followers',
    order: params.order !== undefined ? params.order : 'desc',
    page: params.page+1,
    senti: params.senti,
    langList: params.langList,
    locList: params.locList
  }
  return await apiClient({
    url:'/api/analytics/sources/insta/author/data',
    method: 'post',
    data: postDataInsta,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* getInstaAuthorDataRequest(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedAuthors = yield call(getInstaAuthorData, res.payload);
    yield put(onSuccessAnalyticsInstaAuthorDataRequest(fetchedAuthors));
  } catch (error) {
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getRedditAuthorData = async (params) => {
  let postDataReddit = {
    keyword_ids: params.keyword_ids,
    activeSrcs: params.activeSrcs,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'followers',
    order: params.order !== undefined ? params.order : 'desc',
    page: params.page+1,
    senti: params.senti,
    langList: params.langList,
    locList: params.locList
  }
  return await apiClient({
    url:'/api/analytics/sources/reddit/author/data',
    method: 'post',
    data: postDataReddit,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* getRedditAuthorDataRequest(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedAuthors = yield call(getRedditAuthorData, res.payload);
    yield put(onSuccessAnalyticsRedditAuthorDataRequest(fetchedAuthors));
  } catch (error) {
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getWebAuthorData = async (params) => {
  let postDataWeb = {
    keyword_ids: params.keyword_ids,
    activeSrcs: params.activeSrcs,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'followers',
    order: params.order !== undefined ? params.order : 'desc',
    page: params.page+1,
    senti: params.senti,
    langList: params.langList,
    locList: params.locList
  }
  return await apiClient({
    url:'/api/analytics/sources/web/author/data',
    method: 'post',
    data: postDataWeb,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* getWebAuthorDataRequest(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedAuthors = yield call(getWebAuthorData, res.payload);
    yield put(onSuccessAnalyticsWebAuthorDataRequest(fetchedAuthors));
  } catch (error) {
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getHumanAuthorData = async (params) => {
  let postDataHuman = {
    keyword_ids: params.keyword_ids,
    activeSrcs: params.activeSrcs,
    from_date: params.dateRange.selected.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.selected.to.format('YYYY-MM-DD'),
    orderBy: params.orderBy !== undefined ? params.orderBy : 'followers',
    order: params.order !== undefined ? params.order : 'desc',
    page: params.page+1,
    senti: params.senti,
    langList: params.langList,
    locList: params.locList
  }
  return await apiClient({
    url:'/api/analytics/sources/human/author/data',
    method: 'post',
    data: postDataHuman,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* getHumanAuthorDataRequest(res) {
  try {
    let dateRange = yield select(getDateRange);
    res.payload.dateRange = dateRange;
    const fetchedAuthors = yield call(getHumanAuthorData, res.payload);
    yield put(onSuccessAnalyticsHumanAuthorDataRequest(fetchedAuthors));
  } catch (error) {
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getUserProjectKwList = async (params) => {
  let postData = {
    all_kw: params.all_kw,
    is_local: IS_LOCAL
  }
  return await apiClient({
    url:'/api/analytics/user/project/kw/list',
    method: 'post',
    data: postData,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* requestUserProjectKwList(res) {
  try {

    const fetchedRes = yield call(getUserProjectKwList, res.payload);
    yield put(onSuccessUserProjectKwListRequest(fetchedRes));
  } catch (error) {
    console.log(error);
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getPostByAuthorDataRequest = async (params) => {
  let postData = {
    keyword_id: params.keywordIds,
    from_date: params.dateRange.from.format('YYYY-MM-DD'),
    to_date: params.dateRange.to.format('YYYY-MM-DD'),
    author:  params.author,
    page: params.currentPage+1,
    src:  params.ikey
  }
  return await apiClient({
    url:'/api/analytics/author/posts/data',
    method: 'post',
    data: postData,
    timeout: 0,
    responseType: 'json'
  }).then(response => response.data);
};

function* getPostByAuthorData(request) {
  try {
    const postList = yield call(getPostByAuthorDataRequest, request.payload);
    yield put(onSuccessPostByAuthorData(postList));
  } catch (error) {
    console.log(error);
    yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const onFilterSubmitRequest = async (res) => {
  const postData = {sentiPos: res.payload.sentiPos, sentiNeg: res.payload.sentiNeg, sentiNet: res.payload.sentiNet, sentiUnk: res.payload.sentiUnk, filterName: res.payload.filterName, langList: res.payload.langList, locList: res.payload.locList, activeSrcFilter: res.payload.activeSrcFilter, reach: res.payload.reach};
  return await apiClient.post('/api/analytics/add-filter-save', postData)
    .then(response => response.data);
};

function* onFilterSubmit(payload) {
  try {
    let response = yield call(onFilterSubmitRequest, payload);
    yield put(showMailMessage("Filter Saved Successfully."));
    yield put(filterSubmitSuccess());
    let respons = yield call(filterRequest, payload);
    yield put(getFilterSuccess(respons));
  } catch (error) {
    yield put(showMailMessage("Oops! Something Went Wrong"));
    yield put(filterSubmitSuccess());
  }
}

const filterRequest = async (res) => {
  const postData = {};
  return await apiClient.post('/api/analytics/get-filter-list', postData)
    .then(response => response.data);
};

function* getFilterList(payload) {
  try {
    let response = yield call(filterRequest, payload);
    yield put(getFilterSuccess(response));
  } catch (error) {
    yield put(showMailMessage("Oops! Unable to get Filter List!"));
  }
}

const deleteFilterRequest = async (request) => {
  const postData = { filter_id: request.filter_id };
  return await apiClient.post('/api/analytics/delete-filter', postData)
    .then(response => response.data);
}

function* onDeleteFilter(request) {
  try {
    const del_res = yield call(deleteFilterRequest, request.payload);
    if (del_res.error === 1) {
       yield put(showMailMessage(del_res.errMsg));
       yield put(editfilterSubmitSuccess());
    }
    else {
      yield put(showMailMessage(del_res.errMsg));
      yield put(editfilterSubmitSuccess());
    }
    yield delay(1000);
    yield put(hideMailMessage());
  } catch (error) {
    yield put(showMailMessage("Oops! Unable to delete that filter!"));
  }
}

const onEditFilterSubmitRequest = async (res) => {
  const postData = {sentiPos: res.payload.sentiPos, sentiNeg: res.payload.sentiNeg, sentiNet: res.payload.sentiNet, sentiUnk: res.payload.sentiUnk, langList: res.payload.langList, locList: res.payload.locList, activeSrcFilter: res.payload.activeSrcFilter, filter_id: res.payload.filter_id};
  return await apiClient.post('/api/analytics/edit-filter-save', postData)
    .then(response => response.data);
};

function* onEditFilterSubmit(payload) {
  try {
    let response = yield call(onEditFilterSubmitRequest, payload);
    yield put(showMailMessage("Filter Updated Successfully."));
    yield put(editfilterSubmitSuccess());
  } catch (error) {
    yield put(showMailMessage("Oops! Something Went Wrong"));
    yield put(editfilterSubmitSuccess());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ANALYTICS_REQUEST_PROJECT_GRAPH_DIST, requestProjectAnalytics),
    takeLatest(ANALYTICS_REQUEST_PROJECT_LANG_DIST, requestProjectLangAnalytics),
    takeLatest(ANALYTICS_REQUEST_PROJECT_WORD_COUNT, requestProjectWordCountAnalytics),
    takeLatest(ANALYTICS_REQUEST_PROJECT_SUMMARY, requestProjectAnalyticsSummary),
    takeLatest(ANALYTICS_REQUEST_PROJECT_AUTHOR, requestProjectAnalyticsAuthor),
    takeLatest(ANALYTICS_REQUEST_TWITTER_AUTHOR, getTwitterAuthorRequest),
    takeLatest(ANALYTICS_REQUEST_REDDIT_AUTHOR, getRedditAuthorRequest),
    takeLatest(ANALYTICS_REQUEST_INSTA_AUTHOR, getInstaAuthorRequest),
    takeLatest(ANALYTICS_REQUEST_WEB_AUTHOR, getWebAuthorRequest),
    takeLatest(ANALYTICS_REQUEST_HUMAN_AUTHOR, getHumanAuthorRequest),
    takeLatest(ANALYTICS_REQUEST_YOUTUBE_AUTHOR, getYoutubeAuthorRequest),
    takeLatest(GET_KEYWORD_NAMES, getKeywordNames),
    takeLatest(VIEW_POST_BY_AUTHOR, getPostByAuthor),
    takeLatest(VIEW_POST_BY_AUTHOR_DATA, getPostByAuthorData),

    takeLatest(ANALYTICS_REQUEST_SUMMARY_GRAPH, requestAnalyticsSummaryGraph),
    takeLatest(ANALYTICS_REQUEST_SUMMARY_TOP, requestAnalyticsSummaryTop),
    takeLatest(ANALYTICS_REQUEST_LANGUAGE_GRAPH, requestAnalyticsLanguageGraph),
    takeLatest(ANALYTICS_REQUEST_WORD_COUNT, requestAnalyticsWordCount),
    takeLatest(ANALYTICS_REQUEST_LOCATION_GRAPH, requestAnalyticsLocationGraph),
    takeLatest(ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA, getTwitterAuthorDataRequest),
    takeLatest(ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA, getYoutubeAuthorDataRequest),
    takeLatest(ANALYTICS_REQUEST_INSTA_AUTHOR_DATA, getInstaAuthorDataRequest),
    takeLatest(ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA, getRedditAuthorDataRequest),
    takeLatest(ANALYTICS_REQUEST_WEB_AUTHOR_DATA, getWebAuthorDataRequest),
    takeLatest(ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA, getHumanAuthorDataRequest),
    takeLatest(ANALYTICS_USER_PROJECT_KW_LIST, requestUserProjectKwList),
    takeEvery(ON_FILTER_SUBMIT_ANALYTICS, onFilterSubmit),
    takeLatest(ON_EDIT_FILTER_SUBMIT, onEditFilterSubmit),
    takeEvery(GET_FILTER_LIST_ANALYTICS, getFilterList),
    takeEvery(ON_DELETE_FILTER_ANALYTICS, onDeleteFilter),

  ]);
}
