import {
  FETCH_PLANS,
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_FAILED,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILED
} from 'constants/ActionTypes';

const INIT_STATE = {
  'loading': true,
  subscriptionPlanFetchStatus: 0,
  subscriptionPlans: [],
  subscriptionUpdating: false,
  subscriptionUpdateStatus: 0,
  postSubscriptionUpdateMessage: '',
  activePlan: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PLANS: {
      return {
        ...state,
        subscriptionPlanFetchStatus: 1 // Loading
      }
    }

    case FETCH_PLANS_SUCCESS: {
      return {
        ...state,
        subscriptionPlanFetchStatus: 2, // success
        subscriptionPlans: action.payload.plans,
        activePlan: action.payload.active
      }
    }

    case FETCH_PLANS_FAILED: {
      return {
        ...state,
        subscriptionPlanFetchStatus: 3 // Error
      }
    }

    case UPDATE_SUBSCRIPTION: {
      return {
        ...state,
        subscriptionUpdateStatus: 1,
        subscriptionUpdating: true
      }
    }
    case UPDATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscriptionUpdateStatus: 2,
        postSubscriptionUpdateMessage: `Subscription Upgrade success!`,
        subscriptionUpdating: false,
        // activePlan: action.payload.active
      }
    }
    case UPDATE_SUBSCRIPTION_FAILED: {
      return {
        ...state,
        subscriptionUpdateStatus: 3,
        postSubscriptionUpdateMessage: `Unable to update your subscription! (${action.payload.message})`,
        subscriptionUpdating: false
      }
    }
    default:
      return state;
  }
};
