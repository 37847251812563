import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink, withRouter} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import {fetchProjects} from 'actions/Project';
import {fetchUser} from 'actions/Setting';
//import {sideBarState} from 'actions/Setting';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import {Badge} from 'reactstrap';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';

import {WHITE_LABEL_ENABLED} from 'constants/app';
import LinearProgress from "@material-ui/core/LinearProgress";
class SidenavContent extends Component {
  componentDidMount() {
    //const {history} = this.props;
    const that = this;
    that.props.fetchProjects();
    that.props.fetchUser();
  }

  constructor() {
    super();
    this.state = {
      anchorbl: null,
      openedPopoverId: null
    }
  }

  toggleMenu() {
    const {history} = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path
    const menuLi = document.getElementsByClassName('menu');

    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        const parentLiEle = that.closest(this, 'li');
        if(menuLi[i].classList.contains('menu') && parentLiEle !== null) {
          event.stopPropagation();

          if(menuLi[i].classList.contains('open')) {
            menuLi[i].classList.remove('open', 'active', 'primary');
          } else {
            menuLi[i].classList.add('open', 'active', 'primary');
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, 'li');
            if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
              menuLi[j].classList.remove('open');
            } else {
              if(menuLi[j].classList.contains('open')) {
                menuLi[j].classList.remove('open');
              } else {
                menuLi[j].classList.add('open');
              }
            }
          }
        }
      }
    }
    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  componentWillReceiveProps(nextProps) {
    const {history} = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }

  isProjectActive = (path, pid) => {
    return (path.includes("/project/"+pid+"/")) ? 'badge-primary' : 'bg-white text-grey';
  }

  isProjectActiveToggle = (path, pid) => {
    return (path.includes("/project/"+pid+"/")) ? 'no-arrow' : '';
  }

  getCurPro = (cur_params) => {
    let project_id = 0;
    if (cur_params.includes("/analytics/project/")) {
      project_id = this.props.location.pathname.split("/")[4];
    }
    else {
      project_id = this.props.location.pathname.split("/")[3]
    }
    return project_id;
  }

  handlePopoverOpen = (event, popoverId) => {

    this.setState({
      openedPopoverId: popoverId,
      anchorbl: event.currentTarget
    });
    //this.props.sideBarState('open');
    event.stopPropagation();
    event.preventDefault();
    return null;
  };

  handlePopoverClose = () => {
    //this.props.sideBarState('close');
    this.setState({
      openedPopoverId: null,
      anchorbl: null
    });
  };

  render() {
    /*<span className="float-right small badge badge-pill badge-success mr-2">+10</span>
    */
    //open = this.state.anchorbl ? Boolean(this.state.anchorbl) : null;
    return (
      <CustomScrollbars className=" scrollbar">
       {this.props.project.projectLoading?
        <div className="d-flex align-items-center justify-content-center p-1"><CircularProgress size="20px" color="primary"/></div>
        :

          <ul className="nav-menu">

          { (this.props.project.progress !== undefined && this.props.project.progress.length !== 0 && this.props.project.free_user !== undefined && this.props.project.free_user === 1 && (WHITE_LABEL_ENABLED == undefined || WHITE_LABEL_ENABLED==0)) ?
            <li className="user-info-sidebar trial-progress">
            <Card className="">
            <CardContent>
              <Typography className="free-trail-font">{this.props.project.progress.rem}
              {this.props.project.progress.rem === 1 ?<IntlMessages id="sidebar.accountStatus.freeTrialDayLeftOne"/> :<IntlMessages id="sidebar.accountStatus.freeTrialDayLeft"/> }
              </Typography>
              <div><LinearProgress className="mt-3" variant="determinate" value={this.props.project.progress.progress_val} color="primary"/></div>
            </CardContent>
            </Card>
            </li>
             : ''}

          <li className="user-info-sidebar">
          {this.props.project.users !== undefined ?
          <Card className="">
            <CardContent>
              <Typography className="">
                <IntlMessages id="sidebar.accountStatus.heading"/>
              </Typography>
              <table width="100%">
                <tbody>
                  <tr>
                    <td className="pt-1"><IntlMessages id="sidebar.accountStatus.projects"/></td>
                    <td className="text-right pt-1">
                      <NumberFormat
                        value={this.props.project.users !== undefined && this.props.project.users.pro_used_quota !== undefined ? this.props.project.users.pro_used_quota : 0}
                        thousandSeparator={true}
                        displayType={'text'} />/
                      <NumberFormat value={this.props.project.users !== undefined && this.props.project.users.pro_allowed_quota !== undefined ? this.props.project.users.pro_allowed_quota : 0}
                        thousandSeparator={true}
                        displayType={'text'} />
                    </td>
                  </tr>
                  <tr>
                    <td className="pt-1"><IntlMessages id="sidebar.accountStatus.keywords"/></td>
                    <td className="text-right pt-1">
                      <NumberFormat
                        value={this.props.project.users !== undefined && this.props.project.users.key_used_quota !== undefined ? this.props.project.users.key_used_quota : 0}
                        thousandSeparator={true}
                        displayType={'text'} />/
                      <NumberFormat value={this.props.project.users !== undefined && this.props.project.users.key_allowed_quota !== undefined ? this.props.project.users.key_allowed_quota : 0}
                        thousandSeparator={true}
                        displayType={'text'} />
                    </td>
                  </tr>
                  <tr>
                    <td className="pt-1"><IntlMessages id="sidebar.accountStatus.mentions"/></td>
                    <td className="text-right pt-1">
                      <NumberFormat
                        value={this.props.project.users !== undefined && this.props.project.users.post_used_quota !== undefined ? this.props.project.users.post_used_quota : 0}
                        thousandSeparator={true}
                        displayType={'text'} />/
                      <NumberFormat value={this.props.project.users !== undefined && this.props.project.users.post_allowed_quota !== undefined ? this.props.project.users.post_allowed_quota : 0}
                        thousandSeparator={true}
                        displayType={'text'} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardContent>
          </Card> : ''}


          {(this.props.project.free_user !== undefined && this.props.project.free_user === 1 && (WHITE_LABEL_ENABLED == undefined || WHITE_LABEL_ENABLED==0)) ?
          <a href={"/pricing"} className="anchor-no-css" rel="noopener noreferrer">
          <Button className="bg-primary bg-prim-upgrade btn-block text-center mt-3 mb-1" style={{}} variant="contained" size="small"><IntlMessages id="sidebar.upgrade"/></Button>
          </a>
          : '' }
          <a href={"/app/influencer-research"} className="anchor-no-css" rel="noopener noreferrer">
          <Button className="bg-primary bg-prim-upgrade btn-block text-center mt-3 mb-1" variant="contained" size="small">Influencer Research</Button>
          </a>
          <hr/>
          </li>
          {/* {( this.props.project.projects !== undefined && this.props.project.projects.length > 0) ?
          <li className="nav-header">
            <IntlMessages id="sidebar.reports"/>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon m-0 p-0 pb-1 pt-1 pr-rep"
                  to={"/app/analytics"}>
                    <i class="fa fa-bar-chart"></i> <IntlMessages id="sidebar.projectReport"/>
                </NavLink>
              </li>
            </ul>
          </li>
          :'' } */}

          <li className="nav-header">
            <IntlMessages id="sidebar.reports.heading"/>
          </li>

          <li className={`menu collapse-box`}>
            <Button className="create-report-box">
              <span
                className={`size-20 width-21 text-white text-center report-icon`}
                style={{fontSize: 12, display : 'inline-block'}}>
                <i className="fa fa-signal report-icon" aria-hidden="true"></i>
              </span>
              <span className="nav-text pl-3 text-truncate" style={{maxWidth: 'calc(100% - 60px)',}}>
                <a href={`/app/analytics`} rel="noopener noreferrer" className="new-pro p-0" style={{position: 'absolute', top: '6px', left: '50px'}}><IntlMessages id="sidebar.reports.dashboard"/></a>
              </span>
              </Button>
          </li>

          <li className={`menu collapse-box`}>
            <Button className="create-report-box">
              <span
                className={`size-20 width-21 text-white text-center report-icon`}
                style={{fontSize: 12, display : 'inline-block'}}>
                <i className="fa fa-chart-line report-icon" aria-hidden="true"></i>
              </span>
              <span className="nav-text pl-3 text-truncate" style={{maxWidth: 'calc(100% - 60px)',}}>
                <a href={"/create-report"} rel="noopener noreferrer" className="new-pro p-0" style={{position: 'absolute', top: '6px', left: '50px'}}><IntlMessages id="sidebar.reports.create"/></a>
              </span>
              </Button>
          </li>

          <li className="nav-header">
            <IntlMessages id="sidebar.projects.heading"/>
            <Tooltip title={<IntlMessages id="sidebar.project.addNew"/>} placement="top">
              <a href={"/project/create"} rel="noopener noreferrer" className="new-pro p-0 float-right"><IntlMessages id="sidebar.projects.addNew"/>
              </a>
            </Tooltip>
          </li>

            {( this.props.project.projects !== undefined && this.props.project.projects.length > 0) ? <div>
            {this.props.project.projects.map(project =>
              <li key={"project_"+project.project.id} className={`menu collapse-box open ${this.isProjectActiveToggle(this.props.location.pathname, project.project.id)}`} onClick={this.toggleMenu.bind(this)}>
              <Button>
                <div
               className={`zmdi-hc-fw rounded-circle size-20 width-21 text-white text-center ${this.isProjectActive(this.props.location.pathname, project.project.id)}`}
             style={{fontSize: 12, display : 'inline-block'}}> {project.project.pname.charAt(0).toUpperCase()}</div>
                <span className="nav-text pl-3 text-truncate" style={{maxWidth: 'calc(100% - 60px)',}}>
                  {project.project.pname}
                </span>
                {project.access_type == 1 ?
                <Tooltip title={<IntlMessages id="sidebar.projects.edit"/>} placement="top">
                <a href={"/project/" + project.project.id + "/keyword/edit-project"} rel="noopener noreferrer" className="pr-1 pt-1 pb-0 pl-0 float-right">
                <i className="zmdi zmdi-edit zmd-fw mr-0 float-right"/>
                </a>
                </Tooltip>
                : ''}
              </Button>

              <ul className="sub-menu">
                  {project.keywords.map(keyword =>
                    <li key={"kwid_"+project.id+keyword.id}>
                      <NavLink className="prepend-icon m-0 p-0 pb-1 pt-1"
                      to={"/app/project/" + project.project.id + "/keyword/" + keyword.keyword_detail.id} style={{width: 'calc(100% - 70px)', display: 'inline-block'}}>
                        <Tooltip title={keyword.keyword_detail.kw} placement="top">
                        <span className="nav-text text-truncate" fontStyle="oblique" style={(keyword.status===1)?{maxWidth:
                          '95%', display : 'inline-block'}:{maxWidth:
                            '95%', display : 'inline-block', 'fontStyle': 'oblique', 'color': '#ccc'}}>{keyword.keyword_detail.kw}</span>
                        </Tooltip>
                      </NavLink>
                      {(keyword['bool'] !== undefined && keyword['bool']['len'] !== 0) ?
                      <i className="bool-icon-wt"
                        aria-owns={this.state.anchorbl ? "bool-kw-popover" : undefined}
                        aria-haspopup="true"
                        onMouseEnter={(e) => this.handlePopoverOpen(e, "kwid_"+project.id+keyword.id)}
                        onMouseLeave={this.handlePopoverClose}>
                        <Popper
                          style={{ zIndex: 999999, overflow: 'visible'}}
                          id="bool-kw-popover"
                          open={this.state.openedPopoverId === "kwid_"+project.id+keyword.id}
                          anchorEl={this.state.anchorbl}
                          onClose={this.handlePopoverClose}
                          transition
                          placement="right-end"
                        >
                          <Paper className="popper-bg" elevation={5} style={{padding: '10px'}}>
                            <div className="font-weight-500 pb-2 text-gray-bg-wt text-popper"><IntlMessages id="sidebar.boolean.heading"/></div>

                            { keyword['bool'] !== undefined && keyword['bool'].length === 0 ? <IntlMessages id="sidebar.boolean.notFound"/> :
                            '' }

                            { keyword['bool'] !== undefined && keyword['bool'].length !== 0 ?
                              <div>
                              {keyword['bool']['not'].map(filter =>
                                <small className="w-100 font-weight-bold text-gray-bg"><Badge color="white"><IntlMessages id="sidebar.boolean.not"/></Badge>{filter}<br/></small>
                              )}
                              {keyword['bool']['pos'].map(filter =>
                                <small className="w-100 font-weight-bold text-gray-bg"><Badge color="white"><IntlMessages id="sidebar.boolean.and"/></Badge>{filter}<br/></small>

                              )}
                              {keyword['bool']['or'].map(filter =>
                                <small className="w-100 font-weight-bold text-gray-bg"><Badge color="white" style={{minWidth: '33px'}}><IntlMessages id="sidebar.boolean.or"/></Badge>{filter}<br/></small>
                              )}

                              {keyword['bool']['not_domain'].map(filter =>
                                <small className="w-100 font-weight-bold text-gray-bg"><Badge color="white"><IntlMessages id="sidebar.boolean.not"/></Badge><IntlMessages id="sidebar.boolean.domain"/> {filter}<br/></small>
                              )}

                              {keyword['bool']['len'] === 0 ? <IntlMessages id="sidebar.boolean.notFound"/> : ''}

                              </div>  :
                            '' }

                          </Paper>
                        </Popper>
                        </i> : '' }
                    </li>
                  )}


              </ul>
            </li>

            )}
             </div> : <li><IntlMessages id="sidebar.projects.noProject"/></li>}

          </ul>

       }
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = ({project, settings}) => {
  const {sidebar} = settings;
  return {project, sidebar};
};

export default withRouter(connect(mapStateToProps, {fetchProjects, fetchUser })(SidenavContent));
