
import {all, call, put, takeEvery, takeLatest, delay, select} from 'redux-saga/effects';

import {
  onSuccessInfluencerResearchData,
  quotaRequestSuccess,
  savedUrlRequestSuccess
} from 'actions/InfluencerResearch';

import {
  INFLUENCER_REQUEST_DATA,
  ON_QUOTA_REQUST,
  SAVED_URL_REQUEST
} from 'constants/ActionTypes';

import apiClient from 'apiclient';
import {cAxios} from 'apiclient';
import queryString from 'query-string';
import {IS_LOCAL} from 'constants/app';


const getInfluencerApi = async (params) => {
  //alert(params.page);
  let list_filter = JSON.parse(JSON.stringify(params.filter_list['list']));
  console.log(list_filter);
  /*list_filter.push({
  list_filter.push({
    "conditionType": "AND",
    "name": "Engagement Rate",
    "f_name": "engagement_rate",
    "f_type": 1,
    "oprName": "Less than",
    "operator": "<",
    "class_name": "lessThan",
    "value": "50",
    "filter_target": 0.7738328310583058,
    "status": 0
  });
  list_filter.push({
    "conditionType": "AND",
    "name": "Follower Count",
    "f_name": "followers_count",
    "f_type": 1,
    "oprName": "Greater than",
    "operator": ">",
    "class_name": "greaterThan",
    "value": "10",
    "filter_target": 0.6421745198419584,
    "status": 0
  });
  list_filter.push({
    "conditionType": "AND",
    "name": "Post Count",
    "f_name": "posts",
    "f_type": 1,
    "oprName": "Greater than",
    "operator": ">",
    "class_name": "greaterThan",
    "value": "50",
    "filter_target": 0.9437611236599766,
    "status": 0
  })
  */
  /*
  list_filter.push(
    {
    "groupId": 0.9615510606958517,
    "conditionType": "AND",
    "list": [
        {
            "conditionType": "AND",
            "name": "Follower Count",
            "f_name": "follower_count",
            "f_type": 1,
            "oprName": "Greater than",
            "operator": ">",
            "class_name": "greaterThan",
            "value": "10",
            "filter_target": 0.3980416785631544,
            "status": 0.9615510606958517
        },
        {
            "conditionType": "AND",
            "name": "Engagement Rate",
            "f_name": "er",
            "f_type": 1,
            "oprName": "Less than",
            "operator": "<",
            "class_name": "lessThan",
            "value": "50",
            "filter_target": 0.5707736891065287,
            "status": 0.9615510606958517
        },
        {
            "conditionType": "AND",
            "name": "Post Count",
            "f_name": "post",
            "f_type": 1,
            "oprName": "Greater than",
            "operator": ">",
            "class_name": "greaterThan",
            "value": "9",
            "filter_target": 0.5666866344833743,
            "status": 0.9615510606958517
        }
    ]
  });
  */
  list_filter.push(
    {
    "groupId": 0.9615510606958517,
    "conditionType": "AND",
    "list": [
        {
            "conditionType": "AND",
            "name": "Follower Count",
            "f_name": "reach",
            "f_type": 1,
            "oprName": "Greater than",
            "operator": ">",
            "class_name": "greaterThan",
            "value": "1000",
            "filter_target": 0.3980416785631544,
            "status": 0.9615510606958517
        }
    ]
  });
  console.log("list_filter");
  console.log(list_filter);

  let saved_kw_list = params.saved_kw_list.filter(function (el) { return !(el['src'] === '' && el['kw'] === '' && el['src_id'] === '')});

  const post_det = {
    kw_list: params.kw_list,
    list: list_filter,
    limit: params.limit,
    offset: params.limit*(params.page-1),
    filter_chk: params.filter_chk,
    saved_kw_list: saved_kw_list,
    sort_by: params.sort_by !== undefined ? params.sort_by: 'reach',
    sort_order: params.sort !== undefined ? params.sort : 'desc',
    and_cond: params.search_cond
  };
  return await apiClient({url:'/api/v2/get_influencer_data', method: 'post', data: post_det, timeout: 0, responseType: 'json'})
      .then(response => response.data);
      // .catch(error => error);
};


function* requestInfluecncerData(res) {
  try {
    const fetchedPro = yield call(getInfluencerApi, res.payload);
    yield put(onSuccessInfluencerResearchData(fetchedPro));
  } catch (error) {
    console.log(error);
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getQuotaApi = async () => {
  //alert(params.page);
  const post_det = {
  };
  return await apiClient({url:'/api/get_credit_details', method: 'post', data: post_det, timeout: 0, responseType: 'json'})
      .then(response => response.data.credits);
      // .catch(error => error);
};

function* quotaRequest() {
  try {
    const fetchedQuota = yield call(getQuotaApi);
    //const fetchedQuota = {"available_credit":1,"total_credit":3000,"expires_on":"2021-11-25"};
    yield put(quotaRequestSuccess(fetchedQuota));
  } catch (error) {
    console.log(error);
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}

const getSavedUrlApi = async (saved_url) => {
  //alert(params.page);
  const post_det = {
    saved_url: saved_url
  };
  //alert('here');
  return await apiClient({url:'/api/get_saved_url_details', method: 'post', data: post_det, timeout: 0, responseType: 'json'})
      .then(response => response.data);
      // .catch(error => error);
};


function* savedUrlRequest(res) {
  try {
    const fetchedData = yield call(getSavedUrlApi, res.payload);
    yield put(savedUrlRequestSuccess(fetchedData));
  } catch (error) {
    console.log(error);
    //yield put(showMailMessage("Oops! Unable to fetch posts right now!"));
  }
}



export default function* rootSaga() {
  yield all([
    takeLatest(INFLUENCER_REQUEST_DATA, requestInfluecncerData),
    takeLatest(ON_QUOTA_REQUST, quotaRequest),
    takeLatest(SAVED_URL_REQUEST, savedUrlRequest),
  ]);
}
