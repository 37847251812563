import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE,
  HORIZONTAL_MENU_POSITION,
  SWITCH_LANGUAGE,
  THEME_COLOR,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  SHOW_MESSAGE,
  SEND_INVITE_REQUEST,
  SEND_INVITE_REQUEST_SUCCESS,
  SHOW_INVITE_REQUEST,
  SHOW_INVITE_SUCCESS,
  USER_QUOTA_REQUEST,
  QUOTA_STAT_SUCCESS,
  SHOW_INVITE_ERROR,
  SHOW_SUPPORT_ERROR,
  SEND_SUPPORT_REQUEST,
  SEND_SUPPORT_REQUEST_SUCCESS,
  RESET_SUCCESS,
  WHITE_LABEL_INFO,
  WHITE_LABEL_INFO_SUCCESS
} from 'constants/ActionTypes';

export function toggleCollapsedNav(isNavCollapsed) {
  return {type: TOGGLE_COLLAPSED_NAV, isNavCollapsed};
}

export function setDrawerType(drawerType) {
  return {type: DRAWER_TYPE, drawerType};
}

export function updateWindowWidth(width) {
  return {type: WINDOW_WIDTH, width};
}

export function setThemeColor(color) {
  return {type: THEME_COLOR, color};
}

export function setDarkTheme() {
  return {type: DARK_THEME};
}

export function changeDirection() {
  return {type: CHANGE_DIRECTION};
}

export function changeNavigationStyle(layoutType) {
  return {
    type: CHANGE_NAVIGATION_STYLE,
    payload: layoutType
  };
}

export function setHorizontalMenuPosition(navigationPosition) {
  return {
    type: HORIZONTAL_MENU_POSITION,
    payload: navigationPosition
  };
}

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale
  };
}

export const fetchUser = () => {
  return {
    type: FETCH_USER_REQUEST
  };
};

export const fetchUserSuccess = (fetchedUser) => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: fetchedUser
  };
};

export const showUserMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const sendInvite = (name,email) => {
  return {
    type: SEND_INVITE_REQUEST,
    payload: {
      name,
      email
    }
  };
};

export const sendInviteSuccess = (response) => {
  return {
    type: SEND_INVITE_REQUEST_SUCCESS,
    payload: {
      response
    }
  };
};

export const showInvite = () => {
  return {
    type: SHOW_INVITE_REQUEST
  };
};

export const showInviteSuccess = (invites) => {
  return {
    type: SHOW_INVITE_SUCCESS,
    payload: invites
  };
};

export const quotaStatSuccessAction = (stats) => {
  return {
    type: QUOTA_STAT_SUCCESS,
    payload: stats
  }
};

export const quotaStatRequestAction = (stats) => {
  return {
    type: USER_QUOTA_REQUEST,
    payload: stats
  }
};

export const showInviteError = () => {
  return {
    type: SHOW_INVITE_ERROR
  };
};

export const showSupportError = () => {
  return {
    type: SHOW_SUPPORT_ERROR
  };
};

export const sendSupport = (issueDisc, images, imagenames) => {
  return {
    type: SEND_SUPPORT_REQUEST,
    payload: {
      issueDisc,
      images,
      imagenames
    }
  };
};

export const sendSupportSuccess = (response) => {
  return {
    type: SEND_SUPPORT_REQUEST_SUCCESS,
    payload: {
      response
    }
  };
};

export const resetSuccess = () => {
  return {
    type: RESET_SUCCESS
  };
};

export const whiteLabelInfo = () => {
  return {
    type: WHITE_LABEL_INFO
  };
};

export const whiteLabelInfoSuccess = (response) => {
  return {
    type: WHITE_LABEL_INFO,
    payload: {
      response
    }
  };
};
/*
export const sideBarState = (open_state) => {
  return {
    type: SIDE_BAR_STATE,
    payload: {
      open_state
    }
  };
};
*/
