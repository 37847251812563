import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {quotaStatRequestAction} from 'actions/Setting';
import NumberFormat from 'react-number-format';
import {WHITE_LABEL_ENABLED} from 'constants/app';
//import HighlightOffIcon from '@material-ui/icons/HighlightOff';

class WSSlideAlert extends React.Component {
  componentDidMount() {
    this.props.quotaStatRequestAction();
  }

  //handleDialogCloseRequest = () => {
  //  this.setState({quotaMsgShow: false});
  //};
  render() {
    let {quotaStats} = this.props;
    let shows = this.props.quotaStats.display_msg === 1 ? 'block': 'none';
    let shows_expire = this.props.quotaStats.plan_expire === 1 ? 'block': 'none';
    //this.setState({quotaMsgShow: true});
    //if (!this.state.openTagEditor)
      //shows = "none";
     // <HighlightOffIcon style={{ cursor:'pointer'}} fontSize="inherit" onClick={this.handleDialogCloseRequest}/>
    return (

      <div id="alerts-msg"> { (quotaStats.quota_reached === 1 && quotaStats.display_msg === 1 )? (quotaStats.diff_in_days < 0 || quotaStats.diff_in_days > 365) ? <div className="text-center bg-warning p-2 pt-3" style={{ minHeight: '50px', zIndex:100, color:'#fff', fontSize:'15px', display: shows }}>Your Quota for <b><NumberFormat value={quotaStats.quota_limit} thousandSeparator={true} displayType={'text'} /></b> mentions has exceeded! New mentions will not be collected anymore. You can {(WHITE_LABEL_ENABLED == undefined || WHITE_LABEL_ENABLED==0) ? <a href={"/pricing"} style={{color: '#fff', textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">upgrade</a>: 'upgrade'} your account to increase your quota.</div>: <div className="text-center bg-warning p-2 pt-3" style={{ minHeight: '50px', zIndex:100, color:'#fff', fontSize:'15px', display: shows }}>Your Quota for <b><NumberFormat value={quotaStats.quota_limit} thousandSeparator={true} displayType={'text'} /></b> mentions has exceeded! New mentions will not be collected anymore. You can {(WHITE_LABEL_ENABLED == undefined || WHITE_LABEL_ENABLED==0) ? <a href={"/pricing"} style={{color: '#fff', textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">upgrade</a>: 'upgrade'} your account to increase your quota or wait <b>{quotaStats.diff_in_days} days</b> for your quota to be reset.</div>

      :
        <div> { ( quotaStats.plan_expire === 1 )?  <div className="text-center bg-warning p-2 pt-3" style={{ minHeight: '50px', zIndex:100, color:'#fff', fontSize:'15px', display: shows_expire }}> Your Free Trial has been completed. Please {(WHITE_LABEL_ENABLED == undefined || WHITE_LABEL_ENABLED==0) ? <a href={"/pricing"} style={{color: '#fff', textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">upgrade</a>: 'upgrade'} your account.</div>

      :
      ''}
      </div>}
      </div>


    )
  }
}

const mapStateToProps = ({settings}) => {
  const {quotaStats} = settings;
  return {quotaStats};
};

export default withRouter(connect(mapStateToProps, {quotaStatRequestAction})(WSSlideAlert));
