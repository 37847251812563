import {
  ANALYTICS_PROJECT_REQUEST_GRAPH_DIST_SUCCESS,
  ANALYTICS_PROJECT_REQUEST_GRAPH_DIST_FAILED,
  ANALYTICS_REQUEST_PROJECT_GRAPH_DIST,
  ANALYTICS_PROJECT_REQUEST_LANG_DIST_SUCCESS,
  ANALYTICS_PROJECT_REQUEST_LANG_DIST_FAILED,
  ANALYTICS_REQUEST_PROJECT_LANG_DIST,
  ANALYTICS_PROJECT_REQUEST_WORD_COUNT_SUCCESS,
  ANALYTICS_PROJECT_REQUEST_WORD_COUNT_FAILED,
  ANALYTICS_REQUEST_PROJECT_WORD_COUNT,
  ANALYTICS_REQUEST_PROJECT_SUMMARY,
  ANALYTICS_PROJECT_REQUEST_SUMMARY_SUCCESS,
  ANALYTICS_PROJECT_REQUEST_SUMMARY_FAILED,
  ANALYTICS_REQUEST_PROJECT_AUTHOR,
  ANALYTICS_PROJECT_REQUEST_AUTHOR_SUCCESS,
  ANALYTICS_PROJECT_REQUEST_AUTHOR_FAILED,
  CHANGE_ACTIVE_SOURCE,
  ANALYTICS_CHANGE_DATE_RANGE,
  ANALYTICS_ON_CHANGE_AUTHOR_SORT,
  ANALYTICS_REQUEST_TWITTER_AUTHOR,
  ON_SUCCESS_ANALYTICS_REQUEST_TWITTER_AUTHOR,
  ANALYTICS_REQUEST_REDDIT_AUTHOR,
  ON_SUCCESS_ANALYTICS_REQUEST_REDDIT_AUTHOR,
  ANALYTICS_REQUEST_INSTA_AUTHOR,
  ON_SUCCESS_ANALYTICS_REQUEST_INSTA_AUTHOR,
  ANALYTICS_REQUEST_WEB_AUTHOR,
  ON_SUCCESS_ANALYTICS_REQUEST_WEB_AUTHOR,
  ANALYTICS_REQUEST_HUMAN_AUTHOR,
  ON_SUCCESS_ANALYTICS_REQUEST_HUMAN_AUTHOR,
  ANALYTICS_REQUEST_YOUTUBE_AUTHOR,
  ON_SUCCESS_ANALYTICS_REQUEST_YOUTUBE_AUTHOR,
  ANALYTICS_ON_CHANGE_KEYWORD_SELECT,
  GET_KEYWORD_NAMES,
  GET_KEYWORD_NAMES_ON_SUCCESS,
  GET_KEYWORD_NAMES_ON_FAILURE,
  VIEW_POST_BY_AUTHOR,
  CLOSE_VIEW_POST_BY_AUTHOR,
  ON_SUCCESS_POST_BY_AUTHOR,


  ANALYTICS_REQUEST_SUMMARY_GRAPH,
  ANALYTICS_REQUEST_SUMMARY_GRAPH_SUCCESS,
  ANALYTICS_REQUEST_SUMMARY_GRAPH_FAILED,
  ANALYTICS_REQUEST_LANGUAGE_GRAPH,
  ANALYTICS_REQUEST_LANGUAGE_GRAPH_SUCCESS,
  ANALYTICS_REQUEST_LANGUAGE_GRAPH_FAILED,
  ANALYTICS_REQUEST_WORD_COUNT,
  ANALYTICS_REQUEST_WORD_COUNT_SUCCESS,
  ANALYTICS_REQUEST_WORD_COUNT_FAILED,
  ANALYTICS_REQUEST_LOCATION_GRAPH,
  ANALYTICS_REQUEST_LOCATION_GRAPH_SUCCESS,
  ANALYTICS_REQUEST_LOCATION_GRAPH_FAILED,
  ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA,
  ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA_SUCCESS,
  ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA_FAILED,
  ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA,
  ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA_SUCCESS,
  ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA_FAILED,
  ANALYTICS_REQUEST_INSTA_AUTHOR_DATA,
  ANALYTICS_REQUEST_INSTA_AUTHOR_DATA_SUCCESS,
  ANALYTICS_REQUEST_INSTA_AUTHOR_DATA_FAILED,

  ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA,
  ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA_SUCCESS,
  ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA_FAILED,

  ANALYTICS_REQUEST_WEB_AUTHOR_DATA,
  ANALYTICS_REQUEST_WEB_AUTHOR_DATA_SUCCESS,
  ANALYTICS_REQUEST_WEB_AUTHOR_DATA_FAILED,

  ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA,
  ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA_SUCCESS,
  ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA_FAILED,

  ANALYTICS_USER_PROJECT_KW_LIST,
  ANALYTICS_USER_PROJECT_KW_LIST_SUCCESS,
  ANALYTICS_USER_PROJECT_KW_LIST_FAILED,
  ANALYTICS_ON_CHANGE_KEYWORD_SELECT_NEW,
  ANALYTICS_CHANGE_DATE_RANGE_FILTER,
  SENTI_ON_CHANGE_IMPACT,
  ACTIVE_SRC_NEW,
  ON_LANG_SUBMIT_ANALYTICS,
  MULTI_CHANGE_IMPACT,
  RESET_FILTER,
  VIEW_POST_BY_AUTHOR_DATA,
  CLOSE_VIEW_POST_BY_AUTHOR_DATA,
  ON_SUCCESS_POST_BY_AUTHOR_DATA,
  ON_FILTER_SUBMIT_ANALYTICS,
  ON_FILTER_SUBMIT_ANALYTICS_SUCCESS,
  ON_FILTER_SELECT,
  GET_FILTER_LIST_ANALYTICS,
  GET_FILTER_ANALYTICS_SUCCESS,
  ON_DELETE_FILTER_ANALYTICS,
  APPLY_SAVED_FILTER,
  DETAIL_LOADER_HANDLER,
  RESET_SELECT_FILTER,
  ON_PRE_LANG_SUBMIT,
  ADD_LANG_FILTER,
  ON_PRE_LOC_SUBMIT,
  ADD_LOC_FILTER,
  ON_LOC_SUBMIT_ANALYTICS,
  ON_EDIT_FILTER_SUBMIT,
  ON_EDIT_FILTER_SUBMIT_SUCCESS,
  DEL_LOC_FILTER,
  DEL_LANG_FILTER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  LOADING_ENABLE,
  ANALYTICS_REQUEST_SUMMARY_TOP,
  ANALYTICS_REQUEST_SUMMARY_TOP_SUCCESS,
  ANALYTICS_REQUEST_SUMMARY_TOP_FAILED,
  CHANGE_ANALYSIS_GRAPH,
  CHANGE_COMPETITIVE_GRAPH,
  SUMMARY_LOAD_ENABLE,
  FOCUS_INPUT,
  COLOR_CODES,
  MORE_COND_CLOSE,
  SEL_ALERT

} from '../constants/ActionTypes';

export const requestProjectAnalyticsGraphDist = (project_id, keyword_id, srcs) => {
  return {
    type: ANALYTICS_REQUEST_PROJECT_GRAPH_DIST,
    payload: {project_id: project_id, keyword_id: keyword_id, activeSrcs: srcs}
  };
};

export const onSuccessProjectAnalyticsGraphDistRequest = (fetchedPro) => {
  return {
    type: ANALYTICS_PROJECT_REQUEST_GRAPH_DIST_SUCCESS,
    payload: fetchedPro
  };
};

export const onFailureProjectAnalyticsGraphDistRequest = () => {
  return {
    type: ANALYTICS_PROJECT_REQUEST_GRAPH_DIST_FAILED
  };
};

export const requestProjectAnalyticsSummary = (project_id, keyword_id, srcs) => {
  return {
    type: ANALYTICS_REQUEST_PROJECT_SUMMARY,
    payload: {project_id: project_id, keyword_id: keyword_id, activeSrcs: srcs}
  };
};

export const onSuccessProjectAnalyticsSummaryRequest = (fetchedPro) => {
  return {
    type: ANALYTICS_PROJECT_REQUEST_SUMMARY_SUCCESS,
    payload: fetchedPro
  };
};


export const onFailureProjectAnalyticsSummaryRequest = () => {
  return {
    type: ANALYTICS_PROJECT_REQUEST_SUMMARY_FAILED
  };
};

export const requestProjectAnalyticsAuthorData = (project_id, keyword_id) => {
  return {
    type: ANALYTICS_REQUEST_PROJECT_AUTHOR,
    payload: {project_id: project_id, keyword_id: keyword_id}
  };
};

export const onSuccessProjectAnalyticsAuthorRequest = (fetchedPro) => {
  return {
    type: ANALYTICS_PROJECT_REQUEST_AUTHOR_SUCCESS,
    payload: fetchedPro
  };
};


export const onFailureProjectAnalyticsAuthorRequest = () => {
  return {
    type: ANALYTICS_PROJECT_REQUEST_AUTHOR_FAILED
  };
};


export const changeActiveSource = (srcs) => {
  return {
    type: CHANGE_ACTIVE_SOURCE,
    payload: {srcs: srcs}
  };
};

export const changeDateRange = (newRange) => {
  return {
    type: ANALYTICS_CHANGE_DATE_RANGE,
    payload: {newRange}
  }
}

export const onChangeAuthorSort = (ikey, value) => {
  return {
    type: ANALYTICS_ON_CHANGE_AUTHOR_SORT,
    payload: {ikey, value}
  }
}

export const onTwitterAuthorRequest = (projectId, keywordIds, dateRange, orderBy, page) => {
  return {
    type: ANALYTICS_REQUEST_TWITTER_AUTHOR,
    payload: { projectId, keywordIds, dateRange, orderBy, page }
  };
}

export const onSuccessTwitterAuthorRequest = (response) => {
  return {
    type: ON_SUCCESS_ANALYTICS_REQUEST_TWITTER_AUTHOR,
    payload: response
  }
}

export const onRedditAuthorRequest = (projectId, keywordIds, dateRange, orderBy, page) => {
  return {
    type: ANALYTICS_REQUEST_REDDIT_AUTHOR,
    payload: { projectId, keywordIds, dateRange, orderBy, page }
  };
}

export const onSuccessRedditAuthorRequest = (response) => {
  return {
    type: ON_SUCCESS_ANALYTICS_REQUEST_REDDIT_AUTHOR,
    payload: response
  }
}

export const onInstaAuthorRequest = (projectId, keywordIds, dateRange, orderBy, page) => {
  return {
    type: ANALYTICS_REQUEST_INSTA_AUTHOR,
    payload: { projectId, keywordIds, dateRange, orderBy, page }
  };
}

export const onSuccessInstaAuthorRequest = (response) => {
  return {
    type: ON_SUCCESS_ANALYTICS_REQUEST_INSTA_AUTHOR,
    payload: response
  }
}

export const onWebAuthorRequest = (projectId, keywordIds, dateRange, orderBy, page) => {
  return {
    type: ANALYTICS_REQUEST_WEB_AUTHOR,
    payload: { projectId, keywordIds, dateRange, orderBy, page }
  };
}

export const onSuccessWebAuthorRequest = (response) => {
  return {
    type: ON_SUCCESS_ANALYTICS_REQUEST_WEB_AUTHOR,
    payload: response
  }
}

export const onHumanAuthorRequest = (projectId, keywordIds, dateRange, orderBy, page) => {
  return {
    type: ANALYTICS_REQUEST_HUMAN_AUTHOR,
    payload: { projectId, keywordIds, dateRange, orderBy, page }
  };
}

export const onSuccessHumanAuthorRequest = (response) => {
  return {
    type: ON_SUCCESS_ANALYTICS_REQUEST_HUMAN_AUTHOR,
    payload: response
  }
}

export const onYoutubeAuthorRequest = (projectId, keywordIds, dateRange, orderBy, page) => {
  return {
    type: ANALYTICS_REQUEST_YOUTUBE_AUTHOR,
    payload: { projectId, keywordIds, dateRange, orderBy, page }
  };
}

export const onSuccessYoutubeAuthorRequest = (response) => {
  return {
    type: ON_SUCCESS_ANALYTICS_REQUEST_YOUTUBE_AUTHOR,
    payload: response
  }
}

export const setKeywordName = (kw) => {
  return {
    type: ANALYTICS_ON_CHANGE_KEYWORD_SELECT,
    payload: {kw}
  }
}

export const getKeywordNames = (project_id, kw_id) => {
  return {
    type: GET_KEYWORD_NAMES,
    payload: {project_id: project_id, kw_id: kw_id}
  };
};

export const getKeywordNamesOnSuccess = (names, selected_kw) => {
  return {
    type: GET_KEYWORD_NAMES_ON_SUCCESS,
    payload: {names, selected_kw}
  };
};

export const getKeywordNamesOnFailure = () => {
  return {
    type: GET_KEYWORD_NAMES_ON_FAILURE
  };
};

export const viewPostByAuthor = (author, ikey, projectId, keywordIds, dateRange, currentPage, yt_name = '') => {
  return {
    type: VIEW_POST_BY_AUTHOR,
    payload: {
      author, ikey, projectId, keywordIds, dateRange, currentPage, yt_name
    }
  }
}

export const closePostByAuthor = () => {
  return {
    type: CLOSE_VIEW_POST_BY_AUTHOR
  }
}

export const onSuccessPostByAuthor = (response) => {
  return {
    type: ON_SUCCESS_POST_BY_AUTHOR,
    payload: response
  }
};

export const requestProjectAnalyticsLangDist = (project_id, keyword_id, srcs) => {
  return {
    type: ANALYTICS_REQUEST_PROJECT_LANG_DIST,
    payload: {project_id: project_id, keyword_id: keyword_id, activeSrcs: srcs}
  };
};

export const onSuccessProjectAnalyticsLangDistRequest = (fetchedPro) => {
  return {
    type: ANALYTICS_PROJECT_REQUEST_LANG_DIST_SUCCESS,
    payload: fetchedPro
  };
};

export const onFailureProjectAnalyticsLangDistRequest = () => {
  return {
    type: ANALYTICS_PROJECT_REQUEST_LANG_DIST_FAILED
  };
};

export const requestProjectAnalyticsWordCount = (project_id, keyword_id) => {
  return {
    type: ANALYTICS_REQUEST_PROJECT_WORD_COUNT,
    payload: {project_id: project_id, keyword_id: keyword_id}
  };
};

export const onSuccessProjectAnalyticsWordCountRequest = (fetchedPro) => {
  return {
    type: ANALYTICS_PROJECT_REQUEST_WORD_COUNT_SUCCESS,
    payload: fetchedPro
  };
};

export const onFailureProjectAnalyticsWordCountRequest = () => {
  return {
    type: ANALYTICS_PROJECT_REQUEST_WORD_COUNT_FAILED
  };
};




export const requestAnalyticsSummaryGraphs = (keyword_ids, srcs, senti, langList, locList) => {
  return {
    type: ANALYTICS_REQUEST_SUMMARY_GRAPH,
    payload: {keyword_ids: keyword_ids, activeSrcs: srcs, senti: senti, langList: langList, locList: locList}
  };
};

export const onSuccessAnalyticsSummaryGraphsRequest = (fetchedRes) => {
  return {
    type: ANALYTICS_REQUEST_SUMMARY_GRAPH_SUCCESS,
    payload: fetchedRes
  };
};

export const onFailureAnalyticsSummaryGraphsRequest = () => {
  return {
    type: ANALYTICS_REQUEST_SUMMARY_GRAPH_FAILED
  };
};

export const requestAnalyticsLanguageGraphs = (keyword_ids, srcs, senti, langList, locList) => {
  return {
    type: ANALYTICS_REQUEST_LANGUAGE_GRAPH,
    payload: {keyword_ids: keyword_ids, activeSrcs: srcs, senti: senti, langList: langList, locList: locList}
  };
};

export const onSuccessAnalyticsLanguageGraphsRequest = (fetchedRes) => {
  return {
    type: ANALYTICS_REQUEST_LANGUAGE_GRAPH_SUCCESS,
    payload: fetchedRes
  };
};

export const onFailureAnalyticsLanguageGraphsRequest = () => {
  return {
    type: ANALYTICS_REQUEST_LANGUAGE_GRAPH_FAILED
  };
};

export const requestAnalyticsLocationGraphs = (keyword_ids, srcs, senti, langList, locList) => {
  return {
    type: ANALYTICS_REQUEST_LOCATION_GRAPH,
    payload: {keyword_ids: keyword_ids, activeSrcs: srcs, senti: senti, langList: langList, locList: locList}
  };
};

export const onSuccessAnalyticsLocationGraphsRequest = (fetchedRes) => {
  return {
    type: ANALYTICS_REQUEST_LOCATION_GRAPH_SUCCESS,
    payload: fetchedRes
  };
};

export const onFailureAnalyticsLocationGraphsRequest = () => {
  return {
    type: ANALYTICS_REQUEST_LOCATION_GRAPH_FAILED
  };
};

export const requestAnalyticsWordCount = (keyword_ids, srcs, senti, langList, locList) => {
  return {
    type: ANALYTICS_REQUEST_WORD_COUNT,
    payload: {keyword_ids: keyword_ids, activeSrcs: srcs, senti: senti, langList: langList, locList: locList}
  };
};

export const onSuccessAnalyticsWordCountRequest = (fetchedRes) => {
  return {
    type: ANALYTICS_REQUEST_WORD_COUNT_SUCCESS,
    payload: fetchedRes
  };
};

export const onFailureAnalyticsWordCountRequest = () => {
  return {
    type: ANALYTICS_REQUEST_WORD_COUNT_FAILED
  };
};


export const requestAnalyticsTwitterAuthorData = (keyword_ids, srcs, page, order, orderBy, senti, langList, locList) => {
  return {
    type: ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA,
    payload: {keyword_ids: keyword_ids, activeSrcs: srcs, page: page, order: order, orderBy: orderBy, senti: senti, langList: langList, locList: locList}
  };
};

export const onSuccessAnalyticsTwitterAuthorDataRequest = (fetchedRes) => {
  return {
    type: ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA_SUCCESS,
    payload: fetchedRes
  };
};

export const onFailureAnalyticsTwitterAuthorDataRequest = () => {
  return {
    type: ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA_FAILED
  };
};

export const requestAnalyticsYoutubeAuthorData = (keyword_ids, srcs, page, order, orderBy, senti, langList, locList) => {
  return {
    type: ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA,
    payload: {keyword_ids: keyword_ids, activeSrcs: srcs, page: page, order: order, orderBy: orderBy, senti: senti, langList: langList, locList: locList}
  };
};

export const onSuccessAnalyticsYoutubeAuthorDataRequest = (fetchedRes) => {
  return {
    type: ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA_SUCCESS,
    payload: fetchedRes
  };
};

export const onFailureAnalyticsYoutubeAuthorDataRequest = () => {
  return {
    type: ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA_FAILED
  };
};

export const requestAnalyticsInstaAuthorData = (keyword_ids, srcs, page, order, orderBy, senti, langList, locList) => {
  return {
    type: ANALYTICS_REQUEST_INSTA_AUTHOR_DATA,
    payload: {keyword_ids: keyword_ids, activeSrcs: srcs, page: page, order: order, orderBy: orderBy, senti: senti, langList: langList, locList: locList}
  };
};

export const onSuccessAnalyticsInstaAuthorDataRequest = (fetchedRes) => {
  return {
    type: ANALYTICS_REQUEST_INSTA_AUTHOR_DATA_SUCCESS,
    payload: fetchedRes
  };
};

export const onFailureAnalyticsInstaAuthorDataRequest = () => {
  return {
    type: ANALYTICS_REQUEST_INSTA_AUTHOR_DATA_FAILED
  };
};


export const requestAnalyticsRedditAuthorData = (keyword_ids, srcs, page, order, orderBy, senti, langList, locList) => {
  return {
    type: ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA,
    payload: {keyword_ids: keyword_ids, activeSrcs: srcs, page: page, order: order, orderBy: orderBy, senti: senti, langList: langList, locList: locList}
  };
};

export const onSuccessAnalyticsRedditAuthorDataRequest = (fetchedRes) => {
  return {
    type: ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA_SUCCESS,
    payload: fetchedRes
  };
};

export const onFailureAnalyticsRedditAuthorDataRequest = () => {
  return {
    type: ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA_FAILED
  };
};


export const requestAnalyticsWebAuthorData = (keyword_ids, srcs, page, order, orderBy, senti, langList, locList) => {
  return {
    type: ANALYTICS_REQUEST_WEB_AUTHOR_DATA,
    payload: {keyword_ids: keyword_ids, activeSrcs: srcs, page: page, order: order, orderBy: orderBy, senti: senti, langList: langList, locList: locList}
  };
};

export const onSuccessAnalyticsWebAuthorDataRequest = (fetchedRes) => {
  return {
    type: ANALYTICS_REQUEST_WEB_AUTHOR_DATA_SUCCESS,
    payload: fetchedRes
  };
};

export const onFailureAnalyticsWebAuthorDataRequest = () => {
  return {
    type: ANALYTICS_REQUEST_WEB_AUTHOR_DATA_FAILED
  };
};

export const requestAnalyticsHumanAuthorData = (keyword_ids, srcs, page, order, orderBy, senti, langList, locList) => {
  return {
    type: ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA,
    payload: {keyword_ids: keyword_ids, activeSrcs: srcs, page: page, order: order, orderBy: orderBy, senti: senti, langList: langList, locList: locList}
  };
};

export const onSuccessAnalyticsHumanAuthorDataRequest = (fetchedRes) => {
  return {
    type: ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA_SUCCESS,
    payload: fetchedRes
  };
};

export const onFailureAnalyticsHumanAuthorDataRequest = () => {
  return {
    type: ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA_FAILED
  };
};

export const requestUserProjectKwList = (all_kw) => {
  return {
    type: ANALYTICS_USER_PROJECT_KW_LIST,
    payload: {all_kw: all_kw}
  };
};

export const onSuccessUserProjectKwListRequest = (fetchedRes) => {
  return {
    type: ANALYTICS_USER_PROJECT_KW_LIST_SUCCESS,
    payload: fetchedRes
  };
};

export const onFailureUserProjectKwListRequest = () => {
  return {
    type: ANALYTICS_USER_PROJECT_KW_LIST_FAILED
  };
};

export const setKeywordNameNew = (kw) => {
  return {
    type: ANALYTICS_ON_CHANGE_KEYWORD_SELECT_NEW,
    payload: kw
  }
}

export const changeDateRangeFilter = (newRange) => {
  return {
    type: ANALYTICS_CHANGE_DATE_RANGE_FILTER,
    payload: {newRange}
  }
}

export const setSentiState = (impact, checked) => {
  return {
    type: SENTI_ON_CHANGE_IMPACT,
    payload: {impact, checked}
  }
}

export const onAciveSrcChange = (src, checked) => {
  return {
    type: ACTIVE_SRC_NEW,
    payload: {src, checked}
  };
};

export const langSubmit = (data, iterate) => {
  return {
    type: ON_LANG_SUBMIT_ANALYTICS,
    payload: {data, iterate}
  }
}

export const setMultiChange = (data) => {
  return {
    type: MULTI_CHANGE_IMPACT,
    payload: {data}
  }
}

export const viewPostByAuthorData = (author, ikey, keywordIds, dateRange, currentPage, yt_name = '') => {
  return {
    type: VIEW_POST_BY_AUTHOR_DATA,
    payload: {
      author, ikey, keywordIds, dateRange, currentPage, yt_name
    }
  }
}

export const closePostByAuthorData = () => {
  return {
    type: CLOSE_VIEW_POST_BY_AUTHOR_DATA
  }
}

export const onSuccessPostByAuthorData = (response) => {
  return {
    type: ON_SUCCESS_POST_BY_AUTHOR_DATA,
    payload: response
  }
};

export const resetFilter = () => {
  return {
    type: RESET_FILTER
  }
}

export const filterSubmit = (sentiPos, sentiNeg, sentiNet, sentiUnk, filterName, langList, locList, activeSrcFilter, reach) => {
  return {
    type: ON_FILTER_SUBMIT_ANALYTICS,
    payload: {sentiPos, sentiNeg, sentiNet, sentiUnk, filterName, langList, locList, activeSrcFilter, reach }
  }
}

export const onFilterSelect = () => {
  return {
    type: ON_FILTER_SELECT
  };
};

export const filterSubmitSuccess = () => {
  return {
    type: ON_FILTER_SUBMIT_ANALYTICS_SUCCESS
  };
};

export const getfilterList = () => {
  return {
    type: GET_FILTER_LIST_ANALYTICS
  }
}

export const getFilterSuccess = (list) => {
  return {
    type: GET_FILTER_ANALYTICS_SUCCESS,
    payload: list
  }
}

export const onDeleteFilter = (filter_id) => {
  return {
    type: ON_DELETE_FILTER_ANALYTICS,
    payload: {
      filter_id
    }
  }
};

export const applySavedFilter = (info, name, added, id) => {
  return {
    type: APPLY_SAVED_FILTER,
    payload: {
      info, name, added, id
    }
  }
};

export const detailLoaderHandler = () => {
  return {
    type: DETAIL_LOADER_HANDLER
  };
};

export const loadingEnable = () => {
  return {
    type: LOADING_ENABLE
  };
};



export const resetSelectFilter = () => {
  return {
    type: RESET_SELECT_FILTER
  };
};

export const prelangSubmit = (data, iterate) => {
  return {
    type: ON_PRE_LANG_SUBMIT,
    payload: {data, iterate}
  }
}

export const addLangFilter = () => {
  return {
    type: ADD_LANG_FILTER
  }
}

export const prelocSubmit = (data, iterate) => {
  return {
    type: ON_PRE_LOC_SUBMIT,
    payload: {data, iterate}
  }
}

export const addLocFilter = () => {
  return {
    type: ADD_LOC_FILTER
  }
}

export const locSubmit = (data, iterate) => {
  return {
    type: ON_LOC_SUBMIT_ANALYTICS,
    payload: {data, iterate}
  }
}

export const editfilterSubmit = (sentiPos, sentiNeg, sentiNet, sentiUnk, langList, locList, activeSrcFilter, filter_id) => {
  return {
    type: ON_EDIT_FILTER_SUBMIT,
    payload: {sentiPos, sentiNeg, sentiNet, sentiUnk, langList, locList, activeSrcFilter, filter_id }
  }
}


export const editfilterSubmitSuccess = () => {
  return {
    type: ON_EDIT_FILTER_SUBMIT_SUCCESS
  };
};

export const delLangFilter = (iterate) => {
  return {
    type: DEL_LANG_FILTER,
    payload: { iterate }
  }
}

export const delLocFilter = (iterate) => {
  return {
    type: DEL_LOC_FILTER,
    payload: { iterate }

  }
}

export const showMailMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const hideMailMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};



export const requestAnalyticsSummaryTopData = (keyword_ids, srcs, senti, langList, locList) => {
  return {
    type: ANALYTICS_REQUEST_SUMMARY_TOP,
    payload: {keyword_ids: keyword_ids, activeSrcs: srcs, senti: senti, langList: langList, locList: locList}
  };
};

export const onSuccessAnalyticsSummaryTopDataRequest = (fetchedRes) => {
  return {
    type: ANALYTICS_REQUEST_SUMMARY_TOP_SUCCESS,
    payload: fetchedRes
  };
};

export const onFailureAnalyticsSummaryTopDataRequest = () => {
  return {
    type: ANALYTICS_REQUEST_SUMMARY_TOP_FAILED
  };
};

export const changeAnalysisGraph = (value) => {
  return {
    type: CHANGE_ANALYSIS_GRAPH,
    payload: value
  };
}

export const changeCompetitiveGraph = () => {
  return {
    type: CHANGE_COMPETITIVE_GRAPH
  };
}

export const summaryLoadEnable = (value) => {
  return {
    type: SUMMARY_LOAD_ENABLE,
    payload: value
  };
}

export const focusInput = (value) => {
  return {
    type: FOCUS_INPUT,
    payload: value
  };
}

export const colorCodes = (sel_color, sel_color_kw) => {
  return {
    type: COLOR_CODES,
    payload: {sel_color, sel_color_kw}
  };
}

export const moreCondClose = () => {
  return {
    type: MORE_COND_CLOSE
  };
}
export const selAlert = (value) => {
  return {
    type: SEL_ALERT,
    payload: value
  };
}





