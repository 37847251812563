import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE,
  MINI_DRAWER,
  COLLAPSED_DRAWER,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  THEME_COLOR,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
  WINDOW_WIDTH,
  FETCH_USER_SUCCESS,
  FETCH_USER_REQUEST,
  SEND_INVITE_REQUEST,
  SEND_INVITE_REQUEST_SUCCESS,
  SHOW_INVITE_REQUEST,
  SHOW_INVITE_SUCCESS,
  QUOTA_STAT_SUCCESS,
  SHOW_INVITE_ERROR,
  SHOW_SUPPORT_ERROR,
  SEND_SUPPORT_REQUEST,
  SEND_SUPPORT_REQUEST_SUCCESS,
  RESET_SUCCESS,
  WHITE_LABEL_INFO,
  WHITE_LABEL_INFO_SUCCESS
} from 'constants/ActionTypes';
import {DARK_DEEP_ORANGE, DEEP_PURPLE, AMBER, BLUE, CYAN, GREEN, INDIGO, PINK} from 'constants/ThemeColors';
const rltLocale = ['ar'];
const lang_info = {
  'en': {languageId: 'english', locale: 'en', name: 'English', icon: 'us'},
  'fr': {languageId: 'french', locale: 'fr', name: 'French', icon: 'fr'},
  'ar': {languageId: 'arabic', locale: 'ar', name: 'Arabic', icon: 'ar'},
  'it': {languageId: 'italian', locale: 'it', name: 'Italian', icon: 'it'},
  'zh': {languageId: 'chinese', locale: 'zh', name: 'Chinese', icon: 'zh'},
  'es': {languageId: 'spanish', locale: 'es', name: 'Spanish', icon: 'es'},
};
const initialSettings = {
  navCollapsed: false,
  drawerType: (window.innerWidth > 575) ? MINI_DRAWER : COLLAPSED_DRAWER,
  themeColor: DARK_DEEP_ORANGE,
  darkTheme: false,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  user: {
    name: 'Secured User',
    email: 'example@example.com',
    avatar: 'https://via.placeholder.com/150x150',
    joined: '',
    id: 0
  },
  horizontalNavPosition: INSIDE_THE_HEADER,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  inviteDialogLoading: false,
  inviteResponse: {
    'error': -1,
    'errMsg': ''
  },
  inviteLoading: false,
  inviteError: false,
  invites: [],
  quotaStats: {
    'quota_used': 0,
    'quota_reached': 0,
    'quota_limit': 0,
    'display_msg':0
  },
  supportError:false,
  supportDialogLoading: false,
  supportResponse: {
    'error': -1,
    'errMsg': ''
  },
  alertMessage: '',
  showMessage: false,
  supportSuccessMessage: '',
  wl_info: {}
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed
      };
    case DRAWER_TYPE:
      return {
        ...state,
        drawerType: action.drawerType
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      };
    case THEME_COLOR:
      return {
        ...state,
        darkTheme: false,
        themeColor: action.color
      };
    case DARK_THEME:
      return {
        ...state,
        darkTheme: !state.darkTheme
      };
    case SWITCH_LANGUAGE:

      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale)

      };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL

      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload
      };


    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload
      };

    case FETCH_USER_REQUEST: {
      return state;
    }

    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        user: {
          name: action.payload.name,
          email: action.payload.email,
          avatar: action.payload.avatar,
          joined: action.payload.joined,
          id: action.payload.id
        },
        locale: {
          languageId: lang_info[action.payload.lang]['languageId'] !== undefined ? lang_info[action.payload.lang]['languageId'] : 'english',
          locale: lang_info[action.payload.lang] !== undefined ? action.payload.lang : 'en',
          name: lang_info[action.payload.lang]['name'] !== undefined ? lang_info[action.payload.lang]['name'] : 'English',
          icon: lang_info[action.payload.lang] !== undefined ? action.payload.lang : 'us'
        }
      }
    }

    case SEND_INVITE_REQUEST: {

      let l = state.invites;
      l.codes.push({invited_name: action.payload.name, invited_email: action.payload.email});
      return {
        ...state,
        inviteDialogLoading: true,
        inviteError : false,
        invites:l
      }
    }

    case SEND_INVITE_REQUEST_SUCCESS: {
      return {
        ...state,
        inviteDialogLoading: false,
        inviteResponse: action.payload.response
      }
    }

    case SHOW_INVITE_REQUEST: {
      return {
        ...state,
        inviteLoading : true,
        inviteError : false,
        inviteResponse: {
          'error': -1,
          'errMsg': ''
        }
      }
    }

    case SHOW_INVITE_SUCCESS: {
      return {
        ...state,
        invites : action.payload,
        inviteLoading : false
      };
    }

    case QUOTA_STAT_SUCCESS: {
      let qStats = action.payload;
      return {
        ...state,
        quotaStats: qStats
      }
    }

    case SHOW_INVITE_ERROR: {
      return {
        ...state,
        inviteError : true
      };
    }

    case SHOW_SUPPORT_ERROR: {
      return {
        ...state,
        supportError : true,
        supportSuccessMessage: ''
      };
    }

    case SEND_SUPPORT_REQUEST: {
      return {
        ...state,
        supportDialogLoading: true,
        supportError : false,
        supportSuccessMessage: '',
        data: {
          issueDisc: action.payload.issueDisc,
          images: action.payload.images,
          imagenames: action.payload.imagenames
        }
      }
    }

    case SEND_SUPPORT_REQUEST_SUCCESS: {
      return {
        ...state,
        supportDialogLoading: false,
        supportResponse: action.payload.response,
        alertMessage: action.payload.response.message,
        showMessage: true,
        supportSuccessMessage: action.payload.response.message
      }
    }

    case RESET_SUCCESS: {
      return {
        ...state,
        supportSuccessMessage: '',
        supportError : false
      };
    }

    case WHITE_LABEL_INFO: {
      return {
        ...state,
        wl_info: {}
      }
    }

    case WHITE_LABEL_INFO_SUCCESS: {
      let theme_color = DARK_DEEP_ORANGE;
      if (action.payload.theme === 'blue')
        theme_color = BLUE;
      else if (action.payload.theme === 'amber')
        theme_color = AMBER;
      else if (action.payload.theme === 'cyan')
        theme_color = CYAN;
      else if (action.payload.theme === 'green')
        theme_color = GREEN;
      else if (action.payload.theme === 'indigo')
        theme_color = INDIGO;
      else if (action.payload.theme === 'pink')
        theme_color = PINK;
      else
        theme_color = DARK_DEEP_ORANGE;
      document.body.classList.remove(state.themeColor);
      document.body.classList.add(theme_color);
      return {
        ...state,
        wl_info: action.payload,
        darkTheme: (parseInt(action.payload.dark_theme) === 1) ? true: false,
        themeColor: theme_color
      };
    }

    default:
      return state;
  }
};

export default settings;
