import {
  FETCH_ALL_MAIL,
  FETCH_ALL_MAIL_SUCCESS,
  GET_ALL_MAIL,
  GET_IMPORTANT_MAIL,
  GET_NAV_FILTERS,
  GET_NAV_FOLDER,
  GET_NAV_LABELS,
  GET_READ_MAIL,
  GET_STARRED_MAIL,
  GET_UNIMPORTANT_MAIL,
  GET_UNREAD_MAIL,
  GET_UNSELECTED_ALL_MAIL,
  GET_UNSTARRED_MAIL,
  HANDLE_REQUEST_CLOSE,
  ON_ALL_MAIL_SELECT,
  ON_COMPOSE_MAIL,
  ON_DELETE_MAIL,
  ON_BLOCK_MAIL,
  ON_FOLDER_MENU_ITEM_SELECT,
  ON_FOLDER_SELECT,
  ON_HIDE_LOADER,
  ON_IMPORTANT_SELECT,
  ON_LABEL_MENU_ITEM_SELECT,
  ON_LABEL_SELECT,
  ON_MAIL_CHECKED,
  ON_MAIL_SELECT,
  ON_MAIL_SEND,
  ON_OPTION_MENU_SELECT,
  ON_START_SELECT,
  ON_TOGGLE_DRAWER,
  SEARCH_MAIL,
  SET_CURRENT_MAIL_NULL,
  SHOW_MESSAGE,
  UPDATE_SEARCH,
  ON_TRASH_CLICK,
  ON_REPLY_CLICK,
  ON_SPAM_CLICK,
  ON_NOTES_CLICK,
  FETCH_NOTES_SUCCESS,
  ACTIVE_SRC,
  ON_NOTES_SUBMIT,
  ON_DATE_MENU_SELECT,
  ON_DATE_RANGE_CHANGE,
  FETCH_PROJECT_TAGS_REQUEST,
  FETCH_PROJECT_TAGS_SUCCESS,
  UPDATE_LABEL_INFO,
  ADD_LABEL_INFO,
  DELETE_LABEL,
  LABEL_ACTION_PRELOADER_SHOW,
  ADD_LABEL_INFO_SUCCESS,
  DELETE_LABEL_SUCCESS,
  ON_SPAM_CLICK_AUTHOR,
  FOLDER_STAT_SUCCESS,
  ON_READ_CLICK,
  ON_NEW_KEYWORD_LOAD,
  ON_FILTER_TOGGLE_DRAWER,
  ON_USER_FETCHED_SUCCESS,
  SHOW_NEW_POSTS,
  ON_TAG_DELETE,
  ADD_LABEL_ALREADY,
  ADD_LABEL_ALREADY_SUCCESS,
  ON_CHANGE_ADD_LABEL_TITLE,
  ON_APPLY_STARRED,
  TAG_FILTER_SELECTED,
  ON_DOWNLOAD_CLICK,
  ON_DOWNLOAD_SUCCESS,
  ON_APPLY_LABEL,
  DETAIL_LOADER_HANDLER,
  ON_MOUSE_OVER,
  ON_MOUSE_OUT,
  CALL_LAZY_LOAD,
  SENTI_ON_CHANGE_IMPACT,
  ON_SENTI_CHANGE,
  ON_TITLE_SUBMIT,
  ON_WORD_SUBMIT,
  ON_WEB_SUBMIT,
  ON_TITLE_DELETE,
  ON_WORD_DELETE,
  ON_WEB_DELETE,
  RESET_FILTER,
  ON_FILTER_SUBMIT,
  ON_FILTER_SUBMIT_SUCCESS,
  ON_FILTER_SELECT,
  GET_FILTER_LIST,
  GET_FILTER_SUCCESS,
  ON_DELETE_FILTER,
  APPLY_SAVED_FILTER,
  ON_LANG_SUBMIT,
  ON_LANG_DELETE,
  ON_LANG_CHANGE,
  ON_LOC_SUBMIT,
  ON_LOC_DELETE,
  ON_LOC_CHANGE,
  CHANGE_DATE_RANGE_CALENDAR,
  ON_SORT_SUBMIT,
  ON_SORT_DELETE,
  SAVE_LAST_SELECTED_DATE,
  RESELECT_LAST_SELECTED_DATE,
  GET_LANG
} from 'constants/ActionTypes';
import mails from 'app/routes/mail/data/mails';
import moment from 'moment';
import { post } from 'app/routes/dashboard/routes/data';
import IntlMessages from 'util/IntlMessages';
import langText from 'app/routes/mail/data/langText'

const INIT_STATE = {
  searchMail: '',
  noContentFoundMessage: 'No post found in selected folder',
  alertMessage: '',
  showMessage: false,
  drawerState: false,
  anchorEl: null,
  anchorEle: false,
  mail_page: {
    total: 0,
    per_page: 10,
    last_page: 0,
    current_page: 1,
    next_page: 2
  },
  allMail: mails,
  optionName: 'None',
  loader: true,
  currentMail: null,
  selectedMails: 0,
  selectedFolder: 0,
  composeMail: false,
  labelMenuState: false,
  folderMenuState: false,
  optionMenuState: false,
  dateMenuState: false,
  filterMenuState: false,
  selectedDate: {
    to: moment().format('YYYY-MM-DD'),
    from: moment().subtract(1825,'d').format('YYYY-MM-DD'),
    label: "All Time",
    id: 6
  },
  lastSelectedDateId: 6,
  dateOptions: [
    {
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(7,'d').format('YYYY-MM-DD'),
      label: "Last 7 Days",
      id: 1
    },
    {
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(15,'d').format('YYYY-MM-DD'),
      label: "Last 15 Days",
      id: 2
    },
    /*,
    {
      to: moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'),
      from: moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
      label: "Last week",
      id: 3
    },
    {
      to: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
      label: "Last Month",
      id: 4
    },
    */
    {
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(30,'d').format('YYYY-MM-DD'),
      label: "Last 30 Days",
      id: 4
    },
    {
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(180,'d').format('YYYY-MM-DD'),
      label: "Last 180 Days",
      id: 5
    },
    {
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(1825,'d').format('YYYY-MM-DD'),
      label: "All Time",
      id: 6
    },
    {
      to: moment().subtract(1,'d').format('YYYY-MM-DD'),
      from: moment().subtract(1825,'d').format('YYYY-MM-DD'),
      label: "Up to Yesterday",
      id: 7
    },
    {
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(30,'d').format('YYYY-MM-DD'),
      label: "Custom Date",
      id: 8
    }
  ],
  dateOptionList: {
    1: {
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(7,'d').format('YYYY-MM-DD'),
      label: "Last 7 Days",
      id: 1
    },
    2: {
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(15,'d').format('YYYY-MM-DD'),
      label: "Last 15 Days",
      id: 2
    },
    /*,
    {
      to: moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'),
      from: moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
      label: "Last week",
      id: 3
    },
    {
      to: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
      label: "Last Month",
      id: 4
    },
    */
    4: {
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(30,'d').format('YYYY-MM-DD'),
      label: "Last 30 Days",
      id: 4
    },
    5:{
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(180,'d').format('YYYY-MM-DD'),
      label: "Last 180 Days",
      id: 5
    },
    6:{
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(1825,'d').format('YYYY-MM-DD'),
      label: "All Time",
      id: 6
    },
    7:{
      to: moment().subtract(1,'d').format('YYYY-MM-DD'),
      from: moment().subtract(1825,'d').format('YYYY-MM-DD'),
      label: "Up to Yesterday",
      id: 7
    },
    8: {
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(30,'d').format('YYYY-MM-DD'),
      label: "Custom Date",
      id: 8
    }
  },
  projectLabels: [],
  kwName : '',
  kwBooleanList : [],
  kwBoolLoad: false,
  projectLabelsLoading: false,
  notes : [],
  notesLoading: false,
  donutCount : [],
  donutTotal : 0,
  folderMails: [], //only for prod
  // folderMails: mails.filter(mail => mail.folder === 0),
  activeSrc: {'twitter':1, 'web':1, 'facebook':1, 'youtube': 1, 'quora' : 1, 'reddit': 1, 'forum' : 1, 'instagram': 1, 'news': 1, 'podcast': 1, 'tv': 1, 'radio': 1, 'linkedin': 1, 'lqp': 0, 'all':1},
  labelDialogPreloader: false,
  labelDialogAlready: false,
  postStats: [
    {
      id: 1, data:[],
    },
    {
      id: 2, data:[],
    },
    {
      id: 3, data:[],
    },
    {
      id: 4, data:[],
    },
    {
      id: 5, data:[],
    },
    {
      id: 6, data:[],
    },
    {
      id: 7, data:[],
    },
    {
      id: 8, data:[],
    },
    {
      id: 9, data:[],
    },
    {
      id: 15, data:[],
    },
    {
      id: 19, data:[],
    },
    {
      id: 20, data:[],
    },
    {
      id: 21, data:[],
    },
    {
      id: 11, data:[],
    }
  ],
  folderStats: {inbox: -1, unread: -1, replied: -1, blocked: -1, trash:-1, unlink: -1, leads: -1, quest: -1, total: -1},
  filterDrawerState: false,
  user : {
    name: 'Secured User',
    email: 'example@example.com',
    avatar: 'https://via.placeholder.com/150x150',
    id: 0
  },
  quotaStats: {quota_used:0},
  showNewPosts : -1,
  runnowRes: [],
  starredFilter: false,
  tagFilter: false,
  downloader: false,
  quotaStatus: 0,
  detailLoader: true,
  changeCurPostidHover:0,
  lazyLoad: false,
  moduleAct: {"tw":"0","web":"0","fb":"0","yt":"0","qr":"0","rt":"0","fr":"0","ig":"0","nw":"0","pd":"0","rd":"0","tv":"0","ln":"0"},
  sentiPos: false,
  sentiNeg: false,
  sentiNet: false,
  sentiUnk: false,
  trueTotal: 0,
  checkReset: true,
  titleList: [],
  webList: [],
  wordList: [],
  filterList : [],
  filterListLoad: true,
  langList: [],
  locList: [],
  sortList: [],
  loadMore: true,
  localeVal: 'en',
  disable_page: 0,
  old_disable: 0,
  last_post_id: 0,
  show_filter_save: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_MAIL: {
      let mails = state.allMail.map((mail) => {
        return {...mail, selected:true}
      });
      return {
        ...state,
        selectedMails: mails.length,
        optionName: 'All',
        allMail: mails,
        folderMails: mails
      }
    }
    case FETCH_ALL_MAIL: {
      return {...state, loader: state.lazyLoad ? false : true, currentMail: state.lazyLoad ? state.currentMail : null, selectedFolder :  /*state.lazyLoad ?*/ state.selectedFolder/*: 0*/
        //, starredFilter: false, tagFilter: false
      }
    }
    case FETCH_ALL_MAIL_SUCCESS: {
      let newActiveSrc = state.activeSrc;
      if (action.payload.activeSrc.all === undefined) {
        if (action.payload.activeSrc.twitter !== undefined) {
          newActiveSrc = action.payload.activeSrc;
        }
      }
      let disable_old = 0;
      let newPost = action.payload.data.map((post) => {

        console.log('cc');
        console.log(post);
        if (post['src'] !== undefined && post['src'] === 17) {
          post['src'] = 2;
        }

        console.log('cc1');
        console.log(post);

        if (post['post']['src'] !== undefined && post['post']['src'] === 17) {
          post['post']['src'] = 2;
        }
        console.log('cc2');
        console.log(post);

        if (post['old_disable'] === undefined) {
          post['old_disable'] = 0;
          disable_old = 0;
        }
        else {
          disable_old = post['old_disable'];
        }
        return {
          ...post,
          selected: false,
          labels: post.label_info.map(label => label.tag_id)};
      });
      let tempSelectedMails = 0;
      state.allMail.map((mail) => {
        if (mail.selected) {
          tempSelectedMails++;
        }
      });
      console.log(newPost);
      //action.payload.moduleAct.lqp = "1";
      return {
        ...state,
        loader: false,
        selectedMails: tempSelectedMails,
        mail_page: {
          total: action.payload.total,
          per_page: (action.payload.per_page === undefined) ? 10 : parseInt(action.payload.per_page),
          last_page: action.payload.last_page,
          current_page: action.payload.current_page,
          next_page: action.payload.current_page + 1
        },
        postStats: action.payload.stats,
        allMail: state.lazyLoad ? state.allMail.concat(newPost):newPost,
        folderMails: state.lazyLoad ? state.folderMails.concat(newPost): newPost,
        // folderMails: action.payload.data.map((mail) => {
        //   return {
        //     ...mail,
        //     selected: false,
        //     labels: mail.label_info.map(label => label.tag_id)
        //   };
        // }),// .filter(mail => (mail.folder === 0 || mail.folder === 1 || mail.folder === 2))
        donutCount: action.payload.srcStats,
        //donutTotal : action.payload.donutTotal,
        donutTotal : 0,
        activeSrc: newActiveSrc,
        showNewPosts : 0,
        runnowRes: action.payload.runnowRes,
        quotaStatus: action.payload.quotaStatus,
        moduleAct: action.payload.moduleAct,
        loadMore: action.payload.data.length === 0 ? false : true,
        old_disable: action.payload.old_disable,
        disable_page: action.payload.disable_page,
        last_post_id: action.payload.last_post_id

      }
    }
    case GET_IMPORTANT_MAIL: {
      let selectedMail = 0;
      let mails = state.allMail.filter(mail => mail.folder === state.selectedFolder);
      mails = mails.map((mail) => {
        if (mail.important) {
          selectedMail++;
          return {...mail, selected: true};
        }
        return {...mail, selected: false}
      });
      return {
        ...state,
        selectedMails: selectedMail,
        optionName: 'Important',
        allMail: mails,
        folderMails: mails.filter(mail => mail.folder === state.selectedFolder)
      }
    }
    case GET_NAV_FILTERS: {
      const filterMails = state.allMail.filter(
        mail => (action.payload.id === 0 && mail.starred) || (action.payload.id === 1 && mail.important)
      );
      return {
        ...state,
        noContentFoundMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['noPostFoundSelFilter'],
        loader: true,
        starredFilter: true,
        folderMails: filterMails
      }
    }
    case ON_FOLDER_SELECT: {
      return {...state, folderMenuState: !state.folderMenuState}
    }
    case ON_DATE_MENU_SELECT: {
      return {...state, dateMenuState: !state.dateMenuState}
    }
    case ON_FILTER_SELECT: {
      return {...state, filterMenuState: !state.filterMenuState}
    }
    case GET_NAV_LABELS: {
      const filterMails = state.allMail.filter(mail => mail.labels.includes(action.payload.id));
      return {
        ...state,
        loader: true,
        noContentFoundMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['noPostFoundSelLabel'],
        folderMails: filterMails
      }
    }
    case GET_READ_MAIL: {
      let selectedMail = 0;
      let mails = state.allMail.filter(mail => mail.folder === state.selectedFolder);
      mails = mails.map((mail) => {
        if (mail.read) {
          selectedMail++;
          return {...mail, selected: true};
        }
        return {...mail, selected: false}
      });
      return {
        ...state,
        selectedMails: selectedMail,
        allMail: mails,
        optionName: 'Read',
        folderMails: mails.filter(mail => mail.folder === state.selectedFolder)
      }
    }
    case GET_STARRED_MAIL: {
      let selectedMail = 0;
      let mails = state.allMail.filter(mail => mail.folder === state.selectedFolder);
      mails = mails.map((mail) => {
        if (mail.starred) {
          selectedMail++;
          return {...mail, selected: true};
        }
        return {...mail, selected: false}
      });
      return {
        ...state,
        selectedMails: selectedMail,
        allMail: mails,
        optionName: 'Stared',
        folderMails: mails.filter(mail => mail.folder === state.selectedFolder)
      }
    }
    case GET_UNIMPORTANT_MAIL: {
      let selectedMail = 0;
      let mails = state.allMail.filter(mail => mail.folder === state.selectedFolder);
      mails = mails.map((mail) => {
        if (!mail.important) {
          selectedMail++;
          return {...mail, selected: true};
        }
        return {...mail, selected: false}
      });
      return {
        ...state,
        selectedMails: selectedMail,
        allMail: mails,
        optionName: 'Unimportant',
        noContentFoundMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['noPostFoundSelLabel'],
        folderMails: mails.filter(mail => mail.folder === state.selectedFolder)
      }
    }
    case GET_UNREAD_MAIL: {
      let selectedMail = 0;
      let mails = state.allMail.filter(mail => mail.folder === state.selectedFolder);
      mails = mails.map((mail) => {
        if (!mail.read) {
          selectedMail++;
          return {...mail, selected: true};
        }
        return {...mail, selected: false}
      });
      return {
        ...state,
        selectedMails: selectedMail,
        allMail: mails,
        optionName: 'Unread',
        folderMails: mails.filter(mail => mail.folder === state.selectedFolder)
      }
    }
    case GET_UNSELECTED_ALL_MAIL: {
      let mails = state.allMail.map((mail) => {return {
        ...mail,
        selected: false
      }});
      return {
        ...state,
        selectedMails: 0,
        optionName: 'None',
        allMail: mails,
        folderMails: mails//mails.filter(mail => mail.folder === state.selectedFolder)
      }
    }
    case GET_UNSTARRED_MAIL: {
      let selectedMail = 0;
      let mails = state.allMail.filter(mail => mail.folder === state.selectedFolder);
      mails = mails.map((mail) => {
        if (!mail.starred) {
          selectedMail++;
          return {...mail, selected: true};
        }
        return {...mail, selected: false}
      });
      return {
        ...state,
        selectedMails: selectedMail,
        optionName: 'UnStarred',
        allMail: mails,
        folderMails: mails.filter(mail => mail.folder === state.selectedFolder)
      }
    }
    case HANDLE_REQUEST_CLOSE: {
      return {
        ...state,
        composeMail: false,
        showMessage: false,
        folderMenuState: false,
        labelMenuState: false,
        optionMenuState: false,
        dateMenuState: false,
        filterMenuState: false
      }
    }
    case ON_ALL_MAIL_SELECT: {
      return {...state}
    }
    case ON_FOLDER_MENU_ITEM_SELECT: {
      const mails = state.allMail.map(mail =>
        mail.selected && (mail.folder === state.selectedFolder) ?
          {...mail, folder: action.payload, selected: false,} : mail
      );

      return {
        ...state,
        selectedMails: 0,
        allMail: mails,
        noContentFoundMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['noPostSelFolder'],
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['postMoveSuccess'],
        showMessage: true,
        folderMails: mails.filter(mail => mail.folder === state.selectedFolder)
      }
    }
    case ON_IMPORTANT_SELECT: {
      action.payload.important = !action.payload.important;
      return {
        ...state,
        alertMessage: action.payload.important ? langText[state.localeVal!==undefined?state.localeVal:'en']['postMarkImp'] : langText[state.localeVal!==undefined?state.localeVal:'en']['postRemoveImp'],
        showMessage: true,
        folderMails: state.folderMails.map(mail =>
          mail.id === action.payload.id ?
            action.payload : mail
        )
      }
    }
    case ON_LABEL_MENU_ITEM_SELECT: {
      let currentMail = state.currentMail;
      const mails = state.allMail.map(mail => {
          // if (mail.selected && (mail.folder === state.selectedFolder)) {
          if (mail.selected) {
            if (mail.labels.includes(action.payload.id)) {
              mail.labels.splice(mail.labels.indexOf(action.payload.id), 1);
              if (currentMail !== null && mail.id === currentMail.id) {
                currentMail.labels = mail.labels;
              }
              return {...mail, labels: mail.labels};
            } else {
              mail.labels = mail.labels.push(action.payload.id);
              if (currentMail !== null && mail.id === currentMail.id) {
                currentMail.labels = mail.labels;
              }
              return {...mail, labels: mail.labels};
            }
          } else {
            return mail;
          }
        }
      );

      return {
        ...state,
        currentMail: currentMail,
        noContentFoundMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['noPostFoundSelLabel'],
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['labelUpdateSuccess'],
        showMessage: true,
        allMail: mails,
        folderMails: mails.filter(mail => (((state.selectedFolder === 0) && ((mail.folder === 1)
            || (mail.folder === 0) || (mail.folder === 2)) ) || (mail.folder === state.selectedFolder)))
      }
    }
    case ON_LABEL_SELECT: {
      return {...state, labelMenuState: !state.labelMenuState}
    }
    case ON_MAIL_CHECKED: {
      action.payload.selected = !action.payload.selected;
      let selectedMail = 0;
      const mails = state.folderMails.map(mail => {
          if (mail.selected) {
            selectedMail++;
          }
          if (mail.id === action.payload.id) {
            if (mail.selected) {
              selectedMail++;
            }
            return action.payload;
          } else {
            return mail;
          }
        }
      );
      return {
        ...state,
        selectedMails: selectedMail,
        folderMails: mails
      }
    }

    case ON_MAIL_SELECT: {
      let detailLoaderAction = false;
      if (state.currentMail === null) {
        detailLoaderAction = true;
      }
      else if (state.currentMail !== null) {
        if(state.currentMail.id !== action.payload.id) {
          detailLoaderAction = true;
        }
      }
      return {
        ...state,
        // loader: true,
        detailLoader: detailLoaderAction,
        currentMail: action.payload
      }
    }
    case ON_MAIL_SEND: {
      return {
        ...state,
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['mailSentSuccess'],
        showMessage: true,
        folderMails: state.allMail.concat(action.payload),
        allMail: state.allMail.concat(action.payload)
      }
    }
    case ON_OPTION_MENU_SELECT: {
      return {...state, optionMenuState: !state.optionMenuState}
    }
    case GET_NAV_FOLDER: {
      const filterMails = (action.payload.id === 0) ? state.allMail.filter(
        mail => (mail.folder === 0 || mail.folder === 1 || mail.folder === 2))
      : state.allMail.filter(mail => mail.folder === action.payload.id);
      return {
        ...state,
        selectedFolder: action.payload.id,
        noContentFoundMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['noPostSelFolder'],
        currentMail: null,
        loader: true,
        starredFilter: (action.payload.id === 5) ? true: false,
        folderMails: filterMails
      }
    }

    case ON_START_SELECT: {
      action.payload.data.starred = !action.payload.data.starred;
      return {
        ...state,
        alertMessage: action.payload.data.starred ? langText[state.localeVal!==undefined?state.localeVal:'en']['postMarkStar'] : langText[state.localeVal!==undefined?state.localeVal:'en']['postRemoveStar'],
        showMessage: true,
        folderMails: state.folderMails.map(mail =>
          mail.id === action.payload.data.id ?
            action.payload.data : mail
        )
      }
    }

    case SEARCH_MAIL: {
      if (action.payload === '') {
        return {...state, folderMails: state.allMail.filter((mail) => !mail.deleted)};
      } else {
        const searchMails = state.allMail.filter((mail) =>
          !mail.deleted && mail.subject.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
        return {
          ...state,
          folderMails: searchMails
        };
      }

    }

    case UPDATE_SEARCH: {
      return {...state, searchMail: action.payload}
    }
    case ON_HIDE_LOADER: {
      return {...state, loader: false}
    }
    case ON_TOGGLE_DRAWER: {
      return {...state, drawerState: !state.drawerState}
    }
    case SET_CURRENT_MAIL_NULL: {
      return {...state, currentMail: null}
    }
    case ON_DELETE_MAIL: {
      const mails = state.allMail.map(mail =>
        mail.selected && (mail.folder === state.selectedFolder) ?
          {...mail, folder: 4, selected: false,} : mail
      );

      return {
        ...state,
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['postDelSuccess'],
        showMessage: true,
        selectedMails: 0,
        allMail: mails,
        folderMails: mails.filter(mail => mail.folder === state.selectedFolder)
      }
    }

    case ON_BLOCK_MAIL: {
      const mails = state.allMail.map(mail =>
        mail.selected && (mail.folder === state.selectedFolder) ?
          {...mail, folder: 4, selected: false,} : mail
      );

      return {
        ...state,
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['postBlockSuccess'],
        showMessage: true,
        selectedMails: 0,
        allMail: mails,
        folderMails: mails.filter(mail => mail.folder === state.selectedFolder)
      }
    }

    case ON_COMPOSE_MAIL: {
      return {...state, composeMail: true}
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }

    case ON_TRASH_CLICK: {
      return {
        ...state,
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['postDelSuccess'],
        showMessage: true,
        selectedMails: 0,
        folder:0,
        selected: false,
        folderMails: (state.folderMails.map((mail) => {
          if ( mail.id === action.payload.id) {
            mail.folder = 4;
            state.folderStats.inbox = state.folderStats.inbox-1;
            state.folderStats.trash = state.folderStats.trash === -1 ? state.folderStats.trash+2 : state.folderStats.trash+1;
            return null;
          } else {
            return mail;
          }
        }
        )).filter(mail => (mail !== undefined) && (mail !== null))
      };
    }

    case ON_REPLY_CLICK: {
      return {
        ...state,
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['postReplySuccess'],
        showMessage: true,
        selectedMails: 0,
        folder:0,
        selected: false,
        folderMails: (state.folderMails.map((mail) => {
          if ( mail.id === action.payload.id) {
            mail.folder = 2;
            mail.read = 2;
            state.folderStats.replied = state.folderStats.replied === -1 ? state.folderStats.replied+2: state.folderStats.replied+1;
            return mail;
          } else {
            return mail;
          }
        }
        )).filter(mail => mail !== undefined)
      };
    }

    case ON_SPAM_CLICK: {
      return {
        ...state,
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['moveSpamSuccess'],
        showMessage: true,
        selectedMails: 0,
        folder:0,
        selected: false,
        folderMails: (state.folderMails.map((mail) => {
          if ( mail.id === action.payload.id) {
            mail.folder = 3;
            state.folderStats.inbox = state.folderStats.inbox-1;
            state.folderStats.blocked = state.folderStats.blocked === -1 ? state.folderStats.blocked+2 : state.folderStats.blocked+1;
            return null;
          } else {
            return mail;
          }
        }
        )).filter(mail => (mail !== undefined) && mail !== null)
      };
    }

    case ON_NOTES_CLICK: {
      return {
        ...state,
        notesLoading : true
      };
    }

    case FETCH_NOTES_SUCCESS: {
      return {
        ...state,
        notes : action.payload,
        notesLoading : false
      };
    }

    case ON_NOTES_SUBMIT: {
      let newNotes = state.notes;
      if (action.payload.post.notes === undefined) {
          return {
          ...state,
          alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['addnote'],
          showMessage: true,
          notes : newNotes
        };
      }
      newNotes.data.push({"notes": action.payload.post.notes});
      newNotes.total = newNotes.total+1;

      return {
        ...state,
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['noteaddedSuccess'],
        showMessage: true,
        notes : newNotes
      };
    }

    case ACTIVE_SRC: {
      return {
        ...state,
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['srcUpdated'],
        showMessage: true,
        activeSrc : action.payload
      };
    }

    case ON_DATE_RANGE_CHANGE: {
      return {
        ...state,
        selectedDate: action.payload
      };
    }
    case FETCH_PROJECT_TAGS_REQUEST: {
      return {
        ...state,
        projectLabelsLoading: false
      }
    }

    case FETCH_PROJECT_TAGS_SUCCESS: {
      return {
        ...state,
        projectLabels: action.payload.projectTags,
        kwName: action.payload.keywordName,
        kwBooleanList: action.payload.boolList,
        kwBoolLoad: true,
        projectLabelsLoading: true
      }
    }

    case UPDATE_LABEL_INFO: {
      const projectLabels = state.projectLabels.map(label => {
        if (label.id === action.payload.id) {
          label.tag_name = (action.payload.title === null) ? label.tag_name : action.payload.title;
          label.title = (action.payload.title === null) ? label.tag_name : action.payload.title;
          label.color = (action.payload.color === null) ? label.color : action.payload.color;
          return label;
        } else {
          return label;
        }
      });
      return {
        ...state,
        projectLabels: projectLabels
      }
    }

    case ADD_LABEL_INFO: {
      return {
        ...state,
        labelDialogPreloader: true,
        labelDialogAlready: false
      }
    }

    case ADD_LABEL_INFO_SUCCESS: {
      let newProjectlabels = state.projectLabels;
      newProjectlabels.push(action.payload);
      return {
        ...state,
        labelDialogPreloader: false,
        projectLabels: newProjectlabels
      }
    }

    case ADD_LABEL_ALREADY_SUCCESS: {
      return {
        ...state,
        labelDialogAlready: true
      }
    }
    case ADD_LABEL_ALREADY: {
      return {
        ...state,
        labelDialogAlready: false
      }
    }

    case DELETE_LABEL: {
      return {
        ...state,
        labelDialogPreloader: true,
        labelDialogAlready: false
      }
    }
    case DELETE_LABEL_SUCCESS: {
      let newProjectlabels = state.projectLabels.filter(label => label.id !== action.payload);
      return {
        ...state,
        labelDialogPreloader: false,
        projectLabels: newProjectlabels
      }
    }
    case LABEL_ACTION_PRELOADER_SHOW: {
      return {
        ...state,
        labelDialogPreloader: true
      }
    }

    case ON_SPAM_CLICK_AUTHOR: {
      return {
        ...state,
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['movespamSuccess'],
        showMessage: true
      };
    }

    case FOLDER_STAT_SUCCESS: {
      let fStats = state.folderStats;
      if (action.payload.inbox !== undefined) {
        fStats = action.payload;
      }
      return {
        ...state,
        folderStats: fStats
      }
    }

    case ON_READ_CLICK: {
      return {
        ...state,
        showMessage: false,
        selectedMails: 0,
        folder:0,
        selected: false,
        folderMails: (state.folderMails.map((mail) => {
          if ( mail.id === action.payload.id) {
            mail.read = 1;
            return mail;
          } else {
            return mail;
          }
        }
        )).filter(mail => mail !== undefined)
      };
    }

    case ON_NEW_KEYWORD_LOAD: {
      return {
        ...state,
        showMessage: false,
        selectedMails: 0,
        folder:0,
        selected: false,
        activeSrc: {
          'twitter':1,
          'web':1,
          'facebook':1,
          'youtube': 1,
          'quora' : 1,
          'reddit': 1,
          'forum' : 1,
          'instagram': 1,
          'news': 1,
          'podcast': 1,
          'tv': 1,
          'radio': 1,
          'linkedin': 1,
          'lqp': 0,
          'all':1
        },
        folderStats: {inbox: -1, unread: -1, replied: -1, blocked: -1, trash:-1, unlink: -1, leads: -1, quest: -1, total: -1},
        kwName: '',
        kwBooleanList : [],
        kwBoolLoad: false,
        donutTotal: 0,
        sentiPos: false,
        sentiNeg: false,
        sentiNet: false,
        sentiUnk: false,
        trueTotal: 0,
        checkReset: true,
        titleList: [],
        webList: [],
        wordList: [],
        langList: [],
        locList: [],
        sortList: [],
        filterList: [],
        lastSelectedDateId: 6
      }
    }

    case ON_FILTER_TOGGLE_DRAWER: {
      return {
        ...state,
        filterDrawerState: !state.filterDrawerState
      }
    }

    case ON_USER_FETCHED_SUCCESS: {
      return {
        ...state,
        user: action.payload
      }
    }

    case SHOW_NEW_POSTS: {
      return {
        ...state,
        showNewPosts: parseInt(action.payload)
      }
    }

    case ON_TAG_DELETE: {
      let delTagMails = state.folderMails.map((mail) => {
        if (mail.id == action.payload.postId) {
          mail.labels.filter((labelId) => (labelId!=action.payload.labelId))
          return {
            ...mail,
            labels: mail.labels.filter((labelId) => (labelId!=action.payload.labelId)),
            label_info: mail.labels.filter((label) => (label.tag_id!=action.payload.labelId)),
          }
        } else {
          return mail;
        }
      });
      return {
        ...state,
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['postTagRemSuccess'],
        showMessage: true,
        allMails: delTagMails,
        folderMails: delTagMails
      }
    }

    case ON_CHANGE_ADD_LABEL_TITLE: {
      return {...state, labelDialogAlready: false}
    }

    case ON_APPLY_STARRED: {
      // Commented as now we are lazy loading.
      // return {...state, loader: true, currentMail: null}
      let starMails = state.folderMails.map((mail) => {
        if (action.payload.postIds.includes(mail.id)) {
          return {
            ...mail,
            starred: 1
          };
        } else {
          return {
            ...mail
          };
        }
      });
      return {...state, folderMails: starMails, allMail: starMails};
    }

    case TAG_FILTER_SELECTED: {
      return {...state, tagFilter: true, loader: true}
    }

    case ON_DOWNLOAD_CLICK: {
      return {...state, downloader: true}
    }

    case ON_DOWNLOAD_SUCCESS: {
      return {...state, downloader: false}
    }

    case ON_APPLY_LABEL: {
      // Lazeloading content, no need to show preloader
      // return {...state, currentMail: null, loader: true,}
      let tagedmails = state.folderMails.map((post) => {
        if (action.payload.postIds.includes(post.id)) {
          let postLabels = post.label_info;
          let postLabelIds = post.labels;
          // labelId
          let labelToAdd = state.projectLabels.filter((label) => label.id == action.payload.labelId);
          if (labelToAdd.length == 0) {
            return {...post};
          } else {
            if ((postLabels.filter((label) => label.id == action.payload.labelId)).length == 0) {
              postLabels.push({'up_id': 0, tag_id: labelToAdd[0].id});
              postLabelIds.push(labelToAdd[0].id)
              return {
                ...post,
                label_info: postLabels,
                labels: postLabelIds
              };
            } else {
              return {
                ...post
              };
            }
          }
        } else {
          return {
            ...post
          };
        }
      });
      return {...state, folderMails: tagedmails, allMail: tagedmails}
    }

    case DETAIL_LOADER_HANDLER : {
      return {...state, detailLoader: false}
    }

    case ON_MOUSE_OVER : {
      return {...state, changeCurPostidHover: action.payload.postid}
    }

    case ON_MOUSE_OUT : {
      return {...state, changeCurPostidHover: 0}
    }

    case CALL_LAZY_LOAD : {
      return {...state, lazyLoad: action.payload.lazy_load}
    }

    case SENTI_ON_CHANGE_IMPACT: {
      let total_count = state.trueTotal;
      let check_all_reset = true;
      if (action.payload.checked === true ) {
        total_count++;
      }
      else if (action.payload.checked === false) {
        total_count--;
        if (total_count === 0){
          check_all_reset = false;
        }
      }
      return {
        ...state,
        sentiPos : (action.payload.impact === 'sentiPos' ? action.payload.checked: state.sentiPos),
        sentiNeg: (action.payload.impact === 'sentiNeg' ? action.payload.checked: state.sentiNeg),
        sentiNet: (action.payload.impact === 'sentiNet' ? action.payload.checked: state.sentiNet),
        sentiUnk: (action.payload.impact === 'sentiUnk' ? action.payload.checked: state.sentiUnk),
        trueTotal: total_count,
        checkReset: check_all_reset
      };
    }

    case ON_SENTI_CHANGE: {

      return {
        ...state,
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['impactChangeSuccess'],
        showMessage: true,
        selectedMails: 0,
        folder:0,
        selected: false,
        folderMails: (state.folderMails.map((mail) => {
          if ( mail.id === action.payload.id) {
            mail.senti = action.payload.val;
            return mail;
          } else {
            return mail;
          }
        }
        )).filter(mail => mail !== undefined)
      };
    }

    case ON_TITLE_SUBMIT: {
      let titleData = state.titleList;
      titleData.push(action.payload.title);
      return {
        ...state,
        titleList : titleData
      };
    }

    case ON_TITLE_DELETE: {
      let titleData = state.titleList;
      const index = titleData.indexOf(action.payload.title);
      if (index > -1) {
        titleData.splice(index, 1);
      }
      return {
        ...state,
        titleList : titleData,
        checkReset: false
      };
    }

    case ON_WORD_SUBMIT: {
      let wordData = state.wordList;
      wordData.push(action.payload.word);
      return {
        ...state,
        wordList : wordData
      };
    }

    case ON_WORD_DELETE: {
      let wordData = state.wordList;
      const index = wordData.indexOf(action.payload.word);
      if (index > -1) {
        wordData.splice(index, 1);
      }
      return {
        ...state,
        wordList : wordData,
        checkReset: false
      };
    }

    case ON_WEB_SUBMIT: {
      let webData = state.webList;
      webData.push(action.payload.web);
      return {
        ...state,
        webList : webData
      };
    }

    case ON_WEB_DELETE: {
      let webData = state.webList;
      const index = webData.indexOf(action.payload.web);
      if (index > -1) {
        webData.splice(index, 1);
      }
      return {
        ...state,
        webList : webData,
        checkReset: false
      };
    }

    case RESET_FILTER: {
      return {
        ...state,
        sentiPos: false,
        sentiNeg: false,
        sentiNet: false,
        sentiUnk: false,
        trueTotal: 0,
        titleList: [],
        webList: [],
        wordList: [],
        langList: [],
        sortList: [],
        locList: [],
        checkReset: true
      };
    }

    case ON_FILTER_SUBMIT: {
      return {
        ...state,
        show_filter_save: false
      };
    }

    case ON_FILTER_SUBMIT_SUCCESS: {
      return {
        ...state,
        show_filter_save: true
      };
    }

    case GET_FILTER_LIST: {
      return {
        ...state,
        filterList: [],
        filterListLoad: true
      };
    }

    case GET_FILTER_SUCCESS: {
      return {
        ...state,
        filterList: action.payload,
        filterListLoad: false
      };
    }


    case ON_DELETE_FILTER: {
      let filterData = state.filterList;
      let found_res = -1;
      filterData.map((data, index) => {
        if (data['id'] === action.payload.filter_id) {
          found_res = index;
        }
      });
      if (found_res > -1) {
        filterData.splice(found_res, 1);
      }
      return {
        ...state,
        filterList : filterData
      };
    }

    case APPLY_SAVED_FILTER: {
      let filter_info = JSON.parse(action.payload.info);
      let total_cnt = 0;
      if (filter_info.senti_pos === true)
        total_cnt++;
      if (filter_info.senti_neg === true)
        total_cnt++;
      if (filter_info.senti_net === true)
        total_cnt++;
      if (filter_info.senti_unk === true)
        total_cnt++;
      return {
        ...state,
        sentiPos: filter_info.senti_pos,
        sentiNeg: filter_info.senti_neg,
        sentiNet: filter_info.senti_net,
        sentiUnk: filter_info.senti_unk,
        trueTotal: total_cnt,
        checkReset: true,
        titleList: filter_info.title_list,
        webList: filter_info.web_list,
        wordList: filter_info.word_list,
        langList: filter_info.lang_list !== undefined ? filter_info.lang_list :[],
        locList: filter_info.loc_list !== undefined ? filter_info.loc_list :[],
        sortList: filter_info.sort_list !== undefined ? filter_info.sort_list :[]
      };
    }

    case ON_LANG_SUBMIT: {
      let langData = state.langList;
      langData.push(action.payload.data);
      return {
        ...state,
        langList : langData
      };
    }

    case ON_LANG_DELETE: {
      let langData = state.langList;
      const index = langData.indexOf(action.payload.data);
      if (index > -1) {
        langData.splice(index, 1);
      }

      return {
        ...state,
        langList : langData,
        checkReset: false
      };
    }

    case ON_LANG_CHANGE: {
      return {
        ...state,
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['langChangeSuccess'],
        showMessage: true,
        selectedMails: 0,
        folder:0,
        selected: false,
        folderMails: (state.folderMails.map((mail) => {
          if ( mail.id === action.payload.id) {
            mail.lan = action.payload.val;
            return mail;
          } else {
            return mail;
          }
        }
        )).filter(mail => mail !== undefined)
      };
    }

    case ON_LOC_SUBMIT: {
      let locData = state.locList;
      locData.push(action.payload.data);
      return {
        ...state,
        locList : locData
      };
    }

    case ON_LOC_DELETE: {
      let locData = state.locList;
      const index = locData.indexOf(action.payload.data);
      if (index > -1) {
        locData.splice(index, 1);
      }

      return {
        ...state,
        locList : locData,
        checkReset: false
      };
    }

    case ON_LOC_CHANGE: {
      return {
        ...state,
        alertMessage: langText[state.localeVal!==undefined?state.localeVal:'en']['locChangeSuccess'],
        showMessage: true,
        selectedMails: 0,
        folder:0,
        selected: false,
        folderMails: (state.folderMails.map((mail) => {
          if ( mail.id === action.payload.id) {
            mail.loc = action.payload.val;
            return mail;
          } else {
            return mail;
          }
        }
        )).filter(mail => mail !== undefined)
      };
    }

    case CHANGE_DATE_RANGE_CALENDAR: {
      let newRange = {
        from: moment(action.payload.newRange.start).format('YYYY-MM-DD'),
        to: moment(action.payload.newRange.end).format('YYYY-MM-DD'),
        label: "Custom Date",
        id: 8
      };
      return {
        ...state,
        selectedDate: newRange
      }
    }

    case RESELECT_LAST_SELECTED_DATE: {
      let newRange = {
        to: moment().format('YYYY-MM-DD'),
        from: moment().subtract(1825,'d').format('YYYY-MM-DD'),
        label: "All Time",
        id: 6
      };
      if (action.payload !== undefined) {
        newRange = state.dateOptionList[action.payload]
      }
      return {
        ...state,
        selectedDate: newRange
      }
    }

    case ON_SORT_SUBMIT: {
      let sortData = state.sortList;
      sortData.push(action.payload.data);
      return {
        ...state,
        sortList : sortData
      };
    }

    case ON_SORT_DELETE: {
      let sortData = state.sortList;
      const index = sortData.indexOf(action.payload.data);
      if (index > -1) {
        sortData.splice(index, 1);
      }

      return {
        ...state,
        sortList : sortData,
        checkReset: false
      };
    }

    case SAVE_LAST_SELECTED_DATE: {
      return {
        ...state,
        lastSelectedDateId : action.payload
      };
    }

    case GET_LANG: {
      return {
        ...state,
        localeVal : action.payload
      };
    }

    default:
      return state;
  }
}
