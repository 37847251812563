import {all, call, put, takeEvery} from 'redux-saga/effects';
import {fetchProjectsSuccess, showProjectMessage} from 'actions/Project';
import {getLoggedInUser} from 'actions/Mail';
import {fetchUserSuccess, showUserMessage, sendInviteSuccess, showInviteSuccess, quotaStatSuccessAction, sendSupportSuccess} from 'actions/Setting';

import {
  FETCH_ALL_PROJECT,
  FETCH_USER_REQUEST,
  SEND_INVITE_REQUEST,
  SHOW_INVITE_REQUEST,
  USER_QUOTA_REQUEST,
  SEND_SUPPORT_REQUEST
} from 'constants/ActionTypes';

import apiClient from 'apiclient';
import queryString from 'query-string';

const getProjects = async () => {
  return await apiClient.get('/api/get-keyword-list')
      .then(response => response.data);
      // .catch(error => error);
};


function* fetchProjectRequest() {
  try {
    const fetchedProjects = yield call(getProjects);
    console.log(fetchedProjects);
    yield put(fetchProjectsSuccess(fetchedProjects['pro'], fetchedProjects['user'], fetchedProjects['plan_progess'], fetchedProjects['free_user']));
  } catch (error) {
    yield put(showProjectMessage(error));
  }
}

const getUser = async () => {
  return await apiClient.get('/api/account-info')
      .then(response => response.data);
      // .catch(error => error);
};


function* fetchUserRequest() {
  try {
    const fetchedUser = yield call(getUser);
    yield put(fetchUserSuccess(fetchedUser));
    yield put(getLoggedInUser(fetchedUser));
  } catch (error) {
    yield put(showUserMessage(error));
  }
}

const sendInviteRequest = async (request) => {
  const post_det = {
    name: request.name,
    email: request.email
  };
  return await apiClient.get('/api/invite/send?'+ queryString.stringify(post_det))
      .then(response => response.data);
      // .catch(error => error);
}


function* sendUserInvite(res) {
  try {
    const inviteResponse = yield call(sendInviteRequest, res.payload);
    yield put(sendInviteSuccess(inviteResponse));
  } catch(error) {
    yield put(showUserMessage(error));
  }
}

function* showUserInvite(res) {
  try {
    const inviteList = yield call(showInviteRequest, res.payload);
    yield put(showInviteSuccess(inviteList));
  } catch(error) {
    yield put(showUserMessage(error));
  }
}

const showInviteRequest = async (request) => {
  return await apiClient.get('/api/invite')
      .then(response => response.data);
      // .catch(error => error);
}

const userQuotaStats = async (request) => {
  let requestData = {};
  return await apiClient.post('/api/post/quota-stats', requestData)
  .then(response => response.data);
  // .catch(error => error);
};

function* getUserQuota(request) {
  try {
    const quotaStats = yield call(userQuotaStats, request.payload);
    yield put(quotaStatSuccessAction(quotaStats));
  } catch (error) {
    yield put(showUserMessage("Unable to fetch quota result"));
  }
}

function* sendSupportRequest(res) {
  try {
    const supportResponse = yield call(callSupportRequest, res.payload);
    console.log(supportResponse);
    yield put(sendSupportSuccess(supportResponse));
  } catch(error) {
    yield put(showUserMessage(error));
  }
}

const callSupportRequest = async (request) => {
  const post_det = {
    issueDisc: request.issueDisc,
    images: request.images,
    imagenames: request.imagenames
  };
  return await apiClient.post('/api/support', post_det)
      .then(response => response.data);
      // .catch(error => error);
}

export default function* rootSaga() {
  yield all([
    takeEvery(FETCH_ALL_PROJECT, fetchProjectRequest),
    takeEvery(FETCH_USER_REQUEST, fetchUserRequest),
    takeEvery(SEND_INVITE_REQUEST, sendUserInvite),
    takeEvery(SHOW_INVITE_REQUEST, showUserInvite),
    takeEvery(USER_QUOTA_REQUEST, getUserQuota),
    takeEvery(SEND_SUPPORT_REQUEST, sendSupportRequest),
  ])
}

