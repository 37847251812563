import {
ADD_KW_LIST,
ON_SRC_CHANGE,
ON_KW_SRC_CHANGE,
ON_KW_CHANGE,
ON_FILL_CHANGE,
ON_DELETE_KW,
INFLUENCER_REQUEST_DATA,
INFLUENCER_REQUEST_DATA_SUCCESS,
INFLUENCER_REQUEST_DATA_FAILED,
ON_RESET_LIST,
ON_FILTER_ADD,
ON_FILTER_DEL,
ON_FILTER_DEL_GRP,
ON_FILTER_DEL_COMP,
ON_FILTER_FILL_CHANGE,
ON_FILTER_RESET,
ON_ADD_KW_FILL_LIST,
ON_HANDLE_ADD_KW_CHANGE,
ON_HANDLE_ADD_KW_CHANGE_FILL,
CALL_LAZY_LOAD,
SEL_ALERT,
FILTER_EDIT_DATA,
FILTER_UPDATE,
SET_KW_LIST,
EDIT_SRC_FILTER,
ON_QUOTA_REQUST_SUCCESS,
CLOSE_QUOTA,
SHOW_QUOTA,
SAVED_URL_REQUEST,
SAVED_URL_REQUEST_SUCCESS,
ON_SORT,
RESET_KW_STATE,
SET_SEARCH_BOOL
} from 'constants/ActionTypes';


const list_num = [{
    "name": "Equal to",
    "operator": "=",
    "className": "equal",
    "iconName": "equals"
  }, {
    "name": "Less than",
    "operator": "<",
    "className": "lessThan",
    "iconName": "less-than"
  }, {
    "name": "Greater than",
    "operator": ">",
    "className": "greaterThan",
     "iconName": "greater-than"
  }, {
    "name": "Between",
    "operator": "<>",
    "className": "between",
    "iconName": "arrows-alt-h"
  }, {
    "name": "Not Equal",
    "operator": "!=",
    "className": "notEqual",
    "iconName": "not-equal"
}];

const list_str = [{
    "name": "Equal to",
    "operator": "=",
    "className": "equal",
    "iconName": "equals"
  }, {
    "name": "Include",
    "operator": "<",
    "className": "check",
    "iconName": "check"
  }, {
    "name": "Exclude",
    "operator": ">",
    "className": "ban",
     "iconName": "ban"
  }];

  const list_email = [{
    "name": "Include",
    "operator": "@",
    "className": "check",
    "iconName": "check"
  }, {
    "name": "Exclude",
    "operator": "#",
    "className": "ban",
    "iconName": "ban"
  }, {
    "name": "Has Email",
    "operator": "$",
    "className": "check",
    "iconName": "check"
  }, {
    "name": "Doesn\'t Have Email",
    "operator": "%",
    "className": "ban",
    "iconName": "ban"
  }
  ];

const filter_list = [{'Engagement Rate': list_num}, {'Follower Count': list_num}, {'Following Count': list_num}, {'Post Count': list_num}, {'Location': list_str}, {'Language': list_str}, {'Email': list_email}];
//, {'Website': list_has}

const INIT_STATE = {
  kwListSearchCnt: 1,
  kwList: [],
  filterList: {
    filterId: '',
    filterName: '',
    groupId: 0,
    duplicate: false,
    advancedFilter: true,
    filterStatus: { savedStatus: true, updateStatus: false },
    list: []
  },
  /*filterBool: 'OR',
  filterBy: '',
  filterCond: '',
  filterValue: '',
  filternGrp: 0,
  */
  kwResSet: 0,
  loadingResearchRes: true,
  hasResearchResError: false,
  researchReserror: '',
  influencerData: [],
  addKw: '',
  addKwisBool: 1,
  addKwField: '',
  filter_list_det: filter_list,
  toBeLater: true,
  lazyLoad: false,
  result_page: {
    total: 0,
    per_page: 20,
    current_page: 1,
    sort_by: 'reach',
    sort: 'desc'
  },
  loadMore: true,
  sel_alert_cond: 0,
  filter_edit_data_val: {},
  all_added: 0,
  src_filter: {
    'tw': 1,
    'reddit': 1,
    'youtube': 1,
    'insta': 1
  },
  quota_info: {},
  quota_show: false,
  quota_popup: false,
  saved_url: '',
  saved_url_load: true,
  filterSearchBool: 0

};
//'Twitter Location': 'twt_loc', 'Youtube Location': 'yt_loc',
const field_des = {'Twitter Description': 'twt_des',  'Twitter Name': 'twt_name','Twitter Location': 'twt_loc', 'Reddit Description': 'red_des', 'Reddit User Name': 'red_usr', 'Youtube Channel Description': 'yt_des',  'Youtube Channel Title': 'yt_title', 'Youtube Location': 'yt_loc', 'Instagram Description': 'ins_des', 'Instagram User': 'ins_user', 'All': 'all'};
//'Youtube Video Title': 'yt_title_dt',
//const field_des = {'Twitter Description': 'twt_des',  'Twitter Name': 'twt_name','Twitter Location': 'twt_loc', 'Instagram Description': 'ins_des', 'Instagram User': 'ins_user', 'All': 'all'};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case ADD_KW_LIST: {
      return {
        ...state,
        kwListSearchCnt: state.kwListSearchCnt+1
      }
    }

    case ON_SRC_CHANGE: {
      let kwData = state.kwList;

      if (kwData[action.payload.iterate] === undefined) {
        kwData[action.payload.iterate] = {'src' :action.payload.data};
        kwData[action.payload.iterate]['src_id'] = field_des[action.payload.data];
      }
      else {
        kwData[action.payload.iterate]['src'] = action.payload.data;
        kwData[action.payload.iterate]['src_id'] = field_des[action.payload.data];
      }

      if (kwData[action.payload.iterate]['fill'] === undefined) {
        kwData[action.payload.iterate]['fill'] = 1;
      }

      if (kwData[action.payload.iterate]['kw'] === undefined) {
        kwData[action.payload.iterate]['kw'] = '';
      }

      if (action.payload.data === 'All') {
        kwData[action.payload.iterate]['fill'] = 1;
      }
      let check_all_enabled = 0;
      kwData.map((data, index) => {
        if (data.src === 'All') {
          check_all_enabled = 1;
        }
      });

      return {
        ...state,
        kwList : kwData,
        all_added : action.payload.data === 'All' ? 1 : check_all_enabled
      };
    }

    case ON_KW_SRC_CHANGE: {
      return {
        ...state,
        addKwField: action.payload.data
      };
    }

    case ON_KW_CHANGE: {
      let kwData = state.kwList;
      if (kwData[action.payload.iterate] === undefined) {
        kwData[action.payload.iterate] = {'kw' :action.payload.data};
      }
      else {
        kwData[action.payload.iterate]['kw'] = action.payload.data;
      }

      if (kwData[action.payload.iterate]['fill'] === undefined) {
        kwData[action.payload.iterate]['fill'] = 1;
      }

      if (kwData[action.payload.iterate]['src'] === undefined) {
        kwData[action.payload.iterate]['src'] = '';
        kwData[action.payload.iterate]['src_id'] = '';
      }

      return {
        ...state,
        kwList : kwData
      };


    }

    case ON_FILL_CHANGE: {
      let kwData = state.kwList;

      if (kwData[action.payload.iterate] === undefined) {
        kwData[action.payload.iterate] = {'fill' :action.payload.data};
      }
      else {
        kwData[action.payload.iterate]['fill'] = action.payload.data;
      }

      if (kwData[action.payload.iterate]['kw'] === undefined) {
        kwData[action.payload.iterate]['kw'] = '';
      }

      if (kwData[action.payload.iterate]['src'] === undefined) {
        kwData[action.payload.iterate]['src'] = '';
        kwData[action.payload.iterate]['src_id'] = '';
      }

      return {
        ...state,
        kwList : kwData
      };

    }

    case ON_FILTER_FILL_CHANGE: {
      return {
        ...state,
        filterBool : action.payload.data
      };

    }

    case ON_DELETE_KW: {
      let kwData = state.kwList;
      let check_all = 0;
      if (kwData[action.payload.iterate] !== undefined) {
        if (kwData[action.payload.iterate]['src'] === 'All') {
          check_all = 1;
        }
        delete kwData[action.payload.iterate];
      }
      let array = kwData,
        filteredArray = array.filter(function () {
          return true
        });

      let temp_kw_data = [];
      let temp_index = 0;
      filteredArray.map((data, index) => {
        temp_kw_data[temp_index] = data;
        temp_index++;
      });

      temp_kw_data.map((data, index) => {
        if (data.src === 'All') {
          check_all = 0;
        }
      });
      let src_filter_sel = state.src_filter;
      if (check_all === 1 && state.all_added === 1) {
        src_filter_sel = {
          'tw': 1,
          'reddit': 1,
          'youtube': 1,
          'insta': 1
        }
      }
      return {
        ...state,
        kwList : temp_kw_data,
        kwListSearchCnt: state.kwListSearchCnt-1,
        all_added: (check_all === 1 && state.all_added === 1) ? 0 : state.all_added,
        src_filter: src_filter_sel,
        filterSearchBool: temp_kw_data.length === 1? 0: state.filterSearchBool

      };

    }

    case ON_RESET_LIST: {
      return {
        ...state,
        kwList : [],
        filterList: {
          filterId: '',
          filterName: '',
          groupId: 0,
          duplicate: false,
          advancedFilter: true,
          filterStatus: { savedStatus: true, updateStatus: false },
          list: []
        },
        kwResSet: 0,
        kwListSearchCnt: 1,
        influencerData: [],
        loadMore: true,
        loadingResearchRes: true,
        hasResearchResError: false,
        lazyLoad: false,
        result_page: {
          total: 0,
          per_page: 20,
          current_page: 1,
          sort_by: 'reach',
          sort: 'desc'
        },
        all_added: 0,
        src_filter: {
          'tw': 1,
          'reddit': 1,
          'youtube': 1,
          'insta': 1
        }
      };
    }

    case CALL_LAZY_LOAD : {
      return {...state, lazyLoad: action.payload.lazy_load}
    }

    case INFLUENCER_REQUEST_DATA: {
      //alert(action.payload.filter_chk);
      let kw_list_filtered = state.kwList.filter(function (el) { return !(el['src'] === '' && el['kw'] === '' && el['src_id'] === '')});

      return {
        ...state,
        kwResSet: 1,
        saved_url_load: true,
        loadingResearchRes: state.lazyLoad ? false : true,
        hasResearchResError: false,
        researchReserror: '',
        influencerData: action.payload.page === 1 ? []: state.influencerData,
        kwList: kw_list_filtered,
        result_page :  {
          //total: action.payload.total,
          total: state.result_page.total,
          per_page: state.result_page.per_page,
          current_page: action.payload.page,
          sort_by: action.payload.sort_by !== undefined ? action.payload.sort_by : state.result_page.sort_by,
          sort: action.payload.sort !== undefined ? action.payload.sort : state.result_page.sort
        }
      };
    }

    case INFLUENCER_REQUEST_DATA_SUCCESS: {
      let newData = action.payload.data;
      let total = action.payload.total !== undefined ? action.payload.total: 0;
      if (newData.length === 0 && state.result_page.current_page > 1) {
        total = state.result_page.total
      }
      //action.payload.saved_url = '6192060557d17b146b3298a3';
      return {
        ...state,
        loadingResearchRes: false,
        hasResearchResError: false,
        researchReserror: '',
        loadMore: newData.length === 0 || newData.length < 20  ? false : true,
        influencerData: state.lazyLoad ? state.influencerData.concat(newData):newData,
        result_page :  {
          total: total,
          //total: 100,
          per_page: state.result_page.per_page,
          current_page: state.result_page.current_page,
          sort_by: state.result_page.sort_by,
          sort: state.result_page.sort

        },
        quota_info: action.payload.credit !== undefined ? action.payload.credit: state.quota_info,
        saved_url: action.payload.saved_url !== undefined ? action.payload.saved_url: state.saved_url
        //filterSearchBool: action.payload.and_cond !== undefined ? parseInt(action.payload.and_cond): 0
      };
    }

    case INFLUENCER_REQUEST_DATA_FAILED: {
      return {
        ...state,
        influencerData: [],
        loadingResearchRes: false,
        hasResearchResError: true,
        researchReserror: 'Something went wrong'
      };
    }


    case ON_FILTER_ADD: {
      /*let filterListData = state.filterList;
      console.log(action.payload);
      //, newgp: action.payload.filterNewGrp

      if (action.payload.groupId !== undefined && action.payload.groupId > 0) {

      }
      if (action.payload.filterNewGrp === true) {
        const new_grp_list = [];
        const m_num = Math.random();
        new_grp_list.push(conditionType: action.payload.filterBool, name: action.payload.filterByVal, oprName: action.payload.filterBySelVal, value: action.payload.filterBySelValCond, status: m_num);
        filterListData.push({groupId: m_num, conditionType: action.payload.filterBool, list:  new_grp_list});
      }
      else {
        filterListData.push({conditionType: action.payload.filterBool, name: action.payload.filterByVal, oprName: action.payload.filterBySelVal, value: action.payload.filterBySelValCond });
      }

      console.log(filterListData);
      */
      //let filterListData = state.filterList;
      //filterListData.push(action.payload.data);
      //console.log(filterListData);
      return {
        ...state,
        filterList: action.payload.data,
        lazyLoad: false
        /*,
        filterBool: 'OR',
        filterBy: '',
        filterCond: '',
        filterValue: '',
        filternGrp: 0*/
      };
    }

    case ON_FILTER_DEL: {
      let to_be_delete = state.filterList;
      function removeCriteria(node, criteria, inner) {
        if (criteria.length == 0) {
          return;
        }
        if (node.list) {
          node.list = node.list.filter(child =>  !criteria.includes(child.filter_target));
          node.list.forEach(child => removeCriteria(child, criteria, 1));

          return node;
        }
      }
      let temp_data = removeCriteria(to_be_delete, [action.payload.data], 0);
      temp_data.list.filter(function f(o) {
        if (o.list === undefined) return true;
        if (o.list) {
          return (o.list = o.list.filter(f)).length
        }
      });
      return {
        ...state,
        filterList: temp_data,
        lazyLoad: false
      }
    }

    case ON_FILTER_DEL_GRP: {
      let to_be_delete = state.filterList;
      function removeCriteria(node, criteria) {
        if (criteria.length == 0) {
          return;
        }
        if (node.list) {
          node.list = node.list.filter(child =>  !criteria.includes(child.groupId));
          node.list.forEach(child => removeCriteria(child, criteria));
          return node;
        }
        return node;
      }
      let temp_data = removeCriteria(to_be_delete, [action.payload.data]);
      temp_data.list.filter(function f(o) {
        if (o.list === undefined) return true;
        if (o.list) {
          return (o.list = o.list.filter(f)).length
        }
      });

      return {
        ...state,
        filterList: temp_data,
        lazyLoad: false
      }
    }

    case ON_FILTER_RESET: {
      return {
        ...state,
        lazyLoad: false
        /*,
        filterBool: 'OR',
        filterBy: '',
        filterCond: '',
        filterValue: '',
        filternGrp: 0*/
      };
    }


    case ON_ADD_KW_FILL_LIST: {
      let kwData = state.kwList;
      if (kwData[kwData.length] === undefined) {
        kwData[kwData.length] = action.payload.data;
      }
      return {
        ...state,
        kwList : kwData,
        addKw: '',
        addKwisBool: 1,
        addKwField: '',
        lazyLoad: false,
        kwListSearchCnt: state.kwListSearchCnt+1,
        src_filter: {
          'tw': 1,
          'reddit': 1,
          'youtube': 1,
          'insta': 1
        },
        all_added: action.payload.data.src === 'All' ? 1 : state.all_added

      };

    }

    case ON_HANDLE_ADD_KW_CHANGE: {
      return {
        ...state,
        addKw : action.payload.data
      };

    }

    case ON_HANDLE_ADD_KW_CHANGE_FILL: {
      return {
        ...state,
        addKwisBool : action.payload.data
      };

    }

    case SEL_ALERT: {
      return {
        ...state,
        sel_alert_cond: action.payload
      }
    }

    case FILTER_EDIT_DATA: {
      return {
        ...state,
        filter_edit_data_val: action.payload
      }
    }

    case FILTER_UPDATE: {

      let to_be_update = state.filterList;
      let new_update = state.filter_edit_data_val;
      function updateCriteria(node, criteria) {
        if (Object.keys(criteria).length == 0) {
          return;
        }
        if (node.list) {
          let temp_update_data = node;
          let temp_index = 0;
          node.list.map((data, index) => {
            if (data.filter_target === criteria.filter_target) {
              temp_update_data.list[temp_index] = criteria;
            }
            else {
              temp_update_data.list[temp_index] = data;
            }
            temp_index++;
          });
          temp_update_data.list.forEach(child => updateCriteria(child, criteria));
          return temp_update_data;
        }
      }
      let temp_data = updateCriteria(to_be_update, new_update);
      return {
        ...state,
        filterList: temp_data,
        lazyLoad: false
      }
    }


    case ON_FILTER_DEL_COMP: {
      return {
        ...state,
        lazyLoad: false,
        filterList: {
          filterId: '',
          filterName: '',
          groupId: 0,
          duplicate: false,
          advancedFilter: true,
          filterStatus: { savedStatus: true, updateStatus: false },
          list: []
        }
      }
    }

    case SET_KW_LIST: {
      return {
        ...state,
        kwList: action.payload
      }
    }

    case EDIT_SRC_FILTER: {
      let edit_vals = state.src_filter;

      let check_all_enabled = 0;
      for (const [key, value] of Object.entries(edit_vals)) {
        if (value === 1) {
          check_all_enabled++;
        }
      }
      if (check_all_enabled === 4) {
        edit_vals['tw'] = 0;
        edit_vals['reddit'] = 0;
        edit_vals['youtube'] = 0;
        edit_vals['insta'] = 0;
        edit_vals[action.payload.src] = 1;
      }
      else {
        edit_vals[action.payload.src] = (action.payload.val === false || action.payload.val === 0) ? 0 : 1;
        let check_each = 0;
        for (const [key, value] of Object.entries(edit_vals)) {
          if (value === 0) {
            check_each++;
          }
        }
        if (check_each === 4) {
          edit_vals['tw'] = 1;
          edit_vals['reddit'] = 1;
          edit_vals['youtube'] = 1;
          edit_vals['insta'] = 1;
        }
      }
      return {
        ...state,
        src_filter: edit_vals,
        lazyLoad: false
      }
    }

    case ON_QUOTA_REQUST_SUCCESS: {
      return {
        ...state,
        quota_show: true,
        quota_info: action.payload
      }
    }

    case CLOSE_QUOTA: {
      return {
        ...state,
        quota_popup: false
      }
    }

    case SHOW_QUOTA: {
      return {
        ...state,
        quota_popup: true
      }
    }

    case SAVED_URL_REQUEST: {
      return {
        ...state
      }
    }

    case SAVED_URL_REQUEST_SUCCESS: {
      let data = JSON.parse(action.payload.saved_url);
      //let data = [{kw: 's', fill: 1, src: 'All', src_id: 'all'}];
      let all_val = 0;
      data.map((o) => {
        if (o.src === 'All') {
          all_val = 1;
        }
      });
      return {
        ...state,
        kwList: data,
        kwResSet: 1,
        saved_url_load: false,
        all_added: all_val,
        filterSearchBool: action.payload.and_cond !== undefined ? parseInt(action.payload.and_cond): state.filterSearchBool
      }
    }

    case ON_SORT: {
      let sort_val = 'desc';
      if (action.payload === state.result_page.sort_by) {
        if (state.result_page.sort === 'desc') {
          sort_val = 'asc';
        }
      }
      return {
        ...state,
        lazyLoad: false,
        result_page: {
          total: state.result_page.total,
          per_page: state.result_page.per_page,
          current_page: state.result_page.page,
          sort_by: action.payload,
          sort: sort_val
        }
      }
    }

    case RESET_KW_STATE : {
      return {
        ...state,
        addKw: '',
        addKwisBool: 1,
        addKwField: ''
      }
    }

    case SET_SEARCH_BOOL : {
      return {
        ...state,
        filterSearchBool: action.payload
      }
    }



    default:
      return state;
  }
};

