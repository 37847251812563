  import {
  ADD_KW_LIST,
  ON_SRC_CHANGE,
  ON_KW_SRC_CHANGE,
  ON_KW_CHANGE,
  ON_FILL_CHANGE,
  ON_DELETE_KW,
  INFLUENCER_REQUEST_DATA,
  INFLUENCER_REQUEST_DATA_SUCCESS,
  INFLUENCER_REQUEST_DATA_FAILED,
  ON_RESET_LIST,
  ON_FILTER_ADD,
  ON_FILTER_DEL,
  ON_FILTER_FILL_CHANGE,
  ON_FILTER_RESET,
  ON_ADD_KW_FILL_LIST,
  ON_HANDLE_ADD_KW_CHANGE,
  ON_HANDLE_ADD_KW_CHANGE_FILL,
  CALL_LAZY_LOAD,
  SEL_ALERT,
  FILTER_EDIT_DATA,
  FILTER_UPDATE,
  ON_FILTER_DEL_GRP,
  ON_FILTER_DEL_COMP,
  SET_KW_LIST,
  EDIT_SRC_FILTER,
  ON_QUOTA_REQUST,
  ON_QUOTA_REQUST_SUCCESS,
  CLOSE_QUOTA,
  SHOW_QUOTA,
  SAVED_URL_REQUEST,
  SAVED_URL_REQUEST_SUCCESS,
  ON_SORT,
  RESET_KW_STATE,
  SET_SEARCH_BOOL
} from '../constants/ActionTypes';


export const addsrcChange = (data, iterate) => {
  return {
    type: ON_SRC_CHANGE,
    payload: {data, iterate}
  }
}

export const addKwList = () => {
  return {
    type: ADD_KW_LIST
  };
}

export const addKwChange = (data, iterate) => {
  return {
    type: ON_KW_CHANGE,
    payload: {data, iterate}
  }
}

export const addFillChange = (data, iterate) => {
  return {
    type: ON_FILL_CHANGE,
    payload: {data, iterate}
  }
}

export const deletekw = (iterate) => {
  return {
    type: ON_DELETE_KW,
    payload: {iterate}
  }
}

export const requestInfluencerResearchData = (kw_list, filter_list, page, limit, all_added = 0, src_filter, filter_part = 0, sort_by = 'reach', sort = 'desc', search_condition = 0) => {
  let req_data = kw_list;
  //'Twitter Location','Youtube Location',
  const field_src = ['Twitter Description', 'Twitter Name', 'Twitter Location', 'Reddit Description', 'Reddit User Name', 'Youtube Channel Description', 'Youtube Channel Title', 'Youtube Location', 'Instagram Description', 'Instagram User'];
  //, 'Youtube Video Title'
  //'Twitter Location': 'twt_loc','Youtube Location': 'yt_loc',
  const field_des = {'Twitter Description': 'twt_des', 'Twitter Name': 'twt_name', 'Twitter Location': 'twt_loc', 'Reddit Description': 'red_des', 'Reddit User Name': 'red_usr', 'Youtube Channel Description': 'yt_des', 'Youtube Channel Title': 'yt_title', 'Youtube Location': 'yt_loc', 'Instagram Description': 'ins_des', 'Instagram User': 'ins_user'};
  //, 'Youtube Video Title': 'yt_title_dt'

  let field_src1 = field_src.filter(function (el) {
    if (src_filter['tw'] === 0 && el.includes('Twitter')) {
      return false;
    }
    if (src_filter['reddit'] === 0 && el.includes('Reddit')) {
      return false;
    }
    if (src_filter['youtube'] === 0 && el.includes('Youtube')) {
      return false;
    }
    if (src_filter['insta'] === 0 && el.includes('Instagram')) {
      return false;
    }
    return true;
  });
  let new_kw_list = [];
  let all_kw = '';
  kw_list.map((o) => {
    if (o.src === 'All') {
      all_kw = o.kw;
      field_src1.map((data, index) => {
        //if (data !== 'Reddit Description' && data !== 'Reddit User Name' && data !== 'Youtube Channel Description' && data !== 'Youtube Video Title' && data !== 'Youtube Channel Title' && data !== 'Youtube Location') {
        new_kw_list[index] = {kw: all_kw, fill: 1, src: data, src_id: field_des[data]}
        //}

      });
      req_data = [ ...req_data, ...new_kw_list ];
    }
  });

  /*if (all_kw !== '') {
    field_src.map((data, index) => {
      new_kw_list[index] = {kw: all_kw, fill: 1, src: data, src_id: field_des[data]}
    });
    req_data = [ ...req_data, ...new_kw_list ];
  }
  */

  req_data = req_data.filter(function (el, index) { return !(el === undefined)});
  let req_data1 = req_data.filter(function (el) { return !(el['src'] === 'All')});

  let req_data2 = req_data1.filter(function (el) { return (field_src1.includes(el['src']))});
  req_data = req_data2;
  return {
    type: INFLUENCER_REQUEST_DATA,
    payload: {kw_list: req_data, filter_list: filter_list, page: page, limit: limit, filter_chk: filter_part, saved_kw_list: kw_list, sort_by: sort_by, sort: sort, search_cond: search_condition}
  };
};

export const onSuccessInfluencerResearchData = (fetchedRes) => {
  return {
    type: INFLUENCER_REQUEST_DATA_SUCCESS,
    payload: fetchedRes
  };
};

export const onFailureInfluencerResearchData = () => {
  return {
    type: INFLUENCER_REQUEST_DATA_FAILED
  };
};

export const resetList = () => {
  return {
    type: ON_RESET_LIST
  };
};

export const filterAdd = (data) => {
  return {
    type: ON_FILTER_ADD,
    payload: {data}
  };
};


export const addFilterFilChange = (data) => {
  return {
    type: ON_FILTER_FILL_CHANGE,
    payload: {data}
  }
}

export const filterReset = () => {
  return {
    type: ON_FILTER_RESET
  };
};

export const addKwsrcChange = (data) => {
  return {
    type: ON_KW_SRC_CHANGE,
    payload: {data}
  }
}

export const addKwFillList = (data) => {
  return {
    type: ON_ADD_KW_FILL_LIST,
    payload: {data}
  }
}

export const handleaddKwChange = (data) => {
  return {
    type: ON_HANDLE_ADD_KW_CHANGE,
    payload: {data}
  }
}

export const handleaddKwChangeFill = (data) => {
  return {
    type: ON_HANDLE_ADD_KW_CHANGE_FILL,
    payload: {data}
  }
}

export const filterDel = (data) => {
  return {
    type: ON_FILTER_DEL,
    payload: {data}
  };
};

export const callLazyLoad = (lazyLoad) => {
  return {
    type: CALL_LAZY_LOAD,
    payload: {
      lazy_load: lazyLoad
    }
  };
};

export const selAlert = (value) => {
  return {
    type: SEL_ALERT,
    payload: value
  };
};

export const filterEditData = (value) => {
  return {
    type: FILTER_EDIT_DATA,
    payload: value
  };
};

export const filterUpdate = () => {
  return {
    type: FILTER_UPDATE
  };
};

export const filterDelGrp = (data) => {
  return {
    type: ON_FILTER_DEL_GRP,
    payload: {data}
  };
};

export const filterDelAll = () => {
  return {
    type: ON_FILTER_DEL_COMP
  };
};

export const setKwList = (value) => {
  return {
    type: SET_KW_LIST,
    payload: value
  };
};

export const editSrcFilter = (src, value) => {
  return {
    type: EDIT_SRC_FILTER,
    payload: {src: src, val: value}
  };
};

export const quotaRequest = () => {
  return {
    type: ON_QUOTA_REQUST
  };
};

export const quotaRequestSuccess = (fetchedQuota) => {
  return {
    type: ON_QUOTA_REQUST_SUCCESS,
    payload: fetchedQuota
  };
};

export const closeQuota = () => {
  return {
    type: CLOSE_QUOTA
  };
};

export const showQuota = () => {
  return {
    type: SHOW_QUOTA
  };
};

export const savedUrlRequest = (saved_url) => {
  return {
    type: SAVED_URL_REQUEST,
    payload: saved_url
  };
};

export const savedUrlRequestSuccess = (fetched_info) => {
  return {
    type: SAVED_URL_REQUEST_SUCCESS,
    payload: fetched_info
  };
};

export const sortBy = (sort_by) => {
  return {
    type: ON_SORT,
    payload: sort_by
  };
};

export const resetKwstate = () => {
  return {
    type: RESET_KW_STATE
  };
};

export const setSearchBool = (value) => {
  return {
    type: SET_SEARCH_BOOL,
    payload: value
  };
};









