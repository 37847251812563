import {all, call, put, takeEvery} from 'redux-saga/effects';
import {
  fetchPlanSuccess,
  fetchPlanFailure,
  updateSubscriptionSuccess,
  updateSubscriptionFailure,
  whiteLabelInfoSuccess
} from 'actions/Account';
import {
  API_SUBSCRIPTION_PLANS,
  API_UPDATE_SUBSCRIPTION
} from 'constants/Endpoints';

import {
  FETCH_PLANS,
  UPDATE_SUBSCRIPTION,
  WHITE_LABEL_INFO
} from 'constants/ActionTypes';

import apiClient from 'apiclient';

const fetchSubscriptionPlansRequest = async () => {
  return await apiClient.post(API_SUBSCRIPTION_PLANS, {})
  .then(response => response.data);
};

function* fetchSubscriptionPlans() {
  try {
    const plansResponse = yield call(fetchSubscriptionPlansRequest);
    yield put(fetchPlanSuccess(plansResponse));
  } catch (error) {
    yield put(fetchPlanFailure(`Error: ${error}`));
  }
}

const updateSubscriptionRequest = async (params) => {
  return await apiClient.post(API_UPDATE_SUBSCRIPTION, params).then(response => response.data);
}

function* updateSubscription(action) {
  try {
    const updateResult = yield call(updateSubscriptionRequest, action.payload);
    yield put(updateSubscriptionSuccess(updateResult));
  } catch (error) {
    yield put(updateSubscriptionFailure(error));
  }
}

const whiteLabelInfoReq = async () => {
  return await apiClient.get('/api/white-label-info')
      .then(response => response.data);
      // .catch(error => error);
};

function* whiteLabelInfo() {
  try {
    const wl_info = yield call(whiteLabelInfoReq);
    yield put(whiteLabelInfoSuccess(wl_info));
    return wl_info;
  } catch (error) {
    yield put(fetchPlanFailure(`Error: ${error}`));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(FETCH_PLANS, fetchSubscriptionPlans),
    takeEvery(UPDATE_SUBSCRIPTION, updateSubscription),
    takeEvery(WHITE_LABEL_INFO, whiteLabelInfo)
  ]);
}
