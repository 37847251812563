import {
  ANALYTICS_REQUEST_PROJECT_GRAPH_DIST,
  ANALYTICS_PROJECT_REQUEST_GRAPH_DIST_FAILED,
  ANALYTICS_PROJECT_REQUEST_GRAPH_DIST_SUCCESS,
  ANALYTICS_REQUEST_PROJECT_LANG_DIST,
  ANALYTICS_PROJECT_REQUEST_LANG_DIST_FAILED,
  ANALYTICS_PROJECT_REQUEST_LANG_DIST_SUCCESS,
  ANALYTICS_REQUEST_PROJECT_WORD_COUNT,
  ANALYTICS_PROJECT_REQUEST_WORD_COUNT_FAILED,
  ANALYTICS_PROJECT_REQUEST_WORD_COUNT_SUCCESS,
  ANALYTICS_REQUEST_PROJECT_SUMMARY,
  ANALYTICS_PROJECT_REQUEST_SUMMARY_SUCCESS,
  ANALYTICS_PROJECT_REQUEST_SUMMARY_FAILED,
  ANALYTICS_REQUEST_PROJECT_AUTHOR,
  ANALYTICS_PROJECT_REQUEST_AUTHOR_SUCCESS,
  ANALYTICS_PROJECT_REQUEST_AUTHOR_FAILED,
  CHANGE_ACTIVE_SOURCE,
  ANALYTICS_CHANGE_DATE_RANGE,
  ANALYTICS_ON_CHANGE_AUTHOR_SORT,
  ANALYTICS_REQUEST_TWITTER_AUTHOR,
  ANALYTICS_REQUEST_REDDIT_AUTHOR,
  ANALYTICS_REQUEST_INSTA_AUTHOR,
  ANALYTICS_REQUEST_WEB_AUTHOR,
  ANALYTICS_REQUEST_HUMAN_AUTHOR,
  ANALYTICS_REQUEST_YOUTUBE_AUTHOR,
  ON_SUCCESS_ANALYTICS_REQUEST_TWITTER_AUTHOR,
  ON_SUCCESS_ANALYTICS_REQUEST_REDDIT_AUTHOR,
  ON_SUCCESS_ANALYTICS_REQUEST_INSTA_AUTHOR,
  ON_SUCCESS_ANALYTICS_REQUEST_WEB_AUTHOR,
  ON_SUCCESS_ANALYTICS_REQUEST_HUMAN_AUTHOR,
  ON_SUCCESS_ANALYTICS_REQUEST_YOUTUBE_AUTHOR,
  ANALYTICS_ON_CHANGE_KEYWORD_SELECT,
  GET_KEYWORD_NAMES,
  GET_KEYWORD_NAMES_ON_SUCCESS,
  GET_KEYWORD_NAMES_ON_FAILURE,
  VIEW_POST_BY_AUTHOR,
  CLOSE_VIEW_POST_BY_AUTHOR,
  ON_SUCCESS_POST_BY_AUTHOR,


  ANALYTICS_REQUEST_SUMMARY_GRAPH,
  ANALYTICS_REQUEST_SUMMARY_GRAPH_SUCCESS,
  ANALYTICS_REQUEST_SUMMARY_GRAPH_FAILED,
  ANALYTICS_REQUEST_SUMMARY_TOP,
  ANALYTICS_REQUEST_SUMMARY_TOP_SUCCESS,
  ANALYTICS_REQUEST_SUMMARY_TOP_FAILED,
  ANALYTICS_REQUEST_LANGUAGE_GRAPH,
  ANALYTICS_REQUEST_LANGUAGE_GRAPH_SUCCESS,
  ANALYTICS_REQUEST_LANGUAGE_GRAPH_FAILED,
  ANALYTICS_REQUEST_LOCATION_GRAPH,
  ANALYTICS_REQUEST_LOCATION_GRAPH_SUCCESS,
  ANALYTICS_REQUEST_LOCATION_GRAPH_FAILED,

  ANALYTICS_REQUEST_WORD_COUNT,
  ANALYTICS_REQUEST_WORD_COUNT_SUCCESS,
  ANALYTICS_REQUEST_WORD_COUNT_FAILED,


  ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA,
  ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA_SUCCESS,
  ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA_FAILED,
  ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA,
  ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA_SUCCESS,
  ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA_FAILED,
  ANALYTICS_REQUEST_INSTA_AUTHOR_DATA,
  ANALYTICS_REQUEST_INSTA_AUTHOR_DATA_SUCCESS,
  ANALYTICS_REQUEST_INSTA_AUTHOR_DATA_FAILED,

  ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA,
  ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA_SUCCESS,
  ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA_FAILED,

  ANALYTICS_REQUEST_WEB_AUTHOR_DATA,
  ANALYTICS_REQUEST_WEB_AUTHOR_DATA_SUCCESS,
  ANALYTICS_REQUEST_WEB_AUTHOR_DATA_FAILED,

  ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA,
  ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA_SUCCESS,
  ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA_FAILED,

  ANALYTICS_USER_PROJECT_KW_LIST,
  ANALYTICS_USER_PROJECT_KW_LIST_SUCCESS,
  ANALYTICS_USER_PROJECT_KW_LIST_FAILED,
  ANALYTICS_ON_CHANGE_KEYWORD_SELECT_NEW,
  ANALYTICS_CHANGE_DATE_RANGE_FILTER,
  SENTI_ON_CHANGE_IMPACT,
  ACTIVE_SRC_NEW,
  ON_LANG_SUBMIT_ANALYTICS,
  MULTI_CHANGE_IMPACT,
  RESET_FILTER,
  VIEW_POST_BY_AUTHOR_DATA,
  CLOSE_VIEW_POST_BY_AUTHOR_DATA,
  ON_SUCCESS_POST_BY_AUTHOR_DATA,
  ON_FILTER_SUBMIT_ANALYTICS,
  ON_FILTER_SUBMIT_ANALYTICS_SUCCESS,
  ON_FILTER_SELECT,
  GET_FILTER_LIST_ANALYTICS,
  GET_FILTER_ANALYTICS_SUCCESS,
  ON_DELETE_FILTER_ANALYTICS,
  APPLY_SAVED_FILTER,
  DETAIL_LOADER_HANDLER,
  LOADING_ENABLE,
  RESET_SELECT_FILTER,
  ON_PRE_LANG_SUBMIT,
  ADD_LANG_FILTER,
  ADD_LOC_FILTER,
  ON_LOC_SUBMIT_ANALYTICS,
  ON_PRE_LOC_SUBMIT,
  ON_EDIT_FILTER_SUBMIT,
  ON_EDIT_FILTER_SUBMIT_SUCCESS,
  DEL_LOC_FILTER,
  DEL_LANG_FILTER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  CHANGE_ANALYSIS_GRAPH,
  CHANGE_COMPETITIVE_GRAPH,
  SUMMARY_LOAD_ENABLE,
  FOCUS_INPUT,
  COLOR_CODES,
  MORE_COND_CLOSE,
  SEL_ALERT

} from 'constants/ActionTypes';
import locationData from 'app/routes/mail/data/countryListCode';
import moment from 'moment';
const INIT_STATE = {
  'loading': true,
  'hasError': false,
  'error': '',
  'loadingSummary': true,
  'hasErrorSummary': false,
  'errorSummary': '',
  'loadingAuthor': true,
  'hasErrorAuthor': false,
  'errorAuthor': '',
  'projects': [],
  'project': {
    'name': 'Loading...',
    'keywords': [],
    'created_at': null
  },
  'dist_data': [],
  'summary_data': [],
  'author_data': [],
  'projectId':0,
  'kw_id': [],
  'dateRange': {
    'selected' : {
      to: moment(),
      from: moment().subtract(60,'d'),
    }
  },
  'activeSrc': {
    'twitter': 1,
    'web': 1,
    'facebook': 1,
    'youtube': 1,
    'quora' : 1,
    'reddit': 1,
    'forum' : 1,
    'instagram': 1,
    'news': 1,
    'podcast': 1,
    'tv': 1,
    'radio': 1,
    'linkedin': 1
  },
  showInluencerPosts:{
    enabled: false,
    loading: true,
    author: {
      name: 'John Doe',
    },
    ikey: '',
    totalPosts: 0,
    currentPage: 0,
    perpage: 5,
    postList: []
  },
  influencers: {
    twitter: {
      loading: true,
      sorts: {
        selected: 'fcnt',
        options: [
          {
            name: 'Mentions',
            value: 'posts'
          },
          {
            name: 'Followers',
            value: 'fcnt'
          }
        ]
      },
      list: [],
      total: 0,
      currentPage: 0,
      perpage: 5
    },
    reddit: {
      loading: true,
      sorts: {
        selected: 'karma',
        options: [
          {
            name: 'Mentions',
            value: 'posts'
          },
          {
            name: 'Karma',
            value: 'karma'
          }
        ]
      },
      list: [],
      total: 0,
      currentPage: 0,
      perpage: 5
    },
    insta: {
      loading: true,
      sorts: {
        selected: 'followers',
        options: [
          {
            name: 'Mentions',
            value: 'posts'
          },
          {
            name: 'Followers',
            value: 'followers'
          }
        ]
      },
      list: [],
      total: 0,
      currentPage: 0,
      perpage: 5
    },
    web: {
      loading: true,
      sorts: {
        selected: 'kwrank',
        options: [
          {
            name: 'Mentions',
            value: 'posts'
          },
          {
            name: 'Ranking Keywords',
            value: 'kwrank'
          }
        ]
      },
      list: [],
      total: 0,
      currentPage: 0,
      perpage: 5
    },
    human: {
      loading: true,
      sorts: {
        selected: 'posts',
        options: [
          {
            name: 'Mentions',
            value: 'posts'
          }
        ]
      },
      list: [],
      total: 0,
      currentPage: 0,
      perpage: 5
    },
    youtube: {
      loading: true,
      sorts: {
        selected: 'subscriber_count',
        options: [
          {
            name: 'Mentions',
            value: 'posts'
          },
          {
            name: 'Subscribers',
            value: 'subscriber_count'
          }
        ]
      },
      list: [],
      total: 0,
      currentPage: 0,
      perpage: 5
    }
  },
  'loadingKw': true,
  'hasErrorKw': false,
  'errorKw': '',
  'kwName' : [],
  kwId: [],
  'keywords' : [],
  'loadingLang': true,
  'hasLangError': false,
  'lang_dist_data': [],
  'langError': '',
  'loadingWordCount': true,
  'hasWordCountError': false,
  'wordClouderror': false,
  'word_count_data': [],
  'pro_runnow': 0,
  'kw_runnow':0,
  'font_check':0,
  yt_name: '',


  'summary_graph_data': {
    'senti_graph': {
      "data": [
          /*
          {"name": "dummy", "Positive": 10, "Negative": 10, "Neutral": 10, "Unknown": 10},
          {"name": "dummy1", "Positive": 10, "Negative": 10, "Neutral": 10, "Unknown": 10}
          */
      ],
      "type": "bar",
      "size": 40,
      "datakey": [
        {"key": "Positive", "fill": "#60C087"},
        {"key": "Negative", "fill": "#CE4F5A"},
        {"key": "Neutral", "fill": "#E4A450"},
        {"key": "Unknown", "fill": "#CCCCCC"},
      ]
    },
    'src_graph': {
      "data": [
          /*
          {"name": "dummy", "Twitter": 10, "Web": 10, "Facebook": 10, "Youtube": 10, "Quora": 10, "Reddit": 10, "Forum": 10, "Instagram": 10, "News": 10, "Podcast": 10, "TV": 10, "Radio": 10, "Linkedin": 10},
          {"name": "dummy1", "Twitter": 10, "Web": 10, "Facebook": 10, "Youtube": 10, "Quora": 10, "Reddit": 10, "Forum": 10, "Instagram": 10, "News": 10, "Podcast": 10, "TV": 10, "Radio": 10, "Linkedin": 10}
          */
      ],
      "type": "bar",
      "size": 40,
      "datakey": [
        {"key": "Twitter", "fill": "#60C087"},
        {"key": "Web", "fill": "#60C087"},
        {"key": "Facebook", "fill": "#60C087"},
        {"key": "Youtube", "fill": "#60C087"},
        {"key": "Quora", "fill": "#60C087"},
        {"key": "Reddit", "fill": "#60C087"},
        {"key": "Forum", "fill": "#60C087"},
        {"key": "Instagram", "fill": "#60C087"},
        {"key": "News", "fill": "#60C087" },
        {"key": "Podcast", "fill": "#60C087"},
        {"key": "TV", "fill": "#60C087"},
        {"key": "Radio", "fill": "#60C087"},
        {"key": "Linkedin", "fill": "#60C087"}
      ]
    },
    'lang_graph': {},
    'loc_graph': {},
    'date_graph': {}
  },
  'active_analysis': 'senti_graph',
  'active_competitive': 'mentions',
  'loadingSummaryGraph': true,
  'hasSummaryGraphError': false,
  'summaryGrapherror': '',
  'summary_top_data': {
    'summary_graph': {},
    'mentions' :0,
    'authors': 0,
    'site': 0
  },
  'loading_summary_top_data': true,
  'has_error_summary_top_data': false,
  'err_summary_top_data': '',
  'loadingLanguageGraph': true,
  'hasLanguageGraphError': false,
  'languageGrapherror': '',
  'loadingLocationGraph': true,
  'hasLocationGraphError': false,
  'locationGrapherror': '',
  'UserProKwList' : [],
  'kwNameNew' : [],

  moduleAct: {"tw":"0","web":"0","fb":"0","yt":"0","qr":"0","rt":"0","fr":"0","ig":"0","nw":"0","pd":"0","rd":"0","tv":"0","ln":"0"},
  sentiPos: true,
  sentiNeg: true,
  sentiNet: true,
  sentiUnk: true,
  trueTotal: 0,
  checkReset: true,
  langList: [],
  prelangList: [],
  locList: [],
  prelocList: [],
  reach: 0,
  activeSrcFilter: {'twitter':1, 'web':1, 'facebook':1, 'youtube': 1, 'quora' : 1, 'reddit': 1, 'forum' : 1, 'instagram': 1, 'news': 1, 'podcast': 1, 'tv': 1, 'radio': 1, 'linkedin': 1, 'lqp': 0, 'all':1},
  pending_value: [],
  pending_value_del: [],
  checked_list: {},
  //more_number: 0,
  min_sel: 1,
  params_data: [],
  preload_kw: {},
  detailLoader: true,
  selFilterName: '',
  selFilterId: 0,
  selFilterNameAdded: '',
  langSelectorCnt: 1,
  locSelectorCnt: 1,
  alertMessage: '',
  showMessage: false,
  saved_summary_result: [],
  saved_top_result: [],
  kw_color_alloc: [],
  kw_color_alloc_kw: [],
  autoOpen: true,
  kwChange: 0,
  data_total: 0,
  more_cond: 0,
  sel_alert_cond: 0

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ANALYTICS_REQUEST_PROJECT_GRAPH_DIST: {
      return {
        ...state,
        loading: true,
        hasError: false,
        error: '',
        dist_data : []
      }
    }

    case ANALYTICS_PROJECT_REQUEST_GRAPH_DIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        hasError: false,
        error: '',
        dist_data: action.payload.result
      }
    }

    case ANALYTICS_PROJECT_REQUEST_GRAPH_DIST_FAILED: {
      return {
        ...state,
        loading: false,
        hasError: true,
        error: '',
        dist_data : []
      }
    }


    case ANALYTICS_REQUEST_PROJECT_SUMMARY: {
      return {
        ...state,
        loadingSummary: true,
        hasErrorSummary: false,
        errorSummary: '',
        summary_data : []
      }
    }

    case ANALYTICS_PROJECT_REQUEST_SUMMARY_SUCCESS: {
      return {
        ...state,
        loadingSummary: false,
        hasErrorSummary: false,
        errorSummary: '',
        summary_data: action.payload.result
      }
    }

    case ANALYTICS_PROJECT_REQUEST_SUMMARY_FAILED: {
      return {
        ...state,
        loadingSummary: false,
        hasErrorSummary: true,
        errorSummary: '',
        summary_data : []
      }
    }


    case ANALYTICS_REQUEST_PROJECT_AUTHOR: {
      return {
        ...state,
        loadingAuthor: true,
        hasErrorAuthor: false,
        errorAuthor: '',
        author_data : []
      }
    }

    case ANALYTICS_PROJECT_REQUEST_AUTHOR_SUCCESS: {
      let twitterInfluencers = [];
      let redditInfluencers = [];
      let instaInfluencers = [];
      let webInfluencers = [];
      let humanInfluencers = [];
      let youtubeInfluencers = [];
      let twTotal = 0;
      let redTotal = 0;
      let inTotal = 0;
      let webTotal = 0;
      let humanTotal = 0;
      let twCurPage = 0;
      let redCurPage = 0;
      let inCurPage = 0;
      let webCurPage = 0;
      let humanCurPage = 0;
      let youtubeTotal = 0;
      let youtubeCurPage = 0;
      if (action.payload.length > 0) {
        action.payload.map((response) => {
          if (response.status !== undefined && response.status.error === false) {

            // Now handle other responses for other sources like instagram this way:
            if (response.endpoint !== undefined && response.endpoint === 'sources.twitter.author') {
              twitterInfluencers = response.result.data.map((author) => {
                return {
                  name: author.name ? author.name : `@${author.author}`,
                  sname: author.sname ? author.sname : author.author,
                  posts: author.posts,
                  author: author.author,
                  fcnt: author.fcnt,
                  pic: author.pimg ? author.pimg : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
                };
              });
              twTotal = response.result.rows_before_limit_at_least;
              twCurPage = response.page;
            } else if (response.endpoint !== undefined && response.endpoint === 'sources.reddit.author') {
              redditInfluencers = response.result.data.map((author) => {
                return {
                  name: author.name ? author.name : `u/${author.author}`,
                  sname: author.sname ? author.sname : author.author,
                  posts: author.posts,
                  author: author.author,
                  karma: author.karma,
                  pic: author.pimg ? author.pimg : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
                };
              });
              redTotal = response.result.rows_before_limit_at_least;
              redCurPage = response.page;
            } else if (response.endpoint !== undefined && response.endpoint === 'sources.instagram.author') {
              instaInfluencers = response.result.data.map((author) => {
                return {
                  name: author.user ? author.user : `${author.author}`,
                  sname: author.user ? author.user : author.author,
                  posts: author.posts,
                  author: author.author,
                  followers: author.followers,
                  pic: author.img_path ? author.img_path : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
                };
              });
              inTotal = response.result.rows_before_limit_at_least;
              inCurPage = response.page;
            } else if (response.endpoint !== undefined && response.endpoint === 'sources.web.author') {
              webInfluencers = response.result.data.map((author) => {
                return {
                  name: author.domain ? author.domain : `${author.author}`,
                  sname: author.domain ? author.doamin : author.author,
                  posts: author.posts,
                  author: author.author,
                  kwrank: author.kwrank,
                  pic: author.ficon ? author.ficon : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
                };
              });
              webCurPage = response.page;
              webTotal = response.result.rows_before_limit_at_least;
            } else if (response.endpoint !== undefined && response.endpoint === 'sources.human.author') {
              humanInfluencers = response.result.data.map((author) => {
                return {
                  name: author.author ? author.author : ``,
                  posts: author.posts,
                  author: author.author,
                  pic: 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
                };
              });
              humanCurPage = response.page;
              humanTotal = response.result.rows_before_limit_at_least;
            } else if (response.endpoint !== undefined && response.endpoint === 'sources.youtube.author') {
              youtubeInfluencers = response.result.data.map((author) => {
                return {
                  name: author.title ? author.title : `${author.author}`,
                  sname: author.channel ? author.channel : author.author,
                  posts: author.posts,
                  author: author.title ? author.title : author.author,
                  authid: author.authid,
                  subscribers: author.subscriber_count,
                  pic: author.thumbnail_src ? author.thumbnail_src : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
                };
              });
              youtubeCurPage = response.page;
              youtubeTotal = response.result.rows_before_limit_at_least;
            }

          }
        });
      } else {
        twitterInfluencers = [];
        redditInfluencers = [];
        instaInfluencers = [];
        webInfluencers = [];
        humanInfluencers = [];
        youtubeInfluencers = [];
        twTotal = 0;
        redTotal = 0;
        inTotal = 0;
        webTotal = 0;
        humanTotal = 0;
        twCurPage = 0;
        redCurPage = 0;
        inCurPage = 0;
        webCurPage = 0;
        humanCurPage = 0;
        youtubeCurPage = 0;
        youtubeTotal = 0;
      }
      return {
        ...state,
        loadingAuthor: false,
        hasErrorAuthor: false,
        errorAuthor: '',
        influencers: {...state.influencers,
          twitter: { ...state.influencers.twitter,
            loading: false,
            list: twitterInfluencers,
            total: twTotal,
            currentPage: twCurPage,
            perpage: 5
          }, reddit: { ...state.influencers.reddit,
            loading: false,
            list: redditInfluencers,
            total: redTotal,
            currentPage: redCurPage,
            perpage: 5
          }, insta: { ...state.influencers.insta,
            loading: false,
            list: instaInfluencers,
            total: inTotal,
            currentPage: inCurPage,
            perpage: 5
          }, web: { ...state.influencers.web,
            loading: false,
            list: webInfluencers,
            total: webTotal,
            currentPage: webCurPage,
            perpage: 5
          }, human: { ...state.influencers.human,
            loading: false,
            list: humanInfluencers,
            total: humanTotal,
            currentPage: humanCurPage,
            perpage: 5
          }, youtube: { ...state.influencers.youtube,
            loading: false,
            list: youtubeInfluencers,
            total: youtubeTotal,
            currentPage: youtubeCurPage,
            perpage: 5
          }
        },
        author_data: action.payload[0].result
      }
    }

    case ANALYTICS_PROJECT_REQUEST_AUTHOR_FAILED: {
      return {
        ...state,
        loadingAuthor: false,
        hasErrorAuthor: true,
        errorAuthor: '',
        author_data : []
      }
    }

    case CHANGE_ACTIVE_SOURCE: {
      return {
        ...state,
        activeSrc: action.payload.srcs
      }
    }

    case ANALYTICS_CHANGE_DATE_RANGE: {
      let newRange = {
        from: action.payload.newRange.start,
        to: action.payload.newRange.end
      };
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          selected: newRange
        }
      }
    }

    case ANALYTICS_ON_CHANGE_AUTHOR_SORT: {
      let newInfluencers = state.influencers;
      if (action.payload.ikey === 'instagram')
        action.payload.ikey = 'insta';
      if (newInfluencers[action.payload.ikey] !== undefined) {
        newInfluencers[action.payload.ikey].sorts.selected = action.payload.value;
        newInfluencers[action.payload.ikey].loading = true;
        newInfluencers[action.payload.ikey].list = [];
      }
      return {
        ...state,
        influencers: newInfluencers
      };
    }


    case ANALYTICS_REQUEST_TWITTER_AUTHOR : {
        return {
          ...state,
          influencers: {
            ...state.influencers,
            twitter: {
              ...state.influencers.twitter,
              list: [],
              loading: true,
              total: 0,
              currentPage: 0,
              perpage: 5
            }
          }
        }
    }

    case ON_SUCCESS_ANALYTICS_REQUEST_TWITTER_AUTHOR: {
      let newInfluencer = [];
      let response = action.payload;
      let twTotal = 0;
      let twCurPage = 0;
      if (response.status !== undefined && response.status.error === false) {
        // Now handle other responses for other sources like instagram this way:
        if (response.endpoint !== undefined && response.endpoint === 'sources.twitter.author') {
          newInfluencer = response.result.data.map((author) => {
            return {
              name: author.name ? author.name : `@${author.author}`,
              sname: author.sname ? author.sname : author.author,
              posts: author.posts,
              author: author.author,
              fcnt: author.fcnt,
              pic: author.pimg ? author.pimg : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
            };
          });
          twTotal = response.result.rows_before_limit_at_least;
          twCurPage = response.page;
        }
      }

      return {
        ...state,
        influencers: {
          ...state.influencers,
          twitter: {
            ...state.influencers.twitter,
            list: newInfluencer,
            loading: false,
            total: twTotal,
            currentPage: twCurPage,
            perpage: 5
          }
        }
      }
    }

    case ANALYTICS_REQUEST_REDDIT_AUTHOR : {
        return {
          ...state,
          influencers: {
            ...state.influencers,
            reddit: {
              ...state.influencers.reddit,
              list: [],
              loading: true,
              total: 0,
              currentPage: 0,
              perpage: 5
            }
          }
        }
    }

    case ON_SUCCESS_ANALYTICS_REQUEST_REDDIT_AUTHOR: {
      let newInfluencer = [];
      let response = action.payload;
      let redTotal = 0;
      let redCurPage = 0;
      if (response.status !== undefined && response.status.error === false) {
        // Now handle other responses for other sources like instagram this way:
        if (response.endpoint !== undefined && response.endpoint === 'sources.reddit.author') {
          newInfluencer = response.result.data.map((author) => {
            return {
              name: author.name ? author.name : `u/${author.author}`,
              sname: author.sname ? author.sname : author.author,
              posts: author.posts,
              author: author.author,
              karma: author.karma,
              pic: author.pimg ? author.pimg : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
            };
          });
          redTotal = response.result.rows_before_limit_at_least;
          redCurPage = response.page;
        }
      }
      return {
        ...state,
        influencers: {
          ...state.influencers,
          reddit: {
            ...state.influencers.reddit,
            list: newInfluencer,
            loading: false,
            total: redTotal,
            currentPage: redCurPage,
            perpage: 5
          }
        }
      }
    }

    case ANALYTICS_REQUEST_INSTA_AUTHOR : {
        return {
          ...state,
          influencers: {
            ...state.influencers,
            insta: {
              ...state.influencers.insta,
              list: [],
              loading: true,
              total: 0,
              currentPage: 0,
              perpage: 5
            }
          }
        }
    }

    case ON_SUCCESS_ANALYTICS_REQUEST_INSTA_AUTHOR: {
      let newInfluencer = [];
      let response = action.payload;
      let inTotal = 0;
      let inCurPage = 0;
      if (response.status !== undefined && response.status.error === false) {
        // Now handle other responses for other sources like instagram this way:
        if (response.endpoint !== undefined && response.endpoint === 'sources.instagram.author') {
          newInfluencer = response.result.data.map((author) => {
            return {
              name: author.user ? author.user : `${author.author}`,
              sname: author.user ? author.user : author.author,
              posts: author.posts,
              author: author.author,
              followers: author.followers,
              pic: author.img_path ? author.img_path : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
            };
          });
          inTotal = response.result.rows_before_limit_at_least;
          inCurPage = response.page;
        }
      }
      return {
        ...state,
        influencers: {
          ...state.influencers,
          insta: {
            ...state.influencers.insta,
            list: newInfluencer,
            loading: false,
            total: inTotal,
            currentPage: inCurPage,
            perpage: 5
          }
        }
      }
    }

    case ANALYTICS_REQUEST_WEB_AUTHOR : {
        return {
          ...state,
          influencers: {
            ...state.influencers,
            web: {
              ...state.influencers.web,
              list: [],
              loading: true,
              total: 0,
              currentPage: 0,
              perpage: 5
            }
          }
        }
    }

    case ON_SUCCESS_ANALYTICS_REQUEST_WEB_AUTHOR: {
      let newInfluencer = [];
      let response = action.payload;
      let webTotal = 0;
      let webCurPage = 0;
      if (response.status !== undefined && response.status.error === false) {
        // Now handle other responses for other sources like instagram this way:
        if (response.endpoint !== undefined && response.endpoint === 'sources.web.author') {
          newInfluencer = response.result.data.map((author) => {
            return {
              name: author.domain ? author.domain : `${author.author}`,
              sname: author.domain ? author.doamin : author.author,
              posts: author.posts,
              author: author.author,
              kwrank: author.kwrank,
              pic: author.ficon ? author.ficon : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
            };
          });
          webTotal = response.result.rows_before_limit_at_least;
          webCurPage = response.page;
        }
      }
      return {
        ...state,
        influencers: {
          ...state.influencers,
          web: {
            ...state.influencers.web,
            list: newInfluencer,
            loading: false,
            total: webTotal,
            currentPage: webCurPage,
            perpage: 5
          }
        }
      }
    }

    case ANALYTICS_REQUEST_HUMAN_AUTHOR : {
      return {
        ...state,
        influencers: {
          ...state.influencers,
          human: {
            ...state.influencers.human,
            list: [],
            loading: true,
            total: 0,
            currentPage: 0,
            perpage: 5
          }
        }
      }
    }

  case ON_SUCCESS_ANALYTICS_REQUEST_HUMAN_AUTHOR: {
    let newInfluencer = [];
    let response = action.payload;
    let humanTotal = 0;
    let humanCurPage = 0;
    if (response.status !== undefined && response.status.error === false) {
      // Now handle other responses for other sources like instagram this way:
      if (response.endpoint !== undefined && response.endpoint === 'sources.human.author') {
        newInfluencer = response.result.data.map((author) => {
          return {
            name: author.author ? author.author : ``,
            posts: author.posts,
            author: author.author,
            pic: 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
          };
        });
        humanTotal = response.result.rows_before_limit_at_least;
        humanCurPage = response.page;
      }
    }
    return {
      ...state,
      influencers: {
        ...state.influencers,
        human: {
          ...state.influencers.human,
          list: newInfluencer,
          loading: false,
          total: humanTotal,
          currentPage: humanCurPage,
          perpage: 5
        }
      }
    }
  }

    case ANALYTICS_REQUEST_YOUTUBE_AUTHOR : {
      return {
        ...state,
        influencers: {
          ...state.influencers,
          youtube: {
            ...state.influencers.youtube,
            list: [],
            loading: true,
            total: 0,
            currentPage: 0,
            perpage: 5
          }
        }
      }
  }

  case ON_SUCCESS_ANALYTICS_REQUEST_YOUTUBE_AUTHOR: {
    let newInfluencer = [];
    let response = action.payload;
    let youtubeTotal = 0;
    let youtubeCurPage = 0;
    if (response.status !== undefined && response.status.error === false) {
      // Now handle other responses for other sources like instagram this way:
      if (response.endpoint !== undefined && response.endpoint === 'sources.youtube.author') {
        newInfluencer = response.result.data.map((author) => {
          return {
            name: author.title ? author.title : `${author.author}`,
            sname: author.channel ? author.channel : author.author,
            posts: author.posts,
            author: author.title ? author.title : author.author,
            subscribers: author.subscriber_count,
            authid: author.authid,
            pic: author.thumbnail_src ? author.thumbnail_src : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
          };
        });
        youtubeTotal = response.result.rows_before_limit_at_least;
        youtubeCurPage = response.page;
      }
    }
    return {
      ...state,
      influencers: {
        ...state.influencers,
        youtube: {
          ...state.influencers.youtube,
          list: newInfluencer,
          loading: false,
          total: youtubeTotal,
          currentPage: youtubeCurPage,
          perpage: 5
        }
      }
    }
  }

    case ANALYTICS_ON_CHANGE_KEYWORD_SELECT: {
      let filtered_kw_ids = [];
      action.payload.kw.map((kw) => {
        var index = state.keywords.map(function(o) { return o.user_kw; }).indexOf(kw);
        if (index !== -1) {
          filtered_kw_ids.push(state.keywords[index]['kw_id']);
        }
      });
      return {
        ...state,
        kwName : action.payload.kw,
        kwId : filtered_kw_ids
      };
    }

    case GET_KEYWORD_NAMES: {
      return {
        ...state,
        loadingKw: true,
        hasErrorKw: false,
        errorKw: '',
        keywords : [],
        kwId: [],
        kwName: []
      }
    }

    case GET_KEYWORD_NAMES_ON_SUCCESS: {
      let sel_kw = [];
      let sel_kw_id = [];
      if (action.payload.names.result.length > 0 && action.payload.selected_kw.length > 0) {
        action.payload.names.result.map((response) => {
          if (response.kw_id == action.payload.selected_kw) {
            sel_kw.push(response.user_kw);
            sel_kw_id.push(response.kw_id)
          }
        });
      }
      return {
        ...state,
        loadingKw: false,
        hasErrorKw: false,
        errorKw: '',
        keywords: action.payload.names.result,
        kwId: sel_kw_id.length > 0 ? sel_kw_id : [],
        kwName: sel_kw.length >0? sel_kw: []
      }
    }

    case GET_KEYWORD_NAMES_ON_FAILURE: {
      return {
        ...state,
        loadingKw: false,
        hasErrorKw: true,
        errorKw: '',
        keywords : [],
        kwId: [],
        kwName: []
      }
    }

    case VIEW_POST_BY_AUTHOR: {
      return {
        ...state,
        yt_name: action.payload.yt_name,
        showInluencerPosts: {
          ...state.showInluencerPosts,
          enabled: true,
          loading:true,
          author: {
            name: action.payload.author
          },
          ikey: action.payload.ikey,
          totalPosts: 0,
          currentPage: 0,
          postList: []
        }
      }
    }
    case CLOSE_VIEW_POST_BY_AUTHOR: {
      return {
        ...state,
        yt_name:'',
        showInluencerPosts: {
          ...state.showInluencerPosts,
          enabled: false,
          loading:true,
          author: {
            name: ''
          },
          ikey: '',
          totalPosts: 0,
          currentPage: 0,
          postList: []
        }
      };
    }

    case ON_SUCCESS_POST_BY_AUTHOR: {
      return {
        ...state,
        showInluencerPosts: {
          ...state.showInluencerPosts,
          enabled: true,
          loading: false,
          totalPosts: action.payload.total,
          currentPage: action.payload.current_page - 1,
          postList: action.payload.data
        }
      };
    }

    case ANALYTICS_REQUEST_PROJECT_LANG_DIST: {
      return {
        ...state,
        loadingLang: true,
        hasLangError: false,
        langError: '',
        lang_dist_data : []
      }
    }

    case ANALYTICS_PROJECT_REQUEST_LANG_DIST_SUCCESS: {
      return {
        ...state,
        loadingLang: false,
        hasLangError: false,
        langError: '',
        lang_dist_data: action.payload.result
      }
    }

    case ANALYTICS_PROJECT_REQUEST_LANG_DIST_FAILED: {
      return {
        ...state,
        loadingLang: false,
        hasLangError: true,
        langError: '',
        lang_dist_data : []
      }
    }


    case ANALYTICS_REQUEST_PROJECT_WORD_COUNT: {
      return {
        ...state,
        loadingWordCount: true,
        hasWordCountError: false,
        word_count_data : []
      }
    }

    case ANALYTICS_PROJECT_REQUEST_WORD_COUNT_SUCCESS: {
      return {
        ...state,
        loadingWordCount: false,
        hasWordCountError: false,
        word_count_data: action.payload.result,
        pro_runnow: action.payload.pro_runnow,
        kw_runnow: action.payload.kw_runnow,
        font_check: action.payload.font_check
      }
    }

    case ANALYTICS_PROJECT_REQUEST_WORD_COUNT_FAILED: {
      return {
        ...state,
        loadingWordCount: false,
        hasWordCountError: true,
        word_count_data : []
      }
    }

    case ANALYTICS_REQUEST_SUMMARY_GRAPH: {
      return {
        ...state,
        loadingSummaryGraph: true,
        hasSummaryGraphError: false,
        summaryGrapherror: ''
      }
    }

    case ANALYTICS_REQUEST_SUMMARY_GRAPH_SUCCESS: {
      let result = action.payload.result;
      let senti_graph_data = {"data": [], "type": "bar", "size": 40, "datakey": []};
      let inc = 0;
      if (result['kw_dist_senti'] !== undefined) {
        for (const [key, value] of Object.entries(result['kw_dist_senti'])) {

          senti_graph_data['data'].push({"name": key});

          if (value['senti']['Positive'] !== undefined) {
            senti_graph_data['data'][inc]['Positive'] = value['senti']['Positive'];
            if (inc == 0)
              senti_graph_data['datakey'].push({"key": "Positive", "fill": "#60C087"});
          }

          if (value['senti']['Negative'] !== undefined) {
            senti_graph_data['data'][inc]['Negative'] = value['senti']['Negative'];
            if (inc == 0)
              senti_graph_data['datakey'].push({"key": "Negative", "fill": "#CE4F5A"});
          }

          if (value['senti']['Neutral'] !== undefined) {
            senti_graph_data['data'][inc]['Neutral'] = value['senti']['Neutral'];
            if (inc == 0)
              senti_graph_data['datakey'].push({"key": "Neutral", "fill": "#E4A450"});
          }

          if (value['senti']['Unknown'] !== undefined) {
            senti_graph_data['data'][inc]['Unknown'] = value['senti']['Unknown'];
            if (inc == 0)
              senti_graph_data['datakey'].push({"key": "Unknown", "fill": "#CCCCCC"}) ;
          }
          inc++;
        };
      }


      let src_graph_data = {"data": [], "type": "bar", "size": 40, "datakey": []};

      let inc_src = 0;
      if (result['kw_dist_src'] !== undefined) {
        for (const [key, value] of Object.entries(result['kw_dist_src'])) {

          src_graph_data['data'].push({"name": key});

          if (value['src']['Twitter'] !== undefined) {
            src_graph_data['data'][inc_src]['Twitter'] = value['src']['Twitter'];
            if (inc_src == 0)
              src_graph_data['datakey'].push({"key": "Twitter", "fill": "#38A1F3"});
          }

          if (value['src']['Web'] !== undefined) {
            src_graph_data['data'][inc_src]['Web'] = value['src']['Web'];
            if (inc_src == 0)
              src_graph_data['datakey'].push({"key": "Web", "fill": "#43A047"});
          }

          if (value['src']['Facebook'] !== undefined) {
            src_graph_data['data'][inc_src]['Facebook'] = value['src']['Facebook'];
            if (inc_src == 0)
              src_graph_data['datakey'].push({"key": "Facebook", "fill": "#303F9F"});
          }

          if (value['src']['Youtube'] !== undefined) {
            src_graph_data['data'][inc_src]['Youtube'] = value['src']['Youtube'];
            if (inc_src == 0)
              src_graph_data['datakey'].push({"key": "Youtube", "fill": "#763636"}) ;
          }

          if (value['src']['Quora'] !== undefined) {
            src_graph_data['data'][inc_src]['Quora'] = value['src']['Quora'];
            if (inc_src == 0)
              src_graph_data['datakey'].push({"key": "Quora", "fill": "#AA2200"}) ;
          }

          if (value['src']['Reddit'] !== undefined) {
            src_graph_data['data'][inc_src]['Reddit'] = value['src']['Reddit'];
            if (inc_src == 0)
              src_graph_data['datakey'].push({"key": "Reddit", "fill": "#FF4301"}) ;
          }

          if (value['src']['Forum'] !== undefined) {
            src_graph_data['data'][inc_src]['Forum'] = value['src']['Forum'];
            if (inc_src == 0)
              src_graph_data['datakey'].push({"key": "Forum", "fill": "#6D4C41"}) ;
          }

          if (value['src']['Instagram'] !== undefined) {
            src_graph_data['data'][inc_src]['Instagram'] = value['src']['Instagram'];
            if (inc_src == 0)
              src_graph_data['datakey'].push({"key": "Instagram", "fill": "#833AB4"}) ;
          }

          if (value['src']['News'] !== undefined) {
            src_graph_data['data'][inc_src]['News'] = value['src']['News'];
            if (inc_src == 0)
              src_graph_data['datakey'].push({"key": "News", "fill": "#61D035"}) ;
          }

          if (value['src']['Podcast'] !== undefined) {
            src_graph_data['data'][inc_src]['Podcast'] = value['src']['Podcast'];
            if (inc_src == 0)
              src_graph_data['datakey'].push({"key": "Podcast", "fill": "#fe7402"}) ;
          }

          if (value['src']['TV'] !== undefined) {
            src_graph_data['data'][inc_src]['TV'] = value['src']['TV'];
            if (inc_src == 0)
              src_graph_data['datakey'].push({"key": "TV", "fill": "#707070"}) ;
          }

          if (value['src']['Radio'] !== undefined) {
            src_graph_data['data'][inc_src]['Radio'] = value['src']['Radio'];
            if (inc_src == 0)
              src_graph_data['datakey'].push({"key": "Radio", "fill": "#983c1f"}) ;
          }

          if (value['src']['Linkedin'] !== undefined) {
            src_graph_data['data'][inc_src]['Linkedin'] = value['src']['Linkedin'];
            if (inc_src == 0)
              src_graph_data['datakey'].push({"key": "Linkedin", "fill": "#1066c2"}) ;
          }

          if (value['src']['LQP'] !== undefined) {
            src_graph_data['data'][inc_src]['LQP'] = value['src']['LQP'];
            if (inc_src == 0)
              src_graph_data['datakey'].push({"key": "LQP", "fill": "#FF6200"}) ;
          }
          inc_src++;
        };
      }
      /*
      let summary_donut_graph_data = {"data": [], "options": []};

      let summary_data = [];
      let data_inner = [];
      let inc_sum = 0;
      let color_ar = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a'];
      let sel_color = [];
      let load_graph_donut = 'kw_dist_total';
      if (state.active_competitive === 'mentions') {
        load_graph_donut = 'kw_dist_total';
      }
      else if (state.active_competitive === 'authors') {
        load_graph_donut = 'kw_dist_auth_total';
      }
      else if (state.active_competitive === 'sites') {
        load_graph_donut = 'kw_dist_sites_total';
      }
      for (const [key, value] of Object.entries(result[load_graph_donut])) {
        if (data_inner.length === 0) {
          data_inner.push({key: key, value: value.total});
        }
        else if (((data_inner[0]['value'] !== undefined) && (data_inner[0]['value'] < value.total))) {
          data_inner[0]['key'] = key;
          data_inner[0]['value'] = value.total;
        }
        summary_data.push({key: key, value: value.total, color: color_ar[inc_sum]});
        sel_color[key] = color_ar[inc_sum];
        inc_sum++;
      };
      if (summary_data.length > 0) {
        const background_color = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a'];
        const border_color = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a'];
        const hover_back_color = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a'];
        const donutGraphdata = (canvas) => {
          const filter_labels = [];
          const filter_count = [];
          const background_color_filter = [];
          const border_color_filter = [];
          const hover_back_color_filter = [];

          summary_data.map((data, index) => {
              filter_labels.push(data.key);
              filter_count.push(data.value);
              background_color_filter.push(background_color[index]);
              border_color_filter.push(border_color[index]);
              hover_back_color_filter.push(hover_back_color[index]);
              return data;
          });

          const ctx = canvas.getContext("2d");
          const _stroke = ctx.stroke;
          ctx.stroke = function () {
            ctx.save();
            ctx.shadowColor = '';
            ctx.shadowBlur = 25;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 12;
            _stroke.apply(this, arguments);
            ctx.restore();
          };
          return {
            labels: filter_labels,
            datasets: [{
              data: filter_count,
              backgroundColor: background_color_filter,
              borderColor: border_color_filter,
              hoverBackgroundColor: hover_back_color_filter,
            }]
          }
        };

        const optionsDonut = {
          legend: {
            display: false,
            position: "bottom",
            labels: {
              cursor: 'pointer'
            }
          },
          datalabels: {
            display: true,
            color: "#000",
          },
          layout: {
            padding: {
              bottom: 20
            }
          },
          cutoutPercentage: 65,
          borderWidth: 0
        };

        summary_donut_graph_data['data'] = donutGraphdata;
        summary_donut_graph_data['options'] = optionsDonut;
        summary_donut_graph_data['data_inner'] = data_inner;
      }
      */
      let summary_date_graph_data = {"data": [], "type": "bar", "size": 40, "datakey": []};

      let inc_date = 0;
      let keys = [];
      let color_inc = 0;
      let color_arr = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a'];
      let assign_key = '';
      let color_inherit = '';
      let load_graph = 'date_dist';
      //let sel_color = [];
      if (state.active_competitive === 'mentions') {
        load_graph = 'date_dist';
      }
      else if (state.active_competitive === 'authors') {
        load_graph = 'date_dist_auth';
      }
      else if (state.active_competitive === 'sites') {
        load_graph = 'date_dist_sites';
      }
      if (result[load_graph] !== undefined) {
        for (const [key, value] of Object.entries(result[load_graph])) {
          summary_date_graph_data['data'].push({"name": key});
          for (const [key1, value1] of Object.entries(value)) {
            assign_key = key1.toString();
            summary_date_graph_data['data'][inc_date][assign_key] = value1;
            if (keys.includes(assign_key) === false) {
              keys.push(assign_key);
              /*
              if (state.kw_color_alloc[assign_key] !== undefined) {
                color_inherit = state.kw_color_alloc[assign_key];
              }
              else {
                let cc = 0;
                if (state.kw_color_alloc.length > 0) {

                  state.kw_color_alloc.map((data, index) => {
                    if (data === color_arr[color_inc]) {
                      cc = 1;
                    }
                  });
                }
                if (cc === 0) {
                  color_inherit = color_arr[color_inc];
                }
                else {
                  let cc1 = 0;
                  if (state.kw_color_alloc.length > 0) {

                    state.kw_color_alloc.map((data, index) => {
                      if (data === color_arr[color_inc+1]) {
                        cc1 = 1;
                      }
                    });
                  }
                  if (cc1 === 0) {
                    color_inherit = color_arr[color_inc+1];
                  }
                  else {

                    let cc2 = 0;
                    if (state.kw_color_alloc.length > 0) {

                      state.kw_color_alloc.map((data, index) => {
                        if (data === color_arr[color_inc+2]) {
                          cc2 = 1;
                        }
                      });
                    }
                    if (cc2 === 0) {
                      color_inherit = color_arr[color_inc+2];
                    }
                    else {
                      color_inherit = color_arr[color_inc+3];
                    }


                  }

                }
              }*/
              if (state.kw_color_alloc[assign_key] !== undefined) {
                color_inherit = state.kw_color_alloc[assign_key];
              }
              else {
                color_inherit = color_arr[color_inc];
              }
              //color_inherit = color_arr[color_inc];
              //sel_color[assign_key] = color_inherit;
              summary_date_graph_data['datakey'].push({"key": assign_key, "fill": color_inherit}) ;
              color_inc++;
            }

          }
          inc_date++;
        };
      }

      return {
        ...state,
        saved_summary_result: result,
        loadingSummaryGraph: false,
        hasSummaryGraphError: false,
        summaryGrapherror: '',
        summary_graph_data : {
          ...state.summary_graph_data,
          senti_graph: senti_graph_data,
          src_graph: src_graph_data,
          //summary_graph: summary_donut_graph_data,
          summary_date_graph: summary_date_graph_data,
          //summary_donut_data: summary_data
        },
        //kw_color_alloc: sel_color
      }
    }

    case ANALYTICS_REQUEST_SUMMARY_GRAPH_FAILED: {
      return {
        ...state,
        loadingSummaryGraph: false,
        hasSummaryGraphError: true,
        summaryGrapherror: '',
        summary_graph_data : []
      }
    }

    case ANALYTICS_REQUEST_LANGUAGE_GRAPH: {
      return {
        ...state,
        loadingLanguageGraph: true,
        hasLanguageGraphError: false,
        languageGrapherror: ''
      }
    }

    case ANALYTICS_REQUEST_LANGUAGE_GRAPH_SUCCESS: {
      let result = action.payload.result;
      let language_graph_data = {"data": [], "type": "bar", "size": 40, "datakey": []};
      let inc = 0;
      let key_lang = [];
      let color_arr = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a', '#38A1F3', '#763636', '#FF4301', '#833AB4', '#fe7402', '#707070'];
      let color_inc = 0;
      if (result['lang_dist'] !== undefined) {
        for (const [key, value] of Object.entries(result['lang_dist'])) {
          language_graph_data['data'].push({"name": key});

          for (const [key1, value1] of Object.entries(value)) {

            language_graph_data['data'][inc][key1] = value1;

            if (key_lang.includes(key1) === false) {
              key_lang.push(key1);
              language_graph_data['datakey'].push({"key": key1, "fill": color_arr[color_inc]}) ;
              color_inc++;
            }
          }
          inc++;
        };
      }

      return {
        ...state,
        loadingLanguageGraph: false,
        hasLanguageGraphError: false,
        languageGrapherror: '',
        summary_graph_data : {
          ...state.summary_graph_data,
          lang_graph: language_graph_data
        }
      }
    }

    case ANALYTICS_REQUEST_LANGUAGE_GRAPH_FAILED: {
      return {
        ...state,
        loadingLanguageGraph: false,
        hasLanguageGraphError: true,
        languageGrapherror: '',
        summary_graph_data : {
          ...state.summary_graph_data,
          lang_graph: []
        }
      }
    }


    case ANALYTICS_REQUEST_LOCATION_GRAPH: {
      return {
        ...state,
        loadingLocationGraph: true,
        hasLocationGraphError: false,
        locationGrapherror: ''
      }
    }

    case ANALYTICS_REQUEST_LOCATION_GRAPH_SUCCESS: {
      let result = action.payload.result;
      let location_graph_data = {"data": [], "type": "bar", "size": 40, "datakey": []};
      let inc = 0;
      let key_lang = [];
      let color_arr = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a', '#38A1F3', '#763636', '#FF4301', '#833AB4', '#fe7402', '#707070'];
      let color_inc = 0;
      let locat = '';
      if (result['loc_dist'] !== undefined) {
        for (const [key, value] of Object.entries(result['loc_dist'])) {
          location_graph_data['data'].push({"name": key});
          for (const [key1, value1] of Object.entries(value)) {
            if (locationData['en'][key1] !== undefined) {
              locat = locationData['en'][key1];
            }
            else {
              locat = key1;
            }
            location_graph_data['data'][inc][locat] = value1;

            if (key_lang.includes(locat) === false) {
              key_lang.push(locat);
              location_graph_data['datakey'].push({"key": locat, "fill": color_arr[color_inc]}) ;
              color_inc++;
            }
          }
          inc++;
        };
      }

      return {
        ...state,
        loadingLocationGraph: false,
        hasLocationGraphError: false,
        locationGrapherror: '',
        summary_graph_data : {
          ...state.summary_graph_data,
          loc_graph: location_graph_data
        }
      }
    }

    case ANALYTICS_REQUEST_LOCATION_GRAPH_FAILED: {
      return {
        ...state,
        loadingLocationGraph: false,
        hasLocationGraphError: true,
        locationGrapherror: '',
        summary_graph_data : {
          ...state.summary_graph_data,
          loc_graph: []
        }
      }
    }


    case ANALYTICS_REQUEST_WORD_COUNT: {
      return {
        ...state,
        loadingWordCount: true,
        hasWordCountError: false,
        wordClouderror: ''
      }
    }

    case ANALYTICS_REQUEST_WORD_COUNT_SUCCESS: {

      return {
        ...state,
        loadingWordCount: false,
        hasWordCountError: false,
        word_count_data: action.payload.result,
        font_check: action.payload.font_check
      }
    }

    case ANALYTICS_REQUEST_WORD_COUNT_FAILED: {
      return {
        ...state,
        loadingWordCount: false,
        hasWordCountError: true,
        word_count_data : []
      }
    }

    case ANALYTICS_USER_PROJECT_KW_LIST: {

      let preload_kw = {};
      action.payload.all_kw.map((data) => {
        preload_kw[data] = true;
      });
      let loaded = false;
      if (Object.keys(preload_kw).length < 1) {
        const getClass =  document.querySelector('.multiSelect');
        let topPos = getClass.getBoundingClientRect().top-9;
        let leftPos = getClass.getBoundingClientRect().left-8;
        let getHeight = getClass.getBoundingClientRect().height+20;
        let getWidth = getClass.getBoundingClientRect().width+16;
        const getHelperClass = document.querySelector('.mk_helper');
        // getHelperClass.style.top = topPos+'px';
        getHelperClass.style.left = leftPos+'px';
        getHelperClass.style.height = getHeight+'px';
        getHelperClass.style.width = getWidth+'px';
        loaded = true;
        const blurContentWrapper = document.querySelector('#app-site');
        blurContentWrapper.classList.add('blurElem');
        const getHelperBox = document.querySelector('.mk_helper_ref')
        let getAlertClass = document.querySelector('#alerts-msg');
        let getProBanners = document.querySelector('.banner-slider');
        getHelperClass.style.top = topPos+'px';
        getHelperBox.style.top = (topPos - 20)+'px';
      }

      return {
        ...state,
        loadingUserProKwList: true,
        hasUserProKwListError: false,
        UserProKwListerror: '',
        UserProKwList: [],
        preload_kw: preload_kw,
        autoOpen: loaded
      }
    }

    case ANALYTICS_USER_PROJECT_KW_LIST_SUCCESS: {
      let result = action.payload.result;
      let checked_list = {};
      let pending_value = [];
      //let more_num = 0;
      let loaded = true;
      result.reduce((data, row) => {
        row.keywords.map((kw) => {
        for (const [key, value] of Object.entries(state.preload_kw)) {
            let sp_kw = key.split("-");
            if (sp_kw[0] == row.project_id && sp_kw[1] == kw.kw_id) {
              pending_value.push({
                type: row.project.pname,
                name: kw.user_kw,
                id: kw.kw_id,
                pro_id: row.project_id
              });
              checked_list[row.project_id+'-'+kw.kw_id] = true;
              //more_num = (Object.keys(pending_value).length-state.min_sel);
            }
          };
        });
        return data;
      }, []);
      if (Object.keys(checked_list).length < 1) {
        const getClass =  document.querySelector('.multiSelect');
        let topPos = getClass.getBoundingClientRect().top-9;
        let leftPos = getClass.getBoundingClientRect().left-8;
        let getHeight = getClass.getBoundingClientRect().height+20;
        let getWidth = getClass.getBoundingClientRect().width+16;
        const getHelperClass = document.querySelector('.mk_helper');
        // getHelperClass.style.top = topPos+'px';
        getHelperClass.style.left = leftPos+'px';
        getHelperClass.style.height = getHeight+'px';
        getHelperClass.style.width = getWidth+'px';
        loaded = true;
        const blurContentWrapper = document.querySelector('#app-site');
        blurContentWrapper.classList.add('blurElem');
        const getHelperBox = document.querySelector('.mk_helper_ref')
        let getAlertClass = document.querySelector('#alerts-msg');
        let getProBanners = document.querySelector('.banner-slider');
        getHelperClass.style.top = topPos+'px';
        getHelperBox.style.top = (topPos - 20)+'px';
      }
      else {
        const blurContentWrapper = document.querySelector('#app-site');
        blurContentWrapper.classList.remove('blurElem');
        loaded = false;
      }

      return {
        ...state,
        loadingUserProKwList: false,
        hasUserProKwListError: false,
        UserProKwListerror: '',
        UserProKwList: result,
        checked_list: checked_list,
        pending_value: pending_value,
        pending_value_del: pending_value,
        //more_number: more_num,
        preload_kw: checked_list,
        autoOpen: loaded
      }
    }

    case ANALYTICS_USER_PROJECT_KW_LIST_FAILED: {
      return {
        ...state,
        loadingUserProKwList: false,
        hasUserProKwListError: false,
        UserProKwListerror: '',
        UserProKwList: []
      }
    }


    case ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA : {
        return {
          ...state,
          influencers: {
            ...state.influencers,
            twitter: {
              ...state.influencers.twitter,
              list: [],
              loading: true,
              total: 0,
              currentPage: 0,
              perpage: 10,
              kw_input: []
            }
          }
        }
    }

    case ANALYTICS_REQUEST_TWITTER_AUTHOR_DATA_SUCCESS: {
      let newInfluencer = [];
      let response = action.payload;
      let twTotal = 0;
      let twCurPage = 0;
      let kw_input = [];
      if (response.status !== undefined && response.status.error === false) {
        // Now handle other responses for other sources like instagram this way:
        if (response.endpoint !== undefined && response.endpoint === 'sources.twitter.author.data') {
          newInfluencer = response.result.data.map((author) => {

            let posts_per_kw = [];
            for (const [key, value] of Object.entries(response.kw_input)) {
              const per_num = (parseInt(author['cntpost_'+key.replace('-', '_')])/author.posts)*100;
              posts_per_kw.push({'key': per_num, 'value': value});
            };
            return {
              name: author.name ? author.name : `@${author.author}`,
              sname: author.sname ? author.sname : author.author,
              posts: author.posts,
              author: author.author,
              followers: author.fcnt,
              posts_per_kw: posts_per_kw,
              pic: author.pimg ? author.pimg : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
            };
          });
          for (const [key, value] of Object.entries(response.kw_input)) {
            kw_input.push({'key': key, 'value': value});
          };

          kw_input = kw_input;
          twTotal = response.result.rows_before_limit_at_least;
          twCurPage = response.page;
        }
      }

      return {
        ...state,
        kwChange: 0,
        influencers: {
          ...state.influencers,
          twitter: {
            ...state.influencers.twitter,
            list: newInfluencer,
            loading: false,
            total: twTotal,
            currentPage: twCurPage,
            perpage: 10,
            kw_input: kw_input
          }
        }
      }
    }

    case ANALYTICS_ON_CHANGE_KEYWORD_SELECT_NEW: {

      let filtered_kw_ids = [];
      //console.log('act');
      //let selKw =
      /*
      console.log(state.UserProKwList)
      action.payload.map((kw) => {
        console.log(kw);
        console.log('dfdsfsfs')
        var index = state.UserProKwList.map(function(pro) {
          pro['keywords'].map(function(kws) {
            return kws.user_kw;
          });
        }).indexOf(kw.name);
        console.log(index);
        console.log('m');
        if (index !== -1) {
          filtered_kw_ids.push(state.UserProKwList[index]['kw_id']);
        }
      });
      */
      action.payload.map((kw) => {
        filtered_kw_ids.push(kw.id);
      });
      return {
        ...state,
        kwNameNew : action.payload
      };
    }

    case ANALYTICS_REQUEST_INSTA_AUTHOR_DATA: {
      return {
          ...state,
          influencers: {
            ...state.influencers,
            insta: {
              ...state.influencers.insta,
              list: [],
              loading: true,
              total: 0,
              currentPage: 0,
              perpage: 10,
              kw_input: []
            }
          }
        }
    }
    case ANALYTICS_REQUEST_INSTA_AUTHOR_DATA_SUCCESS: {
      let newInfluencer = [];
      let response = action.payload;
      let inTotal = 0;
      let inCurPage = 0;
      let kw_input = [];
      if (response.status !== undefined && response.status.error === false) {
        // Now handle other responses for other sources like instagram this way:
        if (response.endpoint !== undefined && response.endpoint === 'sources.instagram.author.data') {
          newInfluencer = response.result.data.map((author) => {
            let posts_per_kw = [];
            for (const [key, value] of Object.entries(response.kw_input)) {
              const per_num = (parseInt(author['cntpost_'+key.replace('-', '_')])/author.posts)*100;
              posts_per_kw.push({'key': per_num, 'value': value});
            };
            return {
              name: author.user ? author.user : `${author.author}`,
              sname: author.user ? author.user : author.author,
              posts: author.posts,
              author: author.author,
              followers: author.followers,
              posts_per_kw: posts_per_kw,
              pic: author.img_path ? author.img_path : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
            };
          });
          for (const [key, value] of Object.entries(response.kw_input)) {
            kw_input.push({'key': key, 'value': value});
          };

          kw_input = kw_input;
          inTotal = response.result.rows_before_limit_at_least;
          inCurPage = response.page;
        }
      }
      return {
        ...state,
        kwChange: 0,
        influencers: {
          ...state.influencers,
          insta: {
            ...state.influencers.insta,
            list: newInfluencer,
            loading: false,
            total: inTotal,
            currentPage: inCurPage,
            perpage: 10,
            kw_input: kw_input
          }
        }
      }
    }

    case ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA: {
      return {
        ...state,
        influencers: {
          ...state.influencers,
          youtube: {
            ...state.influencers.youtube,
            list: [],
            loading: true,
            total: 0,
            currentPage: 0,
            perpage: 10,
            kw_input: []
          }
        }
      }

    }

    case ANALYTICS_REQUEST_YOUTUBE_AUTHOR_DATA_SUCCESS: {
      let newInfluencer = [];
      let response = action.payload;
      let youtubeTotal = 0;
      let youtubeCurPage = 0;
      let kw_input = [];
      if (response.status !== undefined && response.status.error === false) {
        // Now handle other responses for other sources like instagram this way:
        if (response.endpoint !== undefined && response.endpoint === 'sources.youtube.author.data') {
          //console.log(response.result.data);
          newInfluencer = response.result.data.map((author) => {
            let posts_per_kw = [];
            for (const [key, value] of Object.entries(response.kw_input)) {
              const per_num = (parseInt(author['cntpost_'+key.replace('-', '_')])/author.posts)*100;
              posts_per_kw.push({'key': per_num, 'value': value});
            };
            return {
              name: author.auth ? author.auth : `${author.title}`,
              sname: author.channel ? author.channel : author.auth,
              posts: author.posts,
              author: author.auth ? author.auth : author.title,
              subscribers: author.subscriber_count,
              authid: author.authid,
              posts_per_kw: posts_per_kw,
              pic: author.thumbnail_src ? author.thumbnail_src : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
            };
          });
          for (const [key, value] of Object.entries(response.kw_input)) {
            kw_input.push({'key': key, 'value': value});
          };

          kw_input = kw_input;
          youtubeTotal = response.result.rows_before_limit_at_least;
          youtubeCurPage = response.page;
        }
      }

      return {
        ...state,
        kwChange: 0,
        influencers: {
          ...state.influencers,
          youtube: {
            ...state.influencers.youtube,
            list: newInfluencer,
            loading: false,
            total: youtubeTotal,
            currentPage: youtubeCurPage,
            perpage: 10,
            kw_input: kw_input
          }
        }
      }
    }

    case ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA: {
      return {
          ...state,
          influencers: {
            ...state.influencers,
            reddit: {
              ...state.influencers.reddit,
              list: [],
              loading: true,
              total: 0,
              currentPage: 0,
              perpage: 10,
              kw_input: []
            }
          }
        }
    }

    case ANALYTICS_REQUEST_REDDIT_AUTHOR_DATA_SUCCESS: {
      let newInfluencer = [];
      let response = action.payload;
      let redTotal = 0;
      let redCurPage = 0;
      let kw_input = [];
      if (response.status !== undefined && response.status.error === false) {
        // Now handle other responses for other sources like instagram this way:
        if (response.endpoint !== undefined && response.endpoint === 'sources.reddit.author.data') {
          newInfluencer = response.result.data.map((author) => {
            let posts_per_kw = [];
            for (const [key, value] of Object.entries(response.kw_input)) {
              const per_num = (parseInt(author['cntpost_'+key.replace('-', '_')])/author.posts)*100;
              posts_per_kw.push({'key': per_num, 'value': value});
            };
            return {
              name: author.name ? author.name : `u/${author.author}`,
              sname: author.sname ? author.sname : author.author,
              posts: author.posts,
              author: author.author,
              karma: author.karma,
              posts_per_kw: posts_per_kw,
              pic: author.pimg ? author.pimg : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
            };
          });
          for (const [key, value] of Object.entries(response.kw_input)) {
            kw_input.push({'key': key, 'value': value});
          };

          kw_input = kw_input;
          redTotal = response.result.rows_before_limit_at_least;
          redCurPage = response.page;
        }
      }
      return {
        ...state,
        kwChange: 0,
        influencers: {
          ...state.influencers,
          reddit: {
            ...state.influencers.reddit,
            list: newInfluencer,
            loading: false,
            total: redTotal,
            currentPage: redCurPage,
            perpage: 10,
            kw_input: kw_input
          }
        }
      }
    }


    case ANALYTICS_REQUEST_WEB_AUTHOR_DATA : {
        return {
          ...state,
          influencers: {
            ...state.influencers,
            web: {
              ...state.influencers.web,
              list: [],
              loading: true,
              total: 0,
              currentPage: 0,
              perpage: 10,
              kw_input: []
            }
          }
        }
    }

    case ANALYTICS_REQUEST_WEB_AUTHOR_DATA_SUCCESS: {
      let newInfluencer = [];
      let response = action.payload;
      let webTotal = 0;
      let webCurPage = 0;
      let kw_input = [];
      if (response.status !== undefined && response.status.error === false) {
        // Now handle other responses for other sources like instagram this way:
        if (response.endpoint !== undefined && response.endpoint === 'sources.web.author.data') {
          newInfluencer = response.result.data.map((author) => {
            let posts_per_kw = [];
            for (const [key, value] of Object.entries(response.kw_input)) {
              const per_num = (parseInt(author['cntpost_'+key.replace('-', '_')])/author.posts)*100;
              posts_per_kw.push({'key': per_num, 'value': value});
            };
            return {
              name: author.domain ? author.domain : `${author.author}`,
              sname: author.domain ? author.doamin : author.author,
              posts: author.posts,
              author: author.author,
              kwrank: author.kwrank,
              posts_per_kw: posts_per_kw,
              pic: author.ficon ? author.ficon : 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
            };
          });
          for (const [key, value] of Object.entries(response.kw_input)) {
            kw_input.push({'key': key, 'value': value});
          };

          kw_input = kw_input;
          webTotal = response.result.rows_before_limit_at_least;
          webCurPage = response.page;
        }
      }
      return {
        ...state,
        kwChange: 0,
        influencers: {
          ...state.influencers,
          web: {
            ...state.influencers.web,
            list: newInfluencer,
            loading: false,
            total: webTotal,
            currentPage: webCurPage,
            perpage: 10,
            kw_input: kw_input
          }
        }
      }
    }

    case ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA : {
      return {
        ...state,
        influencers: {
          ...state.influencers,
          human: {
            ...state.influencers.human,
            list: [],
            loading: true,
            total: 0,
            currentPage: 0,
            perpage: 10,
            kw_input: []
          }
        }
      }
    }

    case ANALYTICS_REQUEST_HUMAN_AUTHOR_DATA_SUCCESS: {
      let newInfluencer = [];
      let response = action.payload;
      let humanTotal = 0;
      let humanCurPage = 0;
      let kw_input = [];
      if (response.status !== undefined && response.status.error === false) {
        // Now handle other responses for other sources like instagram this way:
        if (response.endpoint !== undefined && response.endpoint === 'sources.human.author.data') {
          newInfluencer = response.result.data.map((author) => {
            let posts_per_kw = [];
              for (const [key, value] of Object.entries(response.kw_input)) {
                const per_num = (parseInt(author['cntpost_'+key.replace('-', '_')])/author.posts)*100;
                posts_per_kw.push({'key': per_num, 'value': value});
              };
            return {
              name: author.author ? author.author : ``,
              posts: author.posts,
              author: author.author,
              posts_per_kw: posts_per_kw,
              pic: 'https://cdn2.vectorstock.com/i/1000x1000/20/76/man-avatar-profile-vector-21372076.jpg'
            };
          });

          for (const [key, value] of Object.entries(response.kw_input)) {
            kw_input.push({'key': key, 'value': value});
          };

          kw_input = kw_input;
          humanTotal = response.result.rows_before_limit_at_least;
          humanCurPage = response.page;
        }
      }
      return {
        ...state,
        kwChange: 0,
        influencers: {
          ...state.influencers,
          human: {
            ...state.influencers.human,
            list: newInfluencer,
            loading: false,
            total: humanTotal,
            currentPage: humanCurPage,
            perpage: 10,
            kw_input: kw_input
          }
        }
      }
    }


    case ANALYTICS_CHANGE_DATE_RANGE_FILTER: {
      let newRange = {
        from: action.payload.newRange.start,
        to: action.payload.newRange.end
      };
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          selected: newRange
        }
      }
    }

    case SENTI_ON_CHANGE_IMPACT: {
      let total_count = state.trueTotal;
      let check_all_reset = true;
      if (action.payload.checked === true ) {
        total_count++;
      }
      else if (action.payload.checked === false) {
        total_count--;
        if (total_count === 0){
          check_all_reset = false;
        }
      }
      return {
        ...state,
        sentiPos : (action.payload.impact === 'sentiPos' ? action.payload.checked: state.sentiPos),
        sentiNeg: (action.payload.impact === 'sentiNeg' ? action.payload.checked: state.sentiNeg),
        sentiNet: (action.payload.impact === 'sentiNet' ? action.payload.checked: state.sentiNet),
        sentiUnk: (action.payload.impact === 'sentiUnk' ? action.payload.checked: state.sentiUnk),
        trueTotal: total_count,
        checkReset: check_all_reset
      };
    }

    case ACTIVE_SRC_NEW: {
      let srcs = state.activeSrcFilter;
      if (action.payload.src === 'all') {

        if (action.payload.checked === false) {
          Object.keys(srcs).map(function(key) {
            srcs[key] = 0;
            return srcs;
          });
        }
        else {
          Object.keys(srcs).map(function(key) {
            srcs[key] = 1;
            return srcs;
          });
          srcs.all = 1;
          srcs.lqp = 0;
        }
      }
      else {
        srcs[action.payload.src] = (action.payload.checked === false) ? 0 : 1;
        if (srcs[action.payload.src] === 0 && action.payload.src != 'lqp') {
          srcs.all = 0;
        }
        if (srcs[action.payload.src] === 1 && action.payload.src != 'lqp' && srcs['all'] === 0) {
          let chk_all = 1;
          Object.keys(srcs).map(function(key) {
            if (srcs[key] === 0 && key !== 'all' && key != 'lqp') {
              chk_all = 0;
            }
          });
          if (chk_all === 1) {
            srcs.all = 1;
          }
        }
      }
      return {
        ...state,
        activeSrcFilter : srcs
      };
    }

    case ON_LANG_SUBMIT_ANALYTICS: {
      let langData = state.langList;
      let prelangData = state.prelangList;
      langData[action.payload.iterate] = action.payload.data;
      if (langData[action.payload.iterate]['pre'] === undefined) {
        langData[action.payload.iterate]['pre'] = 'in';
        if (prelangData[action.payload.iterate] === undefined) {
          prelangData[action.payload.iterate]['code'] = 'in';
          prelangData[action.payload.iterate]['name'] = 'Include';
        }
      }
      return {
        ...state,
        langList : langData,
        prelangList: prelangData
      };
    }

    case ON_PRE_LANG_SUBMIT: {
      let prelangData = state.prelangList;
      let langData = state.langList;
      prelangData[action.payload.iterate] = action.payload.data;
      if (langData[action.payload.iterate] !== undefined && langData[action.payload.iterate]['pre'] !== undefined) {
        langData[action.payload.iterate]['pre'] = prelangData[action.payload.iterate]['code'];
      }
      return {
        ...state,
        prelangList : prelangData,
        langList: langData
      };
    }

    case MULTI_CHANGE_IMPACT: {
      let checked_list = {};
      let pending_value = [];
      let new_set = state.checked_list;
      let uncheked = action.payload.data;
      let del_id = "";
      let params_data = [];
      let more_cond = 0;
      //let more_num = 0;
      let val = true;
      new_set = action.payload.data.filter((res, index) => {
          val = true;
          if (state.checked_list[res.pro_id+'-'+res.id] === true && index == action.payload.data.length -1 && action.payload.data.length !== 1) {
            val = false;
            del_id = res.pro_id+'-'+res.id;
          }
         return val;
      });

      if (del_id !== '') {

        uncheked = action.payload.data.filter((res, index) => {
          return del_id !== res.pro_id+'-'+res.id
        });

        let new_checked = {};
        uncheked.map((res, index) => {
          new_checked[res.pro_id+'-'+res.id] = true;
        });
        params_data = uncheked;
        checked_list = new_checked;
        pending_value = uncheked;
      }
      else {
        if(Object.keys(action.payload.data).length > 4) {
          let not_allowed_id = "";
          uncheked = action.payload.data.filter((res, index) => {
            return index !== action.payload.data.length -1
          });

          let new_checked_val = {};
          uncheked.map((res, index) => {
            new_checked_val[res.pro_id+'-'+res.id] = true;
          });

          params_data = uncheked;
          checked_list = new_checked_val;
          pending_value = uncheked;
          more_cond = 1;
          // alert('Can not Select More than 4 Keywords');
        }
        else {
          params_data = action.payload.data;
          pending_value = action.payload.data;
          //more_num = (Object.keys(action.payload.data).length-state.min_sel);
          let filtered_kw_ids = {};
          pending_value.map((to_be_inserted) => {
            filtered_kw_ids[to_be_inserted.pro_id+'-'+to_be_inserted.id] = true;
          });
          checked_list = filtered_kw_ids;
        }
      }
      /*
      action.payload.data.map((res, index) => {
        if (state.checked_list[res.pro_id+'-'+res.id] === true && index == action.payload.data.length -1 && action.payload.data.length !== 1) {
          del_id = res.pro_id+'-'+res.id;
          delete new_set[res.pro_id+'-'+res.id];
        }
      });
      */
      //alert(del_id);

      /*
      if (del_id != "") {
        action.payload.data.map((res, index) => {
          if (del_id === res.pro_id+'-'+res.id) {
            delete uncheked[index];
          }
        });
        params_data = uncheked;
        checked_list = new_set;
        pending_value = uncheked;
        //more_num = (Object.keys(uncheked).length-state.min_sel);
        del_id = "";
      }
      else {
        if(Object.keys(action.payload.data).length > 4) {
          let not_allowed_id = "";
          action.payload.data.map((res, index) => {
            if (index == action.payload.data.length -1) {
              not_allowed_id = res.pro_id+'-'+res.id;
            }
          });
          if (not_allowed_id != "") {
            action.payload.data.map((res, index) => {
              if (not_allowed_id === res.pro_id+'-'+res.id) {
                delete uncheked[index];
              }
            });
            params_data = uncheked;
            checked_list = new_set;
            pending_value = uncheked;
            //more_num = (Object.keys(uncheked).length-state.min_sel);
          }
          more_cond = 1;
          // alert('Can not Select More than 4 Keywords');
        }
        else {
          params_data = action.payload.data;
          pending_value = action.payload.data;
          //more_num = (Object.keys(action.payload.data).length-state.min_sel);
          let filtered_kw_ids = {};
          pending_value.map((to_be_inserted) => {
            filtered_kw_ids[to_be_inserted.pro_id+'-'+to_be_inserted.id] = true;
          });
          checked_list = filtered_kw_ids;
        }
      }
      */
      let loaded = true;
      if (Object.keys(checked_list).length > 0) {
        const blurContentWrapper = document.querySelector('#app-site');
        blurContentWrapper.classList.remove('blurElem');
        loaded = false;
      }
      else {
        const getClass =  document.querySelector('.multiSelect');
        let topPos = getClass.getBoundingClientRect().top-9;
        let leftPos = getClass.getBoundingClientRect().left-8;
        let getHeight = getClass.getBoundingClientRect().height+20;
        let getWidth = getClass.getBoundingClientRect().width+16;
        const getHelperClass = document.querySelector('.mk_helper');
        // getHelperClass.style.top = topPos+'px';
        getHelperClass.style.left = leftPos+'px';
        getHelperClass.style.height = getHeight+'px';
        getHelperClass.style.width = getWidth+'px';
        loaded = true;
        const blurContentWrapper = document.querySelector('#app-site');
        blurContentWrapper.classList.add('blurElem');
        const getHelperBox = document.querySelector('.mk_helper_ref')
        let getAlertClass = document.querySelector('#alerts-msg');
        let getProBanners = document.querySelector('.banner-slider');
        getHelperClass.style.top = topPos+'px';
        getHelperBox.style.top = (topPos - 20)+'px';
      }
      return {
        ...state,
        params_data: params_data,
        //more_number: more_num,
        pending_value: pending_value,
        pending_value_del: pending_value,
        checked_list: checked_list,
        preload_kw: checked_list,
        autoOpen: loaded,
        active_analysis: 'senti_graph',
        active_competitive: 'mentions',
        kwChange: 1,
        more_cond: more_cond
      };
    }

    case RESET_FILTER: {
      return {
        ...state,
        sentiPos: true,
        sentiNeg: true,
        sentiNet: true,
        sentiUnk: true,
        trueTotal: 0,
        langList: [],
        locList: [],
        checkReset: true,
        activeSrcFilter: {'twitter':1, 'web':1, 'facebook':1, 'youtube': 1, 'quora' : 1, 'reddit': 1, 'forum' : 1, 'instagram': 1, 'news': 1, 'podcast': 1, 'tv': 1, 'radio': 1, 'linkedin': 1, 'lqp': 0, 'all':1},
        locSelectorCnt: 1,
        langSelectorCnt: 1,
        selFilterName: '',
        selFilterNameAdded: '',
        selFilterId: 0,
        active_analysis: 'senti_graph',
        active_competitive: 'mentions',
        kwChange: 0

      };
    }

    case VIEW_POST_BY_AUTHOR_DATA: {
      return {
        ...state,
        yt_name: action.payload.yt_name,
        showInluencerPosts: {
          ...state.showInluencerPosts,
          enabled: true,
          loading:true,
          author: {
            name: action.payload.author
          },
          ikey: action.payload.ikey,
          totalPosts: 0,
          currentPage: 0,
          postList: []
        }
      }
    }
    case CLOSE_VIEW_POST_BY_AUTHOR_DATA: {
      return {
        ...state,
        yt_name:'',
        showInluencerPosts: {
          ...state.showInluencerPosts,
          enabled: false,
          loading:true,
          author: {
            name: ''
          },
          ikey: '',
          totalPosts: 0,
          currentPage: 0,
          postList: []
        }
      };
    }

    case ON_SUCCESS_POST_BY_AUTHOR_DATA: {
      return {
        ...state,
        showInluencerPosts: {
          ...state.showInluencerPosts,
          enabled: true,
          loading: false,
          totalPosts: action.payload.total,
          currentPage: action.payload.current_page - 1,
          postList: action.payload.data
        }
      };
    }

    case ON_FILTER_SUBMIT_ANALYTICS: {
      return {
        ...state,
        show_filter_save: false
      };
    }

    case ON_FILTER_SUBMIT_ANALYTICS_SUCCESS: {
      return {
        ...state,
        show_filter_save: true
      };
    }

    case GET_FILTER_LIST_ANALYTICS: {
      return {
        ...state,
        filterList: [],
        filterListLoad: true,
        showMessage: false,
        alertMessage: ''
      };
    }

    case GET_FILTER_ANALYTICS_SUCCESS: {
      return {
        ...state,
        filterList: action.payload,
        filterListLoad: false,
        showMessage: false,
        alertMessage: ''
      };
    }


    case ON_DELETE_FILTER_ANALYTICS: {
      /*
      let filterData = state.filterList;
      let found_res = -1;
      filterData.map((data, index) => {
        if (data['id'] === action.payload.filter_id) {
          found_res = index;
        }
      });
      if (found_res > -1) {
        filterData.splice(found_res, 1);
      }*/
      let del_data = state.filterList.filter((data) => {
         return (data['id'] !== action.payload.filter_id)
      });
      return {
        ...state,
        filterList : del_data
        //alertMessage: 'Filter Deleted Successfully'
      };
    }

    case APPLY_SAVED_FILTER: {
      let filter_info = JSON.parse(action.payload.info);
      let total_cnt = 0;
      if (filter_info.senti_pos === true)
        total_cnt++;
      if (filter_info.senti_neg === true)
        total_cnt++;
      if (filter_info.senti_net === true)
        total_cnt++;
      if (filter_info.senti_unk === true)
        total_cnt++;
      return {
        ...state,
        sentiPos: filter_info.senti_pos,
        sentiNeg: filter_info.senti_neg,
        sentiNet: filter_info.senti_net,
        sentiUnk: filter_info.senti_unk,
        trueTotal: total_cnt,
        checkReset: true,
        langList: filter_info.lang_list !== undefined ? filter_info.lang_list :[],
        locList: filter_info.loc_list !== undefined ? filter_info.loc_list :[],
        reach: filter_info.reach,
        activeSrcFilter: filter_info.active_srcs,
        selFilterName: action.payload.name,
        selFilterNameAdded: action.payload.added,
        selFilterId: action.payload.id,
        langSelectorCnt: (filter_info.lang_list !== undefined && filter_info.lang_list.length > 0) ? filter_info.lang_list.length : 1,
        locSelectorCnt: (filter_info.loc_list !== undefined && filter_info.loc_list.length > 0) ? filter_info.loc_list.length : 1
      };
    }

    case DETAIL_LOADER_HANDLER : {
      return {...state, detailLoader: false}
    }

    case LOADING_ENABLE : {
      return {...state, detailLoader: true}
    }

    case RESET_SELECT_FILTER: {
      return {...state, selFilterName: '', selFilterNameAdded: '', selFilterId: 0}
    }

    case ADD_LANG_FILTER: {
      return {
        ...state,
        langSelectorCnt: state.langSelectorCnt+1
      }
    }

    case DEL_LANG_FILTER: {
      let prelangData = state.prelangList;
      let langData = state.langList;
      if (langData[action.payload.iterate] !== undefined) {
        delete langData[action.payload.iterate];
      }
      if (prelangData[action.payload.iterate] !== undefined) {
        delete prelangData[action.payload.iterate];
      }
      let temp_lang_data = [];
      let temp_index = 0;
      langData.map((data, index) => {
        temp_lang_data[temp_index] = data;
        temp_index++;
      });

      let temp_prelang_data = [];
      let temp_pre_index = 0;
      prelangData.map((data, index) => {
        temp_prelang_data[temp_pre_index] = data;
        temp_pre_index++;
      });

      return {
        ...state,
        langSelectorCnt: state.langSelectorCnt === 1 ? 1: state.langSelectorCnt-1,
        langList: temp_lang_data,
        prelangList: temp_prelang_data
      }
    }

    case ADD_LOC_FILTER: {
      return {
        ...state,
        locSelectorCnt: state.locSelectorCnt+1,
      }
    }

    case DEL_LOC_FILTER: {
      let prelocData = state.prelocList;
      let locData = state.locList;
      if (locData[action.payload.iterate] !== undefined) {
        delete locData[action.payload.iterate];
      }
      if (prelocData[action.payload.iterate] !== undefined) {
        delete prelocData[action.payload.iterate];
      }
      let temp_loc_data = [];
      let temp_index = 0;
      locData.map((data, index) => {
        temp_loc_data[temp_index] = data;
        temp_index++;
      });

      let temp_preloc_data = [];
      let temp_pre_index = 0;
      prelocData.map((data, index) => {
        temp_preloc_data[temp_pre_index] = data;
        temp_pre_index++;
      });

      return {
        ...state,
        locSelectorCnt: state.locSelectorCnt === 1 ? 1: state.locSelectorCnt-1,
        locList: temp_loc_data,
        prelocList: temp_preloc_data
      }
    }

    case ON_LOC_SUBMIT_ANALYTICS: {
      let locData = state.locList;
      let prelocData = state.prelocList;
      locData[action.payload.iterate] = action.payload.data;
      if (locData[action.payload.iterate]['pre'] === undefined) {
        locData[action.payload.iterate]['pre'] = 'in';
        if (prelocData[action.payload.iterate] === undefined) {
          prelocData[action.payload.iterate]['code'] = 'in';
          prelocData[action.payload.iterate]['name'] = 'Include';
        }
      }
      return {
        ...state,
        locList : locData,
        prelocList: prelocData
      };
    }

    case ON_PRE_LOC_SUBMIT: {
      let prelocData = state.prelocList;
      let locData = state.locList;
      prelocData[action.payload.iterate] = action.payload.data;
      if (locData[action.payload.iterate] !== undefined && locData[action.payload.iterate]['pre'] !== undefined) {
        locData[action.payload.iterate]['pre'] = prelocData[action.payload.iterate]['code'];
      }
      return {
        ...state,
        prelocList : prelocData,
        locList: locData
      };
    }

    case ON_EDIT_FILTER_SUBMIT: {
      return {
        ...state,
        showMessage: true
      };
    }

    case ON_EDIT_FILTER_SUBMIT_SUCCESS: {
      return {
        ...state,
        showMessage: true
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true
      }
    }

    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false
      }
    }

    case ANALYTICS_REQUEST_SUMMARY_TOP: {
      return {
        ...state,
        loading_summary_top_data: true,
        has_error_summary_top_data: false,
        err_summary_top_data: ''
      }
    }

    case ANALYTICS_REQUEST_SUMMARY_TOP_SUCCESS: {
      let result = action.payload.result;

      let summary_donut_graph_data = {"data": [], "options": []};

      let summary_data = [];
      let data_inner = [];
      let inc_sum = 0;
      let color_ar = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a'];
      let sel_color = [];
      let load_graph_donut = 'kw_dist_total';
      if (state.active_competitive === 'mentions') {
        load_graph_donut = 'kw_dist_total';
      }
      else if (state.active_competitive === 'authors') {
        load_graph_donut = 'kw_dist_auth_total';
      }
      else if (state.active_competitive === 'sites') {
        load_graph_donut = 'kw_dist_sites_total';
      }
      let check_in = 0;
      let data_total = 0;
      if (result[load_graph_donut] !== undefined) {
        for (const [key, value] of Object.entries(result[load_graph_donut])) {
          if (data_inner.length === 0) {
            data_inner.push({key: key, value: value.total});
          }
          else if (((data_inner[0]['value'] !== undefined) && (parseInt(data_inner[0]['value']) < parseInt(value.total)))) {
            data_inner[0]['key'] = key;
            data_inner[0]['value'] = value.total;
          }

          data_total += parseInt(value.total);

          let fill_color = '';

          if (state.kw_color_alloc[key] !== undefined) {
            fill_color = state.kw_color_alloc[key];
          }
          else {
            check_in = 1;
            fill_color = color_ar[inc_sum];
          }
          summary_data.push({key: key, value: value.total, color: fill_color});
          sel_color[key] = fill_color;
          inc_sum++;
        };
      }

      if (summary_data.length > 0) {
        //const background_color = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a'];
        //const border_color = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a'];
        //const hover_back_color = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a'];
        const donutGraphdata = (canvas) => {
          const filter_labels = [];
          const filter_count = [];
          const background_color_filter = [];
          const border_color_filter = [];
          const hover_back_color_filter = [];

          summary_data.map((data, index) => {
              filter_labels.push(data.key);
              filter_count.push(data.value);
              background_color_filter.push(sel_color[data.key]);
              border_color_filter.push(sel_color[data.key]);
              hover_back_color_filter.push(sel_color[data.key]);
              return data;
          });

          const ctx = canvas.getContext("2d");
          const _stroke = ctx.stroke;
          ctx.stroke = function () {
            ctx.save();
            ctx.shadowColor = '';
            ctx.shadowBlur = 25;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 12;
            _stroke.apply(this, arguments);
            ctx.restore();
          };
          return {
            labels: filter_labels,
            datasets: [{
              data: filter_count,
              backgroundColor: background_color_filter,
              borderColor: border_color_filter,
              hoverBackgroundColor: hover_back_color_filter,
            }]
          }
        };

        const optionsDonut = {
          legend: {
            display: false,
            position: "bottom",
            labels: {
              cursor: 'pointer'
            }
          },
          datalabels: {
            display: true,
            color: "#000",
          },
          layout: {
            padding: {
              bottom: 20
            }
          },
          cutoutPercentage: 65,
          borderWidth: 0
        };

        summary_donut_graph_data['data'] = donutGraphdata;
        summary_donut_graph_data['options'] = optionsDonut;
        summary_donut_graph_data['data_inner'] = data_inner;
      }

      return {
        ...state,
        //kw_color_alloc: check_in === 1 ? sel_color: state.kw_color_alloc,
        loading_summary_top_data: false,
        has_error_summary_top_data: false,
        err_summary_top_data: '',
        saved_top_result: result,
        summary_top_data : {
          ...state.summary_top_data,
          mentions: result.mentions,
          authors: result.authors,
          sites: result.sites,
          summary_graph: summary_donut_graph_data,
          summary_donut_data: summary_data,
          data_total: data_total
        }
      }
    }

    case ANALYTICS_REQUEST_SUMMARY_TOP_FAILED: {
      return {
        ...state,
        loading_summary_top_data: false,
        has_error_summary_top_data: true,
        err_summary_top_data: '',
        summary_top_data : []
      }
    }

    case CHANGE_ANALYSIS_GRAPH: {
      return {
        ...state,
        active_analysis: action.payload
      }
    }

    case CHANGE_COMPETITIVE_GRAPH: {
      let summary_donut_graph_data = {"data": [], "options": []};
      let summary_data = [];
      let data_inner = [];
      let inc_sum = 0;
      let color_ar = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a'];
      let sel_color = [];
      let load_graph_donut = 'kw_dist_total';
      if (state.active_competitive === 'mentions') {
        load_graph_donut = 'kw_dist_total';
      }
      else if (state.active_competitive === 'authors') {
        load_graph_donut = 'kw_dist_auth_total';
      }
      else if (state.active_competitive === 'sites') {
        load_graph_donut = 'kw_dist_sites_total';
      }
      if (state.saved_top_result[load_graph_donut] !== undefined) {
        for (const [key, value] of Object.entries(state.saved_top_result[load_graph_donut])) {
          if (data_inner.length === 0) {
            data_inner.push({key: key, value: value.total});
          }
          else if (((data_inner[0]['value'] !== undefined) && (parseInt(data_inner[0]['value']) < parseInt(value.total)))) {
            data_inner[0]['key'] = key;
            data_inner[0]['value'] = value.total;
          }

          let fill_color = '';
          if (state.kw_color_alloc[key] !== undefined) {
            fill_color = state.kw_color_alloc[key];
          }
          else {
            fill_color = color_ar[inc_sum];
          }
          summary_data.push({key: key, value: value.total, color: fill_color});
          sel_color[key] = fill_color;
          inc_sum++;
        };
      }
      if (summary_data.length > 0) {
        //const background_color = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a'];
        //const border_color = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a'];
        //const hover_back_color = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a'];
        const donutGraphdata = (canvas) => {
          const filter_labels = [];
          const filter_count = [];
          const background_color_filter = [];
          const border_color_filter = [];
          const hover_back_color_filter = [];

          summary_data.map((data, index) => {
              filter_labels.push(data.key);
              filter_count.push(data.value);
              background_color_filter.push(sel_color[data.key]);
              border_color_filter.push(sel_color[data.key]);
              hover_back_color_filter.push(sel_color[data.key]);
              return data;
          });

          const ctx = canvas.getContext("2d");
          const _stroke = ctx.stroke;
          ctx.stroke = function () {
            ctx.save();
            ctx.shadowColor = '';
            ctx.shadowBlur = 25;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 12;
            _stroke.apply(this, arguments);
            ctx.restore();
          };
          return {
            labels: filter_labels,
            datasets: [{
              data: filter_count,
              backgroundColor: background_color_filter,
              borderColor: border_color_filter,
              hoverBackgroundColor: hover_back_color_filter,
            }]
          }
        };

        const optionsDonut = {
          legend: {
            display: false,
            position: "bottom",
            labels: {
              cursor: 'pointer'
            }
          },
          datalabels: {
            display: true,
            color: "#000",
          },
          layout: {
            padding: {
              bottom: 20
            }
          },
          cutoutPercentage: 65,
          borderWidth: 0
        };

        summary_donut_graph_data['data'] = donutGraphdata;
        summary_donut_graph_data['options'] = optionsDonut;
        summary_donut_graph_data['data_inner'] = data_inner;
      }


      let summary_date_graph_data = {"data": [], "type": "bar", "size": 40, "datakey": []};

      let inc_date = 0;
      let keys = [];
      let color_inc = 0;
      let color_arr = ['#5379c6', '#60c087', '#e4a450', '#ce4f5a'];
      let assign_key = '';
      let color_inherit = '';
      let load_graph = 'date_dist';
      if (state.active_competitive === 'mentions') {
        load_graph = 'date_dist';
      }
      else if (state.active_competitive === 'authors') {
        load_graph = 'date_dist_auth';
      }
      else if (state.active_competitive === 'sites') {
        load_graph = 'date_dist_sites';
      }
      if (state.saved_summary_result[load_graph] !== undefined) {
        for (const [key, value] of Object.entries(state.saved_summary_result[load_graph])) {
          summary_date_graph_data['data'].push({"name": key});
          for (const [key1, value1] of Object.entries(value)) {
            assign_key = key1.toString();
            summary_date_graph_data['data'][inc_date][assign_key] = value1;
            if (keys.includes(assign_key) === false) {
              keys.push(assign_key);
              if (sel_color[assign_key] !== undefined) {
                color_inherit = sel_color[assign_key];
              }
              else {
                color_inherit = color_arr[color_inc];
              }
              summary_date_graph_data['datakey'].push({"key": assign_key, "fill": color_inherit}) ;
              color_inc++;
            }

          }
          inc_date++;
        };
      }


      return {
        ...state,
        loadingSummaryGraph: false,
        hasSummaryGraphError: false,
        summaryGrapherror: '',
        summary_graph_data : {
          ...state.summary_graph_data,
          summary_date_graph: summary_date_graph_data,
        },
        summary_top_data : {
          ...state.summary_top_data,
          summary_graph: summary_donut_graph_data,
          summary_donut_data: summary_data
        }
      }
    }

    case SUMMARY_LOAD_ENABLE: {
      return {
        ...state,
        loadingSummaryGraph: true,
        active_competitive: action.payload
      }
    }

    case FOCUS_INPUT: {
      return {
        ...state,
        autoOpen: action.payload
      }
    }

    case COLOR_CODES: {
      return {
        ...state,
        kw_color_alloc: action.payload.sel_color,
        kw_color_alloc_kw: action.payload.sel_color_kw
      }
    }
    case MORE_COND_CLOSE: {
      return {
        ...state,
        more_cond: 0
      }
    }

    case SEL_ALERT: {
      return {
        ...state,
        sel_alert_cond: action.payload
      }
    }



    default:
      return state;
  }
};
